<template>
  <AddOnSiteCategoryModal
    :categoryId="selectedCategoryId"
    :fetchCategories="fetchCategories()"
  />
  <AddAddressesModal
    :selectedAddressId="selectedAddressId"
    :fetchAddressData="featchAddress()"
  />
  <AddMaterialModal :materialId="selectedMaterialId" @feachData="featchMaterial" />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="salesSetting p-1">
        <div class="row ms-2" style="border-bottom: 5px solid #ccc">
          <h4 class="mainHeading">Setting</h4>
          <div class="subHeadings mb-1">
            <span
              :class="{
                'active-SubHeading': activeHeading === 'Sales',
                'inactive-SubHeading': activeHeading !== 'Sales',
              }"
              @click="changeHeading('Sales')"
              >Sales Setting
            </span>
            <span
              :class="{
                'active-SubHeading': activeHeading === 'OnSite',
                'inactive-SubHeading': activeHeading !== 'OnSite',
              }"
              @click="changeHeading('OnSite')"
              >Onsite Setting
            </span>
            <span
              :class="{
                'active-SubHeading': activeHeading === 'Procurement',
                'inactive-SubHeading': activeHeading !== 'Procurement',
              }"
              @click="changeHeading('Procurement')"
              >Procurement Setting
            </span>
          </div>
        </div>

        <div class="container-fluid">
          <div class="col-md-12 row m-0 p-0" v-if="this.activeHeading === 'Sales'">
            <div class="col-md-2 SubheadingOfSales">
              <p
                :class="{
                  'blueactive-SubHeading': activeSubHeading === 'EstimateDetails',
                  'inactive-SubHeading': activeSubHeading !== 'EstimateDetails',
                }"
                @click="changeSubHeading('EstimateDetails')"
              >
                Estimate Details
              </p>
              <p
                :class="{
                  'blueactive-SubHeading': activeSubHeading === 'material',
                  'inactive-SubHeading': activeSubHeading !== 'material',
                }"
                @click="changeSubHeading('material')"
              >
                Material Library
              </p>
            </div>
            <div class="container-fluid col-md-10 p-1">
              <div v-if="activeSubHeading === 'EstimateDetails'">
                <div class="row container-fluid">
                  <div class="col-md-7">
                    <h5 class="subHeadingHeader">Company Details</h5>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="companyName">Name:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="inputBg form-control"
                          v-model="CompanyData.companyName"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="emialId">EmailID</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="email"
                          class="form-control"
                          v-model="CompanyData.companyEmail"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="address">Address:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          name="address"
                          v-model="CompanyData.address"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="city">City:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="CompanyData.city"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="state">State:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="CompanyData.state"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <h5 class="subHeadingHeader">Bank Details</h5>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="accountName">Account Name :</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          class="form-control"
                          type="text"
                          name="accountName"
                          id=""
                          v-model="bankDetails.accountName"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="accountNumber">Account Number :</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          name="accountNumber"
                          class="form-control"
                          id=""
                          v-model="bankDetails.accountNumber"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="bankName">Bank Name:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          name="bankName"
                          id=""
                          class="form-control"
                          v-model="bankDetails.bankName"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="ifscCode">IFSC Code :</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          name="ifscCode"
                          id=""
                          class="form-control"
                          v-model="bankDetails.IFSC"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-4">
                        <label for="ifscCode">Address :</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          name="ifscCode"
                          id=""
                          class="form-control"
                          v-model="bankDetails.branchAddress"
                          style="border-color: black"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <h5 class="subHeadingHeader">Company Logo</h5>
                    <div class="logoContainer">
                      <div
                        class="dropArea"
                        id="dropArea"
                        :class="{ withImage: imageDataUrl }"
                      >
                        <img
                          src="../../assets/icons/cloud.png"
                          alt=""
                          v-if="!imageDataUrl"
                        />
                        <p v-else>
                          <img :src="imageDataUrl" alt="Selected Logo" />
                        </p>

                        <p class="mb-0">
                          <input
                            type="file"
                            id="fileInput"
                            @change="displayImage"
                            class="form-control-file"
                            accept="image/*"
                            alt=""
                          />
                          <label for="fileInput" class="browse">Browse</label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(section, index) in additionalSections"
                  :key="index"
                  class="row mt-2"
                >
                  <div class="col-md-12">
                    <div>
                      <input
                        type="text"
                        placeholder="Section Header Name"
                        v-model="section.sectionName"
                        class="subHeadingHeader"
                        @blur="createOrUpdateEstimateSection(section)"
                        style="border: none; padding: 5px; font-size: 16px"
                      />
                      <span
                        id="btnGroupDrop1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="../../assets/icons/threeDot.jpg"
                          alt=""
                          class="dropdown-toggle"
                          aria-expanded="false"
                          style="
                            width: 12px;
                            height: 12px;
                            margin-bottom: 2px;

                            cursor: pointer;
                          "
                        />
                      </span>
                      <div
                        class="dropdown-menu m-0 p-1 text-black p-2"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <p @click="deleteSection(section.id)" style="font-size: 12px">
                          Delete
                        </p>
                      </div>
                    </div>

                    <textarea
                      class="form-control p-1"
                      rows="10"
                      placeholder="Write here......"
                      v-model="section.content"
                      @blur="createOrUpdateEstimateSection(section)"
                    ></textarea>
                  </div>
                </div>

                <div class="container mb-5">
                  <button class="browse" @click="addNewSection">+ Add Section</button>
                </div>

                <footer
                  class="row fixed-bottom justify-content-center mb-2 bg-light footerOfSetting"
                  v-if="isAdmin"
                >
                  <div class="text-center">
                    <button
                      v-if="CompanyId !== null"
                      @click="UpdateCompanyDetails"
                      class="btn btn-sm btn-primary"
                    >
                      Update
                    </button>
                    <button v-else @click="postData" class="saveButton">Save</button>

                    <button class="btn btn-sm btn-secondary cancelButton">Cancel</button>
                  </div>
                </footer>
              </div>
              <div v-if="activeSubHeading === 'agreements'">
                <div class="row container">
                  <!-- Left column for uploading Sales Service -->
                  <div class="col-md-6">
                    <h5 class="SubheadingUnderLine">Sales Service</h5>
                    <div
                      class="col-md-9 p-2 pdfContainer d-flex flex-column justify-content-center align-items-center"
                      @dragover.prevent
                      @drop="handleDrop"
                    >
                      <img src="../../assets/icons/cloud.png" alt="" />
                      <p>Drag and Drop file here</p>
                      <p></p>
                      <p>OR</p>
                      <p class="mb-0">
                        <input
                          type="file"
                          id="fileInput2"
                          @change="uploadDocument"
                          class="form-control-file"
                          accept=".pdf"
                          style="display: none"
                        />
                        <label for="fileInput2" class="browse" style="cursor: pointer"
                          >Browse</label
                        >
                      </p>
                    </div>
                  </div>

                  <!-- Right column for showing upload progress -->
                  <div class="col-md-4">
                    <h5 class="SubheadingUnderLine">Upload Progress</h5>
                    <div v-if="uploadProgress !== null">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{ width: uploadProgress + '%' }"
                          :aria-valuenow="uploadProgress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ uploadProgress }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row container">
                  <!-- Left column for uploading Sales Service -->
                  <div class="col-md-6">
                    <h5 class="SubheadingUnderLine">Sales Service</h5>
                    <div
                      class="col-md-9 p-2 pdfContainer d-flex flex-column justify-content-center align-items-center"
                      @dragover.prevent
                      @drop="handleDrop"
                    >
                      <img src="../../assets/icons/cloud.png" alt="" />
                      <p>Drag and Drop file here</p>
                      <p></p>
                      <p>OR</p>
                      <p class="mb-0">
                        <input
                          type="file"
                          id="fileInput2"
                          @change="uploadDocument"
                          class="form-control-file"
                          accept=".pdf"
                          style="display: none"
                        />
                        <label for="fileInput2" class="browse" style="cursor: pointer"
                          >Browse</label
                        >
                      </p>
                    </div>
                  </div>

                  <!-- Right column for showing upload progress -->
                  <div class="col-md-4">
                    <h5 class="SubheadingUnderLine">Upload Progress</h5>
                    <div v-if="uploadProgress !== null">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{ width: uploadProgress + '%' }"
                          :aria-valuenow="uploadProgress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ uploadProgress }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="activeSubHeading === 'material'">
                <div class="row mt-1 col-md-12">
                  <div class="col-md-7"></div>
                  <div
                    style="margin-left: 58%"
                    class="col-md-5 text-nowrap text-end createSourcebutton d-flex justify-content-around"
                  >
                    <button
                      @click="triggerFileInput"
                      class="btn btn-sm bg-body-secondary"
                    >
                      Import
                    </button>
                    <input
                      type="file"
                      id="BulkMaterial"
                      style="display: none"
                      @change="handleFileUpload"
                    />

                    <button
                      class="btn btn-sm btn-outline-success"
                      @click="downloadTemplate"
                    >
                      Download Template
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#addMaterialModal"
                      data-bs-whatever="@mdo"
                      @click="showModal"
                    >
                      Add Material +
                    </button>
                  </div>
                </div>
                <div style="margin: 0 3%">
                  <!-- Table with scrollable content -->
                  <div class="table-responsive mt-2 mx-3 table-wrapper">
                    <table class="table tableFormat">
                      <caption class="d-none">
                        Table
                      </caption>
                      <thead>
                        <tr class="subHeaderHeadingBgColor text-nowrap">
                          <th class="text-start">Sl.No</th>
                          <th>Material Name</th>
                          <th>Discount</th>
                          <th>Unit Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody class="text-nowrap">
                        <!-- Loop through the array to populate rows -->
                        <tr v-for="(item, index) in Material" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.materialName }}</td>
                          <td>{{ item.discount }}%</td>
                          <td>{{ $formatToIndianCurrency(item.unitPrice) }}</td>
                          <td>
                            <div>
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                alt=""
                                id="approve"
                                style="
                                  width: 20px;
                                  height: 20px;
                                  margin: 0 10px;
                                  cursor: pointer;
                                "
                                data-bs-toggle="modal"
                                data-bs-target="#addMaterialModal"
                                @click="showModal(item)"
                              />
                              <img
                                src="../../assets/icons/redDelete.png"
                                alt=""
                                id="approve"
                                @click="deleteMaterial(item.id)"
                                style="width: 20px; height: 20px; cursor: pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 row m-0 p-0" v-if="this.activeHeading === 'OnSite'">
            <div class="col-md-2 SubheadingOfSales">
              <p
                :class="{
                  'blueactive-SubHeading': activeSubHeading === 'Contractor',
                  'inactive-SubHeading': activeSubHeading !== 'Contractor',
                }"
                @click="changeSubHeading('Contractor')"
              >
                Contractor
              </p>
            </div>
            <div class="container-fluid col-md-10 p-1">
              <div v-if="activeSubHeading === 'Contractor'">
                <div class="d-flex justify-content-between align-items-center mt-1">
                  <div
                    class="category-title ms-5"
                    style="font-size: 18px; font-weight: 600"
                  >
                    1. Category List
                  </div>
                  <div class="createSourcebutton">
                    <button
                      type="button"
                      class="browse"
                      data-bs-toggle="modal"
                      data-bs-target="#AddOnSiteCategory"
                      data-bs-whatever="@mdo"
                      @click="showOnsiteModal('null')"
                    >
                      Add Category +
                    </button>
                  </div>
                </div>

                <div style="margin: 0 3%">
                  <!-- Table with scrollable content -->
                  <div class="table-responsive mt-2 mx-3 files-table">
                    <table class="table tableFormat">
                      <caption class="d-none">
                        Table
                      </caption>
                      <thead>
                        <tr class="subHeaderHeadingBgColor text-nowrap">
                          <th class="text-left">Sl.No</th>
                          <th class="text-left">Category Name</th>

                          <th class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody class="text-nowrap">
                        <!-- Loop through the array to populate rows -->
                        <tr v-for="(item, index) in categories" :key="index">
                          <td class="text-left">{{ index + 1 }}</td>
                          <td class="text-left">{{ item.categoryName }}</td>

                          <td class="text-center">
                            <div>
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                alt=""
                                id="approve"
                                style="
                                  width: 20px;
                                  height: 20px;
                                  margin: 0 10px;
                                  cursor: pointer;
                                "
                                data-bs-toggle="modal"
                                data-bs-target="#AddOnSiteCategory"
                                @click="showOnsiteModal(item.id)"
                              />
                              <img
                                src="../../assets/icons/redDelete.png"
                                alt=""
                                id="approve"
                                @click="deleteCategory(item.id)"
                                style="width: 20px; height: 20px; cursor: pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 row m-0 p-0" v-if="this.activeHeading === 'Procurement'">
            <div class="col-md-2 SubheadingOfSales">
              <p
                :class="{
                  'blueactive-SubHeading': activeSubHeading === 'Addresses',
                  'inactive-SubHeading': activeSubHeading !== 'Addresses',
                }"
                @click="changeSubHeading('Addresses')"
              >
                Addresses
              </p>
            </div>
            <div class="container-fluid col-md-10 p-1" style="background-color: #f8f8f8">
              <div v-if="activeSubHeading === 'Addresses'">
                <div class="d-flex justify-content-between align-items-center mt-1">
                  <div
                    class="category-title ms-5"
                    style="font-size: 18px; font-weight: 600"
                  >
                    Addresses
                  </div>
                  <div class="createSourcebutton">
                    <button
                      type="button"
                      class="browse"
                      data-bs-toggle="modal"
                      data-bs-target="#addAddressesModal"
                      data-bs-whatever="@mdo"
                      @click="openModal(null)"
                    >
                      Add Address +
                    </button>
                  </div>
                </div>
                <div style="margin: 1% 3%">
                  <div class="card-container">
                    <div
                      v-for="(address, index) in Addresses"
                      :key="index"
                      class="card cardOfAddreess"
                    >
                      <div class="card-content">
                        <h5 style="color: purple" :title="address.name">
                          {{ address.name }}
                        </h5>
                        <p :title="address.address" class="text-ellipsis">
                          {{ address.address }}
                        </p>
                        <p
                          :title="`${address.city}, ${address.state} - ${address.pincode}`"
                          class="text-ellipsis"
                        >
                          {{ address.city }}, {{ address.state }} - {{ address.pincode }}
                        </p>
                        <p :title="address.email" class="text-ellipsis">
                          {{ address.email }}
                        </p>
                        <p :title="address.contactNo" class="text-ellipsis">
                          {{ address.contactNo }}
                        </p>
                      </div>
                      <div class="card-actions">
                        <span @click="openModal(address.id)">
                          <img
                            src="../../assets/icons/yellowEdit.png"
                            data-toggle="tooltip"
                            data-placement="top-start"
                            title="Edit"
                            data-bs-toggle="modal"
                            data-bs-target="#addAddressesModal"
                            data-bs-whatever="@mdo"
                            alt="Edit Icon"
                          />
                        </span>
                        <span @click="deleteAddress(address.id, index)">
                          <img
                            src="../../assets/icons/redDelete.png"
                            data-toggle="tooltip"
                            data-placement="top-start"
                            title="Delete"
                            alt="Delete Icon"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showBulkUploadModal">
    <div class="modal mt-5" role="dialog" style="display: block">
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="min-width: 60%; margin-left: 30%; margin-top: 5%"
      >
        <div
          class="modal-content"
          style="
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          "
        >
          <div class="modal-header">
            <h5 class="modal-title">Bulk Upload Materials</h5>
            <img
              src="../../assets/icons/cancel.png"
              alt=""
              style="
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 2.5%;
                cursor: pointer;
              "
              @click="closeModal()"
            />
          </div>
          <div class="modal-body" style="max-height: 320px; overflow: auto">
            <table
              style="max-height: 320px; overflow: auto"
              class="table table-responsive p-2"
              v-if="data.materialLibrary.length"
            >
              <caption class="d-none">
                Table
              </caption>
              <thead class="pb-5">
                <tr>
                  <th v-for="(value, key) in data.materialLibrary[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lead, index) in data.materialLibrary" :key="index">
                  <td v-for="(value, key) in lead" :key="key">
                    <span v-if="!value" style="color: red">Please fill this field</span>
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-sm btn-primary confirmationButton"
              @click="approveBulkLeads"
            >
              Continue Upload
            </button>
            <button type="button" class="btn btn-sm- btn-secondary" @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddAddressesModal from "../Modal/AddAddressesModal.vue";
import AddMaterialModal from "../Modal/AddMaterialModal.vue";
import AddOnSiteCategoryModal from "../Modal/AddOnSiteCategoryModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
import * as XLSX from "xlsx";

export default {
  name: "Setting",
  components: {
    Header,
    Nav,
    AddMaterialModal,
    AddOnSiteCategoryModal,
    AddAddressesModal,
  },
  data() {
    return {
      isAdmin: false,
      Addresses: [],
      showBulkUploadModal: false,
      categories: [],
      additionalSections: [],
      selectedCategoryId: null,
      selectedMaterialId: null,
      showRejectionModal: false,
      Material: [],
      selectedAddressId: null,
      CompanyId: "",
      CompanyData: {
        companyName: "",
        contactPerson: "",
        contactNumber: "",
        companyEmail: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
      },
      bankDetails: {
        id: null,
        accountName: "",
        accountNumber: "",
        bankName: "",
        IFSC: "",
        branchAddress: "",
      },
      BankId: " ",
      imageDataUrl: "",
      activeHeading: "Sales",
      activeSubHeading: "EstimateDetails",
      uploadProgress: null,
      data: {
        materialLibrary: [],
      },
      // "EstimateDetails"
    };
  },

  mounted() {
    let user = sessionStorage.getItem("user-info");
    if (user) {
      try {
        const userData = JSON.parse(user);
        const userObject = userData.user;
        this.CompanyId = userObject.companyId;
        this.userId = userObject.userId;
        this.isAdmin = this.$LocalUser.employeeRoles.some(
          (role) => role.team === "Admin"
        );
      } catch (error) {
        this.$handleError(error);
      }
    } else {
      this.$router.push({ name: "Login" });
    }
    console.log(this.CompanyId);
    this.featchCompanyLogo(this.CompanyId);
    this.getAllEstimateSection();
    this.featchMaterial();
    this.featchCompanyData();
    this.featchBankDetails();
    this.fetchCategories();
  },
  methods: {
    openModal(TargetId) {
      this.selectedAddressId = TargetId;
    },
    deleteAddress(Id, index) {
      this.Addresses.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/procurementAddress/${Id}`)
        .then((response) => {
          this.Addresses = response.data;

          console.log(this.Addresses);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    featchAddress() {
      axios
        .get(`${this.$apiEndPoint}/procurementAddress/`)
        .then((response) => {
          this.Addresses = response.data;
          console.log(this.Addresses);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    triggerFileInput() {
      document.getElementById("BulkMaterial").click();
    },
    handleFileUpload(event) {
      // Initialize or clear the data for material library
      this.data.materialLibrary = [];

      // Get the first file from the event's file input
      const file = event.target.files[0];

      if (file) {
        // Create a new FileReader to read the content of the file
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        // Set up the onload event, which will trigger when the file is read successfully
        reader.onload = async (e) => {
          try {
            // Get the raw binary data from the file
            const data = e.target.result;

            // Parse the binary data into a workbook object using XLSX library
            const workbook = XLSX.read(data, { type: "binary" });

            // Get the name of the first sheet in the workbook
            const sheetName = workbook.SheetNames[0];

            // Get the worksheet data from the first sheet
            const worksheet = workbook.Sheets[sheetName];

            // Convert the worksheet data into JSON format, with each row represented as an array
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Define the header name for the date column, used for special processing
            const dateColumnHeader = "date";

            // Get the headers from the first row
            const headers = jsonData[0];

            // Map through each row of data, transforming it into an object with key-value pairs
            const formattedData = jsonData.slice(1).map((row) => {
              const lead = {};

              // Loop through all headers to ensure every column is accounted for
              headers.forEach((key, index) => {
                const cell = row[index]; // Get the cell data at the current index

                if (key === dateColumnHeader && cell !== undefined) {
                  // If the key is 'date' and the cell is not empty, convert the Excel date to a readable format (DD/MM/YYYY)
                  const excelDate = Number(cell);
                  const date = new Date((excelDate - 25569) * 86400 * 1000);
                  lead[key] = this.convertToDDMMYYYY(date);
                } else {
                  // For all other keys, assign the cell value or set to an empty string if undefined
                  lead[key] = cell !== undefined ? cell : "";
                }
              });

              return lead;
            });

            // Assign the processed data to a variable in your component for further use
            this.data.materialLibrary = formattedData;

            // Show the bulk upload modal to the user
            this.showBulkUploadModal = true;
          } catch (error) {
            // Log any errors that occur during file reading or processing
            this.$handleError(error);
          }
        };
      }
    },

    async approveBulkLeads() {
      console.log(this.data);
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      try {
        const backendData = {
          materialLibrary: this.data.materialLibrary.map((lead) => ({
            materialName: lead.Materialname,
            discount: lead.Discount,
            unitPrice: lead.Unitprice,
          })),
        };

        this.loading = true;
        const response = await axios.post(
          `${this.$apiEndPoint}/materialLibrary/bulkCreate/`,
          backendData
        );
        console.log(response.data);
        btn.classList.remove("confirmationButton--loading");
        if (response.status === 201) {
          this.$toast(` Bulk materials Upload successfull.`, "success");
          this.showBulkUploadModal = false;
          this.featchMaterial();
          this.data.materialLibrary = [];
          this.loading = false;

          // Handle success
        } else {
          this.$toast(`Failed to upload bulk materials  .`, "error");
          console.error("Failed to create bulk materialLibrary");
          this.loading = false;
          // Handle error
        }
      } catch (error) {
        this.loading = false;
        this.showBulkUploadModal = false;
        this.data.materialLibrary = [];
        this.$handleError(error);
        btn.classList.remove("confirmationButton--loading");
        // Handle error
      }
    },
    closeModal() {
      // Close modal logic
      this.data.materialLibrary = [];
      this.showBulkUploadModal = false;
    },
    deleteCategory(itemId) {
      this.categories = this.categories.filter((item) => item.id !== itemId);
      axios
        .delete(`${this.$apiEndPoint}/OnSiteCategory/${itemId}`)
        .then((response) => {
          this.fetchCategories();
          // If successful, remove the item from the Material array

          console.log("Material deleted successfully", response.data);
        })
        .catch((error) => {
          this.$handleError(error);
          // Handle error if necessary
        });
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.href = "/Material_Library_Import_Template.csv"; // Path to the file in the public folder
      link.setAttribute("download", "Material_Library_Import_Template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async fetchCategories() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteCategory`);
        this.categories = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    featchCompanyLogo(CompanyId) {
      const PayLoad = {
        entityId: CompanyId,
        fileCategory: "logo",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          console.log(response.data);

          // Update profileImage with the received URL
          this.imageDataUrl = Object.values(response.data)[0];
          // Assuming '02.jpg' is the key for the profile photo URL
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    deleteSection(sectionId) {
      axios
        .delete(`${this.$apiEndPoint}/estimateSections/${sectionId}`)
        .then((response) => {
          console.log("Section deleted successfully:", response.data);
          this.getAllEstimateSection(); // Refresh the sections after deletion
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    addNewSection() {
      this.additionalSections.push({ sectionName: "", content: "", id: null });
    },

    createOrUpdateEstimateSection(section) {
      // Ensure the section has a valid sectionName and content
      if (!section.sectionName || !section.content) {
        return;
      }

      // Check if the section already exists by comparing sectionName
      const existingSection = this.additionalSections.find((s) => s.id === section.id);

      if (existingSection.id) {
        // If section already exists, update it
        axios
          .put(`${this.$apiEndPoint}/estimateSections/${existingSection.id}`, {
            sectionName: section.sectionName,
            content: section.content,
          })
          .then((response) => {
            console.log("Estimate section updated:", response.data);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        // If section does not exist, create a new section
        axios
          .post(`${this.$apiEndPoint}/estimateSections/`, {
            sectionName: section.sectionName,
            content: section.content,
          })
          .then((response) => {
            section.id = response.data.id;

            console.log("Estimate section created:", response.data);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    getAllEstimateSection() {
      axios
        .get(`${this.$apiEndPoint}/estimateSections/`)
        .then((response) => {
          this.additionalSections = response.data;
          console.log("Estimate sections fetched:", response.data);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    showModal(item) {
      this.selectedMaterialId = item.id;
    },
    showOnsiteModal(item) {
      this.selectedCategoryId = item;
    },

    deleteMaterial(itemId) {
      axios
        .delete(`${this.$apiEndPoint}/materialLibrary/${itemId}`)
        .then((response) => {
          // If successful, remove the item from the Material array
          this.Material = this.Material.filter((item) => item.id !== itemId);
          console.log("Material deleted successfully", response.data);
        })
        .catch((error) => {
          this.$handleError(error);
          // Handle error if necessary
        });
    },
    rejectDocument() {
      // Handle rejection logic here, including sending rejection remarks if needed
      this.showRejectionModal = false;
    },

    openRejectModal() {
      this.showRejectionModal = true;
    },
    featchCompanyData() {
      axios
        .get(`${this.$apiEndPoint}/company/${this.CompanyId}`)
        .then((response) => {
          this.CompanyData = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    featchMaterial() {
      axios
        .get(`${this.$apiEndPoint}/materialLibrary/`)
        .then((response) => {
          this.Material = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    featchBankDetails() {
      axios
        .get(`${this.$apiEndPoint}/bankDetails/byEntityId/${this.CompanyId}/Company`)
        .then((response) => {
          console.log(this.CompanyId);
          this.bankDetails = response.data;
          this.bankDetails.id = response.data.id;
          console.log(this.bankDetails);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    handleDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.handleFile(file);
    },
    handleFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageDataUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    uploadDocument(event) {
      const file = event.target.files[0];
      this.handleFile(file);
      this.simulateUploadProgress();
    },
    simulateUploadProgress() {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        this.uploadProgress = progress;
        if (progress >= 100) {
          clearInterval(interval);
          this.uploadProgress = null;
        }
      }, 1000);
    },

    changeSubHeading(subheading) {
      this.activeSubHeading = subheading;
    },
    changeHeading(heading) {
      this.activeHeading = heading;
      if (heading === "Sales") {
        this.activeSubHeading = "EstimateDetails";
      } else if (heading === "OnSite") {
        this.activeSubHeading = "Contractor";
      } else if (heading === "Procurement") {
        this.featchAddress();
        this.activeSubHeading = "Addresses";
      }
    },

    displayImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageDataUrl = e.target.result;
        };
        // Make API call to get signed URL for file upload
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "logo",
            moduleName: "company",
            entityId: this.CompanyId,
            entityName: "logo",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;

            // Use XMLHttpRequest to upload the file to the signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  console.log("Profile photo uploaded successfully:", xhr.responseText);
                  // Handle successful upload
                } else {
                  console.error("Error uploading profile photo to S3:", xhr.responseText);
                  // Handle upload error
                }
              }
            };

            // Send the file as binary data
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
        reader.readAsDataURL(file);
        console.log(file.name);
      }
    },

    async UpdateCompanyDetails() {
      try {
        let companyUpdated = false;
        let bankUpdated = false;

        // Check if CompanyData has changed
        const originalCompanyData = await axios.get(
          `${this.$apiEndPoint}/company/${this.CompanyId}`
        );
        const isCompanyDataChanged =
          JSON.stringify(originalCompanyData.data) !== JSON.stringify(this.CompanyData);

        if (isCompanyDataChanged) {
          const companyResult = await axios.put(
            `${this.$apiEndPoint}/company/${this.CompanyId}`,
            this.CompanyData
          );

          console.log("Company details updated:", companyResult.data);
          companyUpdated = true;
        }
        console.log(this.bankDetails.id);
        if (this.bankDetails.id !== null) {
          await this.updateBankDetails();
        } else {
          await this.createBankDetails();
        }

        if (companyUpdated && bankUpdated) {
          this.$toast(`company and bankUpdated details updated successfully.`, "success");
        } else if (companyUpdated) {
          this.$toast(`company details updated successfully.`, "success");
        }
      } catch (error) {
        this.$handleError(error);
        // Handle error if necessary
      }
    },
    async updateBankDetails() {
      try {
        const bankDetailsPayload = {
          accountName: this.bankDetails.accountName,
          accountNumber: this.bankDetails.accountNumber,
          bankName: this.bankDetails.bankName,
          IFSC: this.bankDetails.IFSC,
          branchAddress: this.bankDetails.branchAddress,
        };
        const response = await axios.put(
          `${this.$apiEndPoint}/bankDetails/${this.CompanyId}/Company`,
          bankDetailsPayload
        );
        console.log("Bank details updated:", response.data);
        this.$toast(`bank details updated successfully.`, "success");
      } catch (error) {
        this.$handleError(error);
        // Optionally handle error (e.g., show an error message to the user)
      }
    },

    async createBankDetails() {
      try {
        const payload = {
          accountName: this.bankDetails.accountName,
          accountNumber: this.bankDetails.accountNumber,
          bankName: this.bankDetails.bankName,
          IFSC: this.bankDetails.IFSC,
          branchAddress: this.bankDetails.branchAddress,
          entityId: this.CompanyId,
          entityName: "Company",
        };

        const response = await axios.post(`${this.$apiEndPoint}/bankDetails/`, payload);
        console.log("Bank details created:", response.data);
      } catch (error) {
        this.$handleError(error);
        // Handle error if necessary
      }
    },

    async postData(e) {
      try {
        // Update the logo property with the image name
        this.CompanyData.logo = `${this.CompanyData.companyName}`;

        console.log("CompanyData before request:", this.CompanyData);

        const companyResult = await axios.post(
          `${this.$apiEndPoint}/company/`,
          this.CompanyData
        );
        console.log(companyResult.data);

        const CompanyId = companyResult.data.id;

        // Now, use the created CompanyId to post bank details
        this.bankDetails.companyId = CompanyId; // Update companyId in the data property

        const bankResult = await axios.post(
          `${this.$apiEndPoint}/bankDetails/`,
          this.bankDetails
        );
        console.warn("Bank details saved:", bankResult.data);

        // Reset the input fields
        this.CompanyData = {
          companyName: null,
          contactPerson: null,
          contactNumber: null,
          companyEmail: null,
          address: null,
          city: null,
          state: null,
          country: null,
          pincode: null,
          natureOfBusiness: null,
          termsAndConditions: null,
          materialSpecification: null,
          logo: null,
        };

        this.bankDetails = {
          companyId: null,
          accountName: null,
          accountNumber: null,
          bankName: null,
          IFSC: null,
          branchAddress: null,
        };
      } catch (error) {
        this.$handleError(error);
      }

      e.preventDefault();
    },
  },
};
</script>
<style scoped>
.SubheadingOfSales p {
  background-color: #f8f8f8;
  white-space: nowrap;
  margin: 10px 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
}

.pdfContainer img {
  width: 10%;
}

.pdfContainer {
  height: 250px;
  width: 350px;
  border: 2px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  margin-left: 5%;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;

  font-size: 14px;
}

input,
textarea,
select {
  border: 1.5px solid #000000;
  outline: none !important;
  font-size: 14px;
}

.subHeadings span:nth-child(2) {
  margin: 0 5%;
}

.subHeadings span {
  cursor: pointer;
}

.inputBorderColor {
  border-color: black;
}

.logoContainer {
  border: 2px dotted #333;
  padding: 10%;
  text-align: center;
  margin: 5%;
  max-width: 86%;
  max-height: 86%;
  min-width: 86%;
  min-height: 86%;
  border-radius: 15px;
}

.dropArea {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dropArea p {
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

#fileInput {
  display: none;
  margin-top: 5%;
}

label[for="fileInput"] {
  cursor: pointer;
  padding: 2% 20%;
  border-radius: 5px;
  margin-top: 2%;
}

.withImage {
  padding: 5%;
  /* Adjust the padding when an image is present */
  max-width: 100%;
  /* Ensure the image fits within the container */
  max-height: 100px;
  /* Limit the height of the image */
}

.withImage img {
  max-width: 100%;
  /* Ensure the image fits within the container */
  max-height: 100px;
  /* Limit the height of the image */
}

.table-wrapper {
  height: 70vh;
  overflow-y: scroll;
}
.table-wrapper thead th {
  position: sticky;
  top: 0;
  text-align: center;
  background-color: #ccc;
}

.table-wrapper thead th:nth-child(1) {
  text-align: center;
  border-radius: 5px 0 0px 5px;
}

.table-wrapper thead th:nth-child(5) {
  border-radius: 0px 5px 5px 0px;
}

.table-wrapper thead th:nth-child(3),
.table-wrapper thead th:nth-child(4) {
  text-align: right;
}

.table-wrapper tbody tr td {
  text-align: right;
}

.table-wrapper tbody tr td:nth-child(1),
.table-wrapper tbody tr td:nth-child(2) {
  text-align: left;
}

.table-wrapper tbody tr td:last-child {
  text-align: center;
}
.category-title {
  flex-grow: 1;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table thead tr {
  border-bottom: 2px solid #ccc;
}

.files-table th {
  background-color: #f8f8f8;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.createSourcebutton {
  flex-shrink: 0;
  margin-right: 1%;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.cardOfAddreess {
  width: 30%;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-content {
  flex: 1;
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.card-actions span {
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.card-actions span img {
  padding: 5px;
  width: 30px;
  height: 30px;
}
.card-actions span img:hover {
  background-color: #ccc;
  border-radius: 50%;
}
.footerOfSetting {
  width: calc(100% - 74px);
  margin: 12px 12px 12px 62px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  border-radius: 0 0px 15px 15px;
  font-family: "Inter", sans-serif;
}
</style>
