<template>
  <LeadFilterModal
    @apply-filters="fetchFilterData"
    :filterOptions="['CreatedBy', 'E-Status']"
  />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="FormatTable" v-if="showUnclaimedList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <div class="col-md-3 text-nowrap">
                <h5>
                  Estimates
                  <img
                    src="../../../assets/icons/addRows.png"
                    alt=""
                    @click="gotoCreateEstimate()"
                    id="CreateEstimateImage"
                    class="ms-3"
                    style="
                      width: 30px;
                      height: 30px;
                      padding: 5px;
                      background-size: cover;
                    "
                  />
                </h5>
              </div>
              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row" style="cursor: pointer">
                  <div class="col-md-4 tableTextEvenRow" @click="exportToCSV">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchEstimates"
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                    data-bs-toggle="modal"
                    data-bs-target="#LeadFilterModal"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper StandardScroller">
          <table class="tableFormat">
            <caption class="d-none">
              Table
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLeads('Archive')">Archive</p>
                    <p @click="deleteLead('ArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">Client Name</th>
                <th class="tableHeaderHeading text-right">Estimate No</th>

                <th class="tableHeaderHeading text-center">Date</th>
                <th class="tableHeaderHeading text-center">Customer Id</th>
                <th class="tableHeaderHeading text-right">Contact No</th>
                <th class="tableHeaderHeading text-left" v-if="$isManager('Sales')">
                  Created By
                </th>
                <th class="tableHeaderHeading">Status</th>
                <th class="tableHeaderHeading text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu text-black p-2 ms-3"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="OpensalesOrder(data)" v-if="data['salesOrders.id']">
                      SO Converted ({{ data["salesOrders.salesOrderNumber"] }})
                    </p>
                    <p
                      @click="convertSO(data)"
                      v-if="
                        data.status === 'Approved' &&
                        !data['salesOrders.id'] &&
                        data['customers.customerNumber'] &&
                        data['customers.customerNumber'].trim() !== ''
                      "
                    >
                      Convert to SO
                    </p>
                    <p @click="duplicate(data)">Duplicate</p>

                    <p @click="ArchiveLead(data, index)">Archive</p>
                    <p
                      @click="ReviseEstimate(data)"
                      v-if="data.status === 'Approved' || data.status === 'Sent'"
                    >
                      Revise
                    </p>
                    <p @click="DeleteLead(data, index)" v-if="data.status !== 'Approved'">
                      Delete
                    </p>
                  </div>
                </td>
                <td @click="OpenEstimate(data)">
                  {{ data["leads.name"] }}
                </td>
                <td>{{ data.estimateId }}</td>

                <td class="text-center">{{ data.date }}</td>
                <td class="text-center">{{ data["customers.customerNumber"] }}</td>
                <td>{{ data["leads.contactNo"] }}</td>
                <td class="text-left" v-if="$isManager('Sales')">
                  {{ data["employees.firstName"] }} {{ data["employees.lastName"] }}
                </td>
                <td>
                  <span v-if="data.status === 'Draft'" class=""> {{ data.status }}</span>
                  <span v-if="data.status === 'Sent'" class="text-warning">{{
                    data.status
                  }}</span>
                  <span
                    v-if="data.status === 'Approved' || data.status === 'Revised'"
                    class="text-success"
                    >{{ data.status }}
                    <img
                      v-if="data.customerComment"
                      src="../../../assets/icons/estimateInfo.png"
                      alt=""
                      style="padding: 5px; height: 25px; width: 25px; cursor: pointer"
                      @mouseover="hover = index"
                      @mouseleave="hover = null"
                    />
                    <div v-if="hover === index" class="comment-tooltip">
                      <p>{{ data.customerComment }}</p>
                    </div>
                  </span>

                  <span v-if="data.status === 'Rejected'" class="text-danger"
                    >{{ data.status }}
                    <img
                      v-if="data.customerComment"
                      src="../../../assets/icons/estimateInfo.png"
                      alt=""
                      style="padding: 5px; height: 25px; width: 25px; cursor: pointer"
                      @mouseover="hover = index"
                      @mouseleave="hover = null"
                    />
                    <div v-if="hover === index" class="comment-tooltip">
                      <p>{{ data.customerComment }}</p>
                    </div>
                  </span>
                  <span v-if="data.status === 'Finalized'" class="text-muted">{{
                    data.status
                  }}</span>
                </td>
                <td class="text-end">
                  {{ $formatToIndianCurrency(data.grandTotal) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToPreviousPage"
              :disabled="!hasPreviousPage"
              alt=""
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in pagination"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToNextPage"
              :disabled="!hasNextPage"
              alt=""
            />
          </div>

          <div class="entries-info">
            <span class="entries-text">Entries: {{ TableData.length }} Rows</span>
          </div>
        </div>
      </div>
      <div class="FormatTable" v-if="showArchiveList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <div class="col-md-3 text-nowrap active-SubHeading">
                <h5>Archived Estimates</h5>
              </div>
              <div class="col-md-1 ms-5">
                <img
                  src="../../../assets/icons/unarchive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport">
                <div class="row" style="visibility: hidden">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row" style="cursor: pointer">
                  <div class="col-md-4 tableTextEvenRow" @click="exportToCSV">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1" style="visibility: hidden">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchArchivedEstimates"
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                    data-bs-toggle="modal"
                    data-bs-target="#LeadFilterModal"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper StandardScroller">
          <table class="tableFormat">
            <caption class="d-none">
              Table
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllArchiveRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLeads('UnArchive')">Unarchive</p>
                    <p @click="deleteLead('UnArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">Client Name</th>
                <th class="tableHeaderHeading text-center">Date</th>
                <th class="tableHeaderHeading text-center">Customer Id</th>
                <th class="tableHeaderHeading text-right">Contact No</th>
                <th class="tableHeaderHeading text-right">Estimate No</th>
                <th class="tableHeaderHeading text-left" v-if="$isManager('Sales')">
                  Created By
                </th>
                <th class="tableHeaderHeading">Status</th>
                <th class="tableHeaderHeading text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedArchiveData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="convertSO(data)" v-if="data.status === 'Approved'">
                      convertSO
                    </p>
                    <p @click="duplicate(data)">Duplicate</p>

                    <p @click="ArchiveLead(data, index)">UnArchive</p>
                    <p @click="DeleteLead(data, index)" v-if="data.status !== 'Approved'">
                      Delete
                    </p>
                  </div>
                </td>
                <td @click="OpenEstimate(data)">
                  {{ data["leads.name"] }}
                </td>
                <td class="text-center">{{ data.date }}</td>
                <td class="text-center">{{ data["customers.customerNumber"] }}</td>

                <td>{{ data["leads.contactNo"] }}</td>
                <td>{{ data.estimateId }}</td>
                <td class="text-left" v-if="$isManager('Sales')">
                  {{ data["employees.firstName"] }} {{ data["employees.lastName"] }}
                </td>
                <td>{{ data.status }}</td>
                <td class="text-end">
                  {{ $formatToIndianCurrency(data.grandTotal) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToArchivePreviousPage"
              :disabled="!hasArchivePreviousPage"
              alt=""
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in paginationArchive"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToArchiveNextPage"
              :disabled="!hasArchiveNextPage"
              alt=""
            />
          </div>

          <div class="entries-info">
            <span class="entries-text">Entries: {{ ArchiveData.length }} Rows</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import LeadFilterModal from "@/components/Modal/LeadFilterModal.vue";
let pageFlag = true;
export default {
  name: "PropertyList",
  components: {
    Header,
    Nav,
    LeadFilterModal,
  },
  data() {
    return {
      totalCount: null,
      totalArchiveCount: null,
      filter: {},
      hover: null,
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedLeadId: null,
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchFilterData(filterData) {
      this.filter = filterData;
      pageFlag = true;
      console.log(this.filter); // Now it should properly log the filter data
      if (this.showUnclaimedList) {
        this.searchEstimates();
      } else {
        this.searchArchivedEstimates();
      }
    },
    OpensalesOrder(salesOrder) {
      this.$router.push({
        name: "CreateSalesOrder",
        params: {
          SalesOrderNumber: salesOrder["salesOrders.salesOrderNumber"],
          SalesOrderId: salesOrder["salesOrders.id"],
        },
      });
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },

    openModal(leadId) {
      this.selectedLeadId = leadId;
    },
    handleArchiveClick() {
      this.currentPage = 1;
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
      this.fetchArchiveData();
    },
    async fetchData() {
      let searchData = {
        searchString: this.searchString,
        status: this.filter.status?.length ? this.filter.status : [],
        salesPerson: this.filter.salesPerson?.length ? this.filter.salesPerson : [],
      };

      console.log(searchData);

      const hasSearchData =
        searchData.searchString ||
        searchData.status.length ||
        searchData.salesPerson.length;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
        console.log(pageFlag);
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/estimates/myEstimates/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchArchiveData() {
      let searchData = {
        searchString: this.searchString,
        status: this.filter.status?.length ? this.filter.status : [],
        salesPerson: this.filter.salesPerson?.length ? this.filter.salesPerson : [],
      };
      console.log(searchData);

      const hasSearchData =
        searchData.searchString ||
        searchData.status.length ||
        searchData.salesPerson.length;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
        console.log(pageFlag);
      }

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/estimates/myEstimates/${this.currentPage}/archived`,
          searchData
        );
        this.totalArchiveCount = response.data.count;
        this.ArchiveData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },

    // Method to delete the selected lead
    DeleteLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/estimates/deleteEstimates`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch(() => {});
    },

    ArchiveLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .put(`${this.$apiEndPoint}/estimates/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch(() => {});
    },

    async searchEstimates() {
      pageFlag = true;
      this.fetchData();
    },

    async searchArchivedEstimates() {
      pageFlag = true;
      this.fetchArchiveData();
    },
    //for all selected lead Unclaim and delete api

    ArchiveLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }

      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/estimates/archive`, payload)
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch(() => {});
    },

    deleteLead(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/estimates/deleteEstimates`, { data: payload })
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch(() => {});
    },

    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      console.log(this.currentPage);
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    createEstimate(selectedLead) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedLead.name,
          PropertyId: selectedLead.propertyId,
          ContactNo: selectedLead.contactNo,
          Email: selectedLead.email,
          leadId: selectedLead.id,
          address: selectedLead.address,
          propertyName: selectedLead["property.name"],
        },
      });
    },
    OpenEstimate(estimate) {
      if (estimate?.estimateId) {
        if (
          estimate.status === "Draft" ||
          estimate.status === "null" ||
          estimate.status === ""
        ) {
          this.$router.push({
            name: "CreateEstimate",
            params: {
              EstimateId: estimate.estimateId,
              estimateIndex: estimate.id,
            },
          });
        } else if (
          ["Sent", "Approved", "Finalized", "Rejected"].includes(estimate.status)
        ) {
          this.$router.push({
            name: "FinalEstimate",
            params: {
              EstimateId: estimate.estimateId,
              estimateIndex: estimate.id,
            },
          });
        } else {
          // Handle the scenario for other status values
          // console.error("Invalid status for routing");
        }
      } else {
        // Handle the scenario where estimate or estimateId is undefined
        // console.error("Estimate or estimateId is undefined");
      }
    },
    gotoCreateEstimate() {
      this.$router.push({
        name: "CreateEstimate",
      });
    },
    convertSO(estimate) {
      axios
        .post(`${this.$apiEndPoint}/salesOrder/${estimate.id}`)
        .then((response) => {
          console.log(response);
          const SalesOrderId = response.data;
          if (SalesOrderId) {
            this.$router.push({
              name: "CreateSalesOrder",
              params: {
                SalesOrderId: SalesOrderId,
              },
            });
          } else {
            // Handle the scenario where salesOrderNumber is undefined in the response
          }
        })
        .catch(() => {});
    },
    ReviseEstimate(estimate) {
      this.duplicateDownload(estimate);
    },

    duplicateDownload(estimate) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          estimateIndex: estimate.id,
          EstimateDetails: estimate,
        },
      });
    },

    duplicate(estimate) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          estimateIndex: estimate.id,
        },
      });
    },
    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    changeStatus(status) {
      this.selectedStatus = status;
      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },

    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.showArchiveList ? this.ArchiveData : this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        /*   Name: `${data.salutation} ${data.name}`, */
        /*  "Saturation":data.salutation, */
        "Client Name": data["leads.name"],
        Date: data.date,
        "Customer Id": data["customers.customerNumber"],
        "Contact No": data["leads.contactNo"],
        "Estimate No": data.estimateId,
        Status: data.status,
        grandTotal: data.grandTotal,
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.comment-tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-width: 200px;
  height: 100px;
  height: auto;
  z-index: 1000;
  word-break: break-all;
  white-space: pre-wrap;
}

.FormatTable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100% - 74px);
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.tableFormat thead {
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th:not(:first-child) {
  background-color: #bcbfc6;
}
.tableFormat th:nth-child(1),
.tableFormat td:nth-child(1) {
  display: inline-flex;

  background-color: #ffffff;
}
.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper
  .tableFormat
  tbody
  tr:not(:first-child):nth-child(even)
  td:not(:first-child) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 30px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }
  #addicon {
    width: 100%;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
