<template>
  <div class="HeaderofApp">
    <div class="header blackBgColor">
      <div class="div1">
        <img
          src="../assets/icons/black_burgerIcon.png"
          alt=""
          @click="expandNavContent"
          id="burgerMenu"
        />
        <img
          src="../assets/icons/ArivuLogo.png"
          alt=""
          style="width: 28px; height: 28px"
          class="arivuLogo WEB"
        />
        <h3 style="font-family: 'Athena', sans-serif" class="NameOfTheAppliction">
          arivu
        </h3>
      </div>

      <div class="div2">
        <div class="searchBar">
          <img class="searchIcon" src="../assets/icons/search.png" alt="searchIcon" />
          <input type="text" ref="searchBarInput" placeholder="Search...." />
        </div>
      </div>

      <div class="div3">
        <img
          src="../assets/icons/black_Setting.png"
          @click="SettingPage"
          style="cursor: pointer"
          v-if="showSalesSettings"
          alt="Setting"
        />
        <div class="dropdown-Menu" v-if="showEmployeeSettings">
          <!-- Dropdown menu items -->
          <!-- Insert your user information in this section -->
          <div class="menuItems">
            <a id="menuItemHeader" href="/EmployeeList">
              <img
                src="../assets/icons/black_Menu.png"
                alt="Menu"
                style="cursor: pointer"
            /></a>
          </div>
        </div>
        <div class="dropdown">
          <span class="dot dropbtn">
            <img
              v-if="EmployeeprofileImage !== null"
              :src="EmployeeprofileImage"
              class="uploaded-photo EmployeeprofileImage"
              alt="Profile"
            />
            <h5 v-else id="initials">{{ initials }}</h5>
          </span>

          <!-- Dropdown content -->
          <div class="dropdown-content">
            <!-- Display user profile information -->
            <div class="user-profile">
              <!-- Display user profile photo -->
              <div class="d-flex flex-column align-items-center justify-content-center">
                <img
                  v-if="EmployeeprofileImage"
                  :src="EmployeeprofileImage"
                  alt="Profile Photo"
                  class="profile-photo"
                />
                <img
                  v-else
                  class="profile-photo"
                  src="../assets/icons/profile.png"
                  alt="Profile Photo"
                />
              </div>

              <!-- Display user name, email, and department -->
              <div class="user-info">
                <p style="font-size: 14px; font-weight: 500; white-space: nowrap">
                  {{ fullName }}
                </p>
                <p class="text-muted" style="font-size: 10px">{{ userEmail }}</p>
                <!-- Display user roles in the specified format -->
                <p
                  class="text-muted"
                  style="font-size: 10px"
                  v-for="role in userRoles"
                  :key="role.team"
                >
                  {{ role.team }} <span style="font-size: 8px">({{ role.role }})</span>
                </p>
              </div>
            </div>

            <!-- Dropdown options (Profile, Settings, Logout) -->
            <div class="d-flex align-items-center" style="background-color: #f8f8f8">
              <span class="ms-2">
                <img
                  src="../assets/icons/profileIconBefore.png"
                  alt=""
                  style="width: 15px; height: 15px"
              /></span>

              <a
                style="font-size: 12px; font-weight: 500"
                class="mt-1"
                @click="goToEmployeeProfile"
                >Profile</a
              >
            </div>
            <div class="d-flex align-items-center" style="background-color: #f8f8f8">
              <span class="ms-2">
                <img
                  src="../assets/icons/setting.png"
                  alt=""
                  style="width: 15px; height: 15px"
              /></span>

              <a style="font-size: 12px; font-weight: 500" class="mt-1">Settings</a>
            </div>
            <div class="d-flex align-items-center" style="background-color: #f8f8f8">
              <span class="ms-2">
                <img
                  src="../assets/icons/logout.png"
                  alt=""
                  style="width: 15px; height: 15px"
              /></span>
              <a
                style="font-size: 12px; font-weight: 500; color: red"
                class="mt-1"
                @click="logout"
                >Logout</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DesktopHeader",
  components: {},
  data() {
    return {
      showEmployeeSettings: false,
      showSalesSettings: false,
      EmployeeprofileImage: null,
      initials: "",
      fullName: "",
      userEmail: "",
      userRoles: {},
    };
  },
  computed: {
    // Filter user roles based on the employeeId
    /*    userRoles() {
         return this.employeeRoles.filter(role => role.team.toLowerCase() === this.user.team.toLowerCase());
       } */
  },
  methods: {
    SettingPage() {
      if (this.showSalesSettings) {
        this.$router.push({ name: "Setting" });
      }
    },
    goToEmployeeProfile() {
      const user = sessionStorage.getItem("user-info");
      if (user) {
        try {
          const userData = JSON.parse(user);
          const userObject = userData.user;
          const encodedId = btoa(userObject.employeeId.toString());
          // Navigate to EmployeeProfile route with emplId parameter
          this.$router.push({
            name: "EmployeeProfile",
            params: { employeeId: encodedId },
          });
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    expandNavContent() {
      const navContent = document.querySelector(".nav-content");
      if (window.matchMedia("(max-width: 1025px)").matches) {
        navContent.style.width = navContent.style.width === "250px" ? "0px" : "250px";
        navContent.style.transition = "0.3s ease";
      }
    },
    fetchEmployeeProfile(employeeId) {
      const PayLoad = {
        entityId: employeeId,
        fileCategory: "profile",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.EmployeeprofileImage = Object.values(response.data)[0];
        })
        .catch((error) => {
          // Handle different error status codes
          if (error.response && error.response.status === 401) {
            this.logout();
          } else {
            this.$handleError(error);
          }
        });
    },

    logout() {
      sessionStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    let user = sessionStorage.getItem("user-info");
    let employeeRoles = sessionStorage.getItem("user-info");
    if (user) {
      try {
        const DepartmentData = JSON.parse(employeeRoles);
        const userData = JSON.parse(user);
        const userObject = userData.user;
        this.userRoles = DepartmentData.employeeRoles;
        this.initials = userObject.initials;
        this.fullName = `${userObject.firstName} ${userObject.lastName}`;
        this.userEmail = userObject.email;
        const employeeId = userObject.employeeId;

        this.userId = userObject.userId; // Set fullName in component's data
        this.fetchEmployeeProfile(employeeId);
        if (this.userRoles) {
          // Check if the user is a Manager in the Sales or Admin team
          this.showSalesSettings = this.userRoles.some(
            (role) =>
              (role.team === "Sales" ||
                role.team === "OnSite" ||
                role.team === "Admin") &&
              role.role === "Manager"
          );
          this.showEmployeeSettings = this.userRoles.some(
            (role) => role.team === "Admin" && role.role === "Manager"
          );
        }
      } catch (error) {
        this.$handleError(error);
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped>
.NameOfTheAppliction {
  font-weight: 100 !important;
}

.user-info {
  background-color: #ffffff;
}

a {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;

  cursor: pointer;
}

.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #2381f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: #000000;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ccc;
}

.user-profile {
  display: flex;
  align-items: flex-start;
  /* Align items to the top of the container */
  justify-content: space-around;

  padding: 10px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-info p {
  margin: 0;
  font-size: 14px;
  background-color: #ffffff;
}
.EmployeeprofileImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1px;
  background-color: #b1c5df;
}
</style>
