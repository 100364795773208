<template>
  <div
    class="modal fade"
    id="addPurchaseOrderInvoice"
    tabindex="-1"
    aria-labelledby="addPurchaseOrderInvoice"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-decoration-underline" id="exampleModalLabel">
            Add New Purchase Order Invoice
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-3">
                  <label for="referencePoNumber" class="form-label"
                    >Reference <span>(PO Number)</span></label
                  >
                </div>
                <div class="col-md-9" style="padding-left: 70px">
                  <input
                    v-model="name"
                    type="text"
                    class="form-control inputbg"
                    id="referencePoNumber"
                    required
                    style="margin-right: auto; width: 300px"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-3">
                  <label for="purchaseInvoiceNumber" class="form-label"
                    >Purchase Invoice Number</label
                  >
                </div>
                <div class="col-md-9" style="padding-left: 70px">
                  <input
                    v-model="name"
                    type="text"
                    class="form-control inputbg"
                    id="purchaseInvoiceNumber"
                    required
                    style="margin-right: auto; width: 300px"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-3">
                  <label for="inputAssignee" class="form-label">Upload Document</label>
                </div>
                <div class="col-md-9" style="padding-left: 70px">
                  <input
                    type="file"
                    class="form-control inputbg"
                    id="inputName"
                    required
                    style="margin-right: auto; width: 300px"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveRecord"
            data-bs-dismiss="modal"
            :disabled="name.trim().length === 0"
          >
            Save
          </button>
          <button type="button" class="cancelButton">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddPurchaseOrderInvoiceModal",
  data() {
    return {
      name: "",
      searchText: "",
      selectedEmployee: "",
      selectedAssignees: [],
      employees: [],
    };
  },
  methods: {
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    searchEmployees() {
      // Filter employees based on searchText
      // This function can be left empty if the filtering is done client-side
    },
    addEmployee() {
      if (this.selectedEmployee) {
        const employeeIndex = this.filteredEmployees.findIndex(
          (emp) => emp.employeeId === this.selectedEmployee
        );
        if (employeeIndex !== -1) {
          this.filteredEmployees.splice(employeeIndex, 1); // Remove the selected employee from filteredEmployees
        }
        this.selectedAssignees.push(this.selectedEmployee);
        this.selectedEmployee = ""; // Clear the selectedEmployee after adding
      }
    },

    removeEmployee(index) {
      const removedEmployeeId = this.selectedAssignees.splice(index, 1)[0]; // Remove the employee ID from selectedAssignees
      const employee = this.employees.find((emp) => emp.employeeId === removedEmployeeId);
      if (employee) {
        this.filteredEmployees.push(employee); // Push the removed employee back to filteredEmployees
        this.filteredEmployees.sort((a, b) => a.employeeId - b.employeeId); // Sort the list to maintain consistency
      }
    },

    getEmployeeFullName(employeeId) {
      const employee = this.employees.find((emp) => emp.employeeId === employeeId);
      return employee
        ? `${employee["employees.firstName"]} ${employee["employees.lastName"]}`
        : "";
    },
    async saveRecord() {
      const data = {
        name: this.name,
      };

      try {
        console.log(data);
        const response = await axios.post(`${this.$apiEndPoint}/source/`, data);
        console.log(response.data);
        const sourceId = response.data.id;
        this.selectedAssignees.push(this.$LocalUser.user.employeeId);
        console.log(this.selectedAssignees);

        if (this.selectedAssignees.length > 0) {
          // Check if the array is not empty
          const requestData = {
            salesPersonId: this.selectedAssignees, // Array of selected salesPersonIds
            sourceId: sourceId,
          };
          console.log(requestData);
          try {
            const salesPersonSourceResponse = await axios.post(
              `${this.$apiEndPoint}/salesPersonSource/`,
              requestData
            );
            console.log(salesPersonSourceResponse.data);
          } catch (error) {
            this.$handleError(error);
          }
        } else {
          console.log("No sales person selected."); // Handle the case when no sales person is selected
        }
        this.name = " ";
        this.selectedAssignees = " ";

        location.reload();
        this.$emit("sourceCreated");
        this.$showToast.success("Source created successfully.");

        this.sourceCreated();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
  created() {
    this.fetchEmployees();
  },
};
</script>
<style scoped>
.modal-lg {
  max-width: 50% !important; /* Custom width for larger modal */
  max-height: 80vh;
}
.modal-content {
  height: 100%; /* Make sure the content takes full height */
}
.modal-body {
  max-height: calc(80vh - 200px); /* Adjust the modal body's height */
  overflow-y: auto; /* Make the modal body scrollable if content exceeds height */
  padding: 15px; /* Adding padding for better spacing */
}
.form-label {
  white-space: nowrap; /* Prevent label text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis (...) if the text is too long */
  display: block; /* Ensure labels are block-level elements */
}
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
/* Add your scoped styles here */
.selected-employee {
  margin-bottom: 5px;
}

.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}
input,
select {
  border: 1px solid #000000;
  outline: none !important;
  width: 50%;
}
select {
  height: fit-content;
}
</style>
