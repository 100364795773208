<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex align-content-center">
              <div class="col-md-6 d-flex">
                <div class="col-md-3 text-nowrap active-SubHeading">
                  <h5>
                    Debit Note
                    <a href="/CreateDebitNote" class="plus-icon">
                      <img
                        src="../../assets/icons/blueAddicon.png"
                        alt=""
                        class="ms-3"
                        style="width: 20px; height: 20px"
                        id="addicon"
                      />
                    </a>
                  </h5>
                </div>
                <div class="col-md-1"></div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Export</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>

                <div class="col-md-1" style="visibility: hidden">
                  <img
                    src="../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchLeads"
                    />
                  </div>
                  <span>
                    <img
                      src="../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th>Debit Note No.</th>
                  <th>GRN No.</th>
                  <th>Reference Invoice No.</th>
                  <th>Date</th>
                  <th>Vendor Name</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(data, index) in displayedData" :key="index">
                  <td>{{ data.debitNoteNo }}</td>
                  <td>{{ data["onSiteGrn.grnNumber"] }}</td>
                  <td>{{ data["onSiteGrn.grnNumber"] }}</td>
                  <td>{{ this.$formatDateDMY(data.date) }}</td>
                  <td>{{ data["vendors.companyName"] }}</td>
                  <td class="text-center">
                    <span class="text-center">
                      <img
                        @click="handleDownload(data.id)"
                        src="../../assets/icons/pinkDownold.png"
                        alt=""
                        style="width: 17px; height: 17px"
                      />

                      <img
                        @click="openModal(data.id)"
                        data-bs-target="#DebitNoteDetailsModal"
                        data-bs-toggle="modal"
                        class=""
                        src="../../assets/icons/view.png"
                        alt=""
                        style="width: 17px; height: 17px; margin: 0 20px"
                      />
                      <img
                        @click="deleteLead(data.id, index)"
                        class="ms-1"
                        src="../../assets/icons/redDelete.png"
                        alt=""
                        style="width: 17px; height: 17px"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="changePage(-1)"
                :disabled="currentPage === 1"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="changePage(1)"
                :disabled="currentPage === pagination.length"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
        <div class="FormatTable" v-if="showArchiveList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex align-content-center">
              <div class="col-md-6 d-flex">
                <div class="col-md-3 text-nowrap active-SubHeading">
                  <h5>Archive in {{ statusName }}</h5>
                </div>
                <div class="col-md-2" style="visibility: hidden">
                  <img
                    src="../../assets/icons/blueAddicon.png"
                    alt=""
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                    data-bs-whatever="@mdo"
                    id="addicon"
                  />
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport">
                  <div class="row" style="visibility: hidden">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Export</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>

                <div class="col-md-1">
                  <img
                    src="../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchArchiveLeads"
                    />
                  </div>
                  <span>
                    <img
                      src="../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        v-model="selectAll"
                        @change="selectAllArchiveRows"
                      />
                    </div>
                    <span
                      id="btnGroupDrop1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="../../assets/icons/threeDot.jpg"
                        alt=""
                        class="dropdown-toggle"
                        aria-expanded="false"
                        id="blackThreeDots"
                      />
                    </span>
                    <div
                      class="dropdown-menu ms-3 text-black p-2"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <p @click="ArchiveLeads('UnArchive')">UnArchive</p>
                      <p @click="deleteLead('UnArchiveDelete')">Delete</p>
                    </div>
                  </th>
                  <th>Project Name</th>
                  <th>Customer Name</th>
                  <th v-if="statusName === 'InProgress' || statusName === 'Completed'">
                    Designer
                  </th>
                  <th v-if="statusName === 'New'">Contact No</th>
                  <th v-if="statusName === 'New'">Email Id</th>
                  <th>Property Name</th>
                  <th v-if="statusName === 'InProgress' || statusName === 'Completed'">
                    Start Date
                  </th>
                  <th v-if="statusName === 'InProgress'">Status</th>
                  <th v-if="statusName === 'Completed'">End Date</th>
                  <th class="tableHeaderHeading" v-if="statusName === 'Completed'">
                    Documents
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayedArchiveData" :key="index">
                  <td class="bodyText">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        v-model="selectedCustomers"
                        :value="data.id"
                      />
                    </div>
                    <span
                      id="btnGroupDrop1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="../../assets/icons/threeDot.jpg"
                        alt=""
                        class="dropdown-toggle"
                        aria-expanded="false"
                        id="blackThreeDots"
                      />
                    </span>
                    <div
                      class="dropdown-menu ms-3 text-black p-2"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <p @click="ArchiveLead(data, index)">UnArchive</p>
                      <p @click="DeleteLead(data, index)">Delete</p>
                    </div>
                  </td>
                  <td @click="navigateToProjectProfile(data)">{{ data.projectName }}</td>
                  <td>{{ data.customerNumber }}</td>
                  <td v-if="statusName === 'InProgress' || statusName === 'Completed'">
                    {{ data.assignTo }}
                  </td>
                  <td v-if="statusName === 'New'">{{ data.ContactNo }}</td>
                  <td v-if="statusName === 'New'">{{ data.EmailId }}</td>
                  <td>{{ data.buildingConfiguration }}</td>
                  <td v-if="statusName === 'InProgress' || statusName === 'Completed'">
                    {{ data.designStartDate }}
                  </td>
                  <td v-if="statusName === 'InProgress'">{{ data.designStage }}</td>
                  <td v-if="statusName === 'Completed'">{{ data.designEndDate }}</td>
                  <td
                    class="tableHeaderHeading d-flex align-content-center"
                    v-if="statusName === 'Completed'"
                  >
                    <img
                      class="ms-3"
                      src="../../assets/icons/import.png"
                      alt=""
                      style="width: 17px; height: 17px"
                    />
                    <img
                      class="ms-3"
                      src="../../assets/icons/view.png"
                      alt=""
                      style="width: 17px; height: 17px"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="changePage(-1)"
                :disabled="currentPage === 1"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in paginationArchive"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="changePage(1)"
                :disabled="currentPage === paginationArchive.length"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text"
                >Entries: {{ displayedArchiveData.length }} Rows</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <div v-if="showModal" class="modal-overlay" @click="openApprovalModal"></div>
  </transition>
  <transition name="slide">
    <div class="modal show d-block" v-if="showModal" style="z-index: 9999999">
      <div class="modal-dialog modal-dialog-centered">
        <div
          class="modal-content"
          style="
            justify-content: center;
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            transition: all 0.3s ease-in-out;
            width: auto !important ;
          "
        >
          <div class="modal-header">
            <p class="modal-title text-center">
              Are you sure you want to processed with Discovery Session for
              <span style="font-weight: 600">{{ this.projectName }}</span> ?
            </p>
          </div>

          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-primary" @click="AcceptProject">
              Yes
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="showModal = false"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <DebitNoteDetailsModal
    :SelectedDebitNoteId="selectedDebitNoteId"
    ref="PODetailsModal"
  />
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

import DebitNoteDetailsModal from "../Modal/DebitNoteDetailsModal.vue";
export default {
  name: "DesignProjectTableByStatus",
  components: {
    Header,
    Nav,
    DebitNoteDetailsModal,
  },
  data() {
    return {
      selectedDebitNoteId: null,
      projectName: null,
      AssignedProjectId: null,
      showModal: false,
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedLeadId: null,
      statusName: "",
      selectedProjectId: null, // New data property for the selected project ID
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.TableData.length / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.ArchiveData.length / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.TableData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.ArchiveData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    displayedData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.TableData.slice(startIndex, endIndex);
    },
    displayedArchiveData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.ArchiveData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleDownload(id) {
      this.selectedDebitNoteId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PODetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openModal(leadId) {
      console.log(this.selectedDebitNoteId);
      this.selectedDebitNoteId = leadId;
      console.log(this.selectedDebitNoteId); // Set the selected lead ID when a lead is clicked
    },
    async AcceptProject() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/designProjects/${this.AssignedProjectId}`,
          {
            designStage: "Discovery Session",
            projectStatus: "In Progress",
          }
        );

        if (response.status === 200) {
          this.fetchData();

          this.$toast("Document approved successfully", "success");
        }
        this.showModal = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    openApprovalModal(Id) {
      this.AssignedProjectId = Id;
      const project = this.TableData.find(
        (project) => project.id === this.AssignedProjectId
      );

      if (project) {
        this.projectName = project.projectName;
      }
      this.showModal = true;
    },

    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Error downloading file:", xhr.statusText);
        }
      };

      xhr.onerror = function () {
        console.error("Error downloading file:", xhr.statusText);
      };

      xhr.send();
    },
    acceptProject(projectId) {
      this.selectedProjectId = projectId;
    },
    navigateToProjectProfile(data) {
      // Navigate to ProjectProfile page with project ID as parameter
      this.$router.push({
        name: "DesignProjectProfile",
        params: {
          Id: data.id,
          projectName: data.projectName,
        },
      });
    },

    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },

    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },
    async fetchData() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/debitNote/`);
        this.TableData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/projectStatus/${this.statusName}/archived`
        );
        this.ArchiveData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },

    // Method to delete the selected lead
    DeleteLead(data) {
      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveLead(data) {
      axios
        .put(`${this.$apiEndPoint}/customer/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(
          `${this.$apiEndPoint}/designProjects/searchStatus/${this.statusName}/archived`,
          searchData
        )

        .then((response) => {
          this.ArchiveData = response.data; // Ensure it's an array or provide default
          console.log(this.ArchiveData);
          // Update the displayedLeads based on currentPage and rowsPerPage
          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.ArchiveData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    //for Serch api for Unclaimleads
    searchLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(`${this.$apiEndPoint}/debitNote/search`, searchData)

        .then((response) => {
          this.TableData = response.data; // Ensure it's an array or provide default
          console.log(this.TableData);
          // Update the displayedLeads based on currentPage and rowsPerPage
          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.TableData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //for all selected lead Unclaim and delete api

    ArchiveLeads() {
      if (this.selectedCustomers.length === 0) {
        return;
      }

      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/customers/archive`, payload)
        .then((response) => {
          console.log("Leads deleted successfully:", response.data);
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    deleteLead(Id, index) {
      // Remove the item at the specified index from TableData
      this.TableData.splice(index, 1);

      // Make an API call to delete the lead from the backend
      axios
        .delete(`${this.$apiEndPoint}/debitNote/${Id}`)
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);

          // Optionally, fetch the data again to update the table
          this.fetchData();
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    changePage(delta) {
      this.currentPage += delta;
    },
    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    createEstimate(selectedLead) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedLead.name,
          PropertyId: selectedLead.propertyId,
          ContactNo: selectedLead.contactNo,
          Email: selectedLead.email,
          leadId: selectedLead.id,
          address: selectedLead.address,
          propertyName: selectedLead["property.name"],
        },
      });
    },

    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    changeStatus(status) {
      this.selectedStatus = status;
      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },
  },
};
</script>

<style scoped>
.FormatTable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100% - 74px);
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
}
.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 30px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

.FilterIconOfTable,
#FilterIconOfTable {
  width: 20px;
  height: 15px;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }
  #addicon {
    width: 100%;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
