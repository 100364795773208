<template>
  <desktop-header />
</template>

<script>
import DesktopHeader from "./DesktopHeader.vue";

export default {
  components: { DesktopHeader }, // Import MobileHeader if needed
  name: "Header",
  data() {
    return {
      initials: "",
    };
  },
  methods: {
    logout() {
      alert("Logout function called");
      sessionStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    let user = sessionStorage.getItem("user-info");

    if (user) {
      try {
        const userData = JSON.parse(user);
        const userObject = userData.user;
        this.initials = userObject.initials;
        this.fullName = userObject.fullname;
        this.userId = userObject.userId;
      } catch (error) {
        this.$handleError(error);
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<!-- <style>

#mobileHeader {
    display: none;
}

@media screen and (max-width: 1096px) {
    #desktopHeader {
        display: flex;
    }

    #mobileHeader {
        display: flex;
    }

}
</style> -->
