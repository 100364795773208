<template>
  <div
    class="modal fade"
    id="eventModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="eventModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h5 class="modal-title" style="margin-left: 40%">
            <img
              class="eventImage"
              src="../../assets/icons/event.png.png"
              alt=""
              style="width: 25px; height: 25px; object-fit: contain; margin-right: 10px"
            />
            Event
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <!-- Event modal content -->
          <div class="mb-3 row">
            <label for="title" class="col-sm-3 col-form-label">Title:</label>
            <div class="col-sm-9">
              <input
                type="text"
                v-model="eventData.title"
                name="title"
                id="title"
                class="form-control"
              />
            </div>
          </div>
          <div class="mb-3 row">
            <label for="date" class="col-sm-3 col-form-label">Date:</label>
            <div class="col-sm-9">
              <input
                type="date"
                v-model="eventData.date"
                name="date"
                id="date"
                class="form-control"
              />
            </div>
          </div>
          <div class="mb-3 row">
            <label for="time" class="col-sm-3 col-form-label">Time:</label>
            <div class="col-sm-9">
              <div class="d-flex align-items-center">
                <input
                  type="time"
                  v-model="eventData.fromTime"
                  name="fromTime"
                  id="fromTime"
                  class="form-control me-2"
                />
                <p
                  style="
                    letter-spacing: 2px;
                    font-weight: lighter;
                    margin: 0 5px;
                    color: black;
                  "
                >
                  To
                </p>
                <input
                  type="time"
                  v-model="eventData.toTime"
                  name="toTime"
                  id="toTime"
                  class="form-control ms-2"
                />
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="description" class="col-sm-3 col-form-label">Description:</label>
            <div class="col-sm-9">
              <textarea
                id="description"
                name="description"
                v-model="eventData.description"
                rows="3"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="meetingLink" class="col-sm-3 col-form-label">Meeting Link:</label>
            <div class="col-sm-9">
              <input
                type="url"
                v-model="eventData.meetingLink"
                name="meetingLink"
                id="meetingLink"
                class="form-control"
              />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="location" class="col-sm-3 col-form-label">Location:</label>
            <div class="col-sm-9">
              <input
                type="text"
                v-model="eventData.location"
                name="location"
                id="location"
                class="form-control"
              />
            </div>
          </div>
          <div class="mb-3 row">
            <label for="addPeople" class="col-sm-3 col-form-label">Add People:</label>
            <div class="col-md-9">
              <div
                v-if="selectedAssignees.length > 0"
                style="
                  font-size: 12px;
                  cursor: pointer;
                  margin: 5px;
                  padding: 5px;
                  border-radius: 5px;

                  border: 2px solid #000;
                  word-break: break-all;
                  white-space: pre-wrap;
                "
              >
                <div
                  v-for="(employeeId, index) in selectedAssignees"
                  :key="index"
                  class="selected-employee ms-2"
                >
                  {{ getEmployeeFullName(employeeId) }}
                  <span @click="removeEmployee(index)" class="remove-employee"
                    >&#10005;</span
                  >
                </div>
              </div>
              <div>
                <select
                  v-if="searchText.length === 0"
                  v-model="selectedEmployee"
                  class="form-control inputbg"
                  id="inputAssignee"
                  @change="addEmployee"
                >
                  <option disabled value="">Select an Employee</option>
                  <option
                    v-for="employee in filteredEmployees"
                    :key="employee.employeeUserId"
                    :value="employee.employeeUserId"
                    class="EmployeeList"
                  >
                    {{ getEmployeeFullName(employee.employeeUserId) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer d-flex justify-content-center" v-if="!this.eventData.id">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="postEvent"
            :disabled="!eventData.title || !eventData.date"
            data-bs-dismiss="modal"
          >
            Done
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="resetForm"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
        <div class="modal-footer d-flex justify-content-center" v-else>
          <button
            type="button"
            class="btn btn-sm btn-success"
            @click="postEvent"
            data-bs-dismiss="modal"
          >
            Update
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="deleteEvent"
            data-bs-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "CreateEventModal",
  props: {
    selectedEventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      eventData: {
        id: null,
        title: "",
        date: null,
        fromTime: null,
        toTime: null,
        addPeople: "",
        description: "",
        meetingLink: "",
        location: "",
        participants: [],
      },
      searchText: "",
      selectedEmployee: "",
      selectedAssignees: [],
      employees: [], // Array to hold filtered people suggestions
      eventModal: false, // Assuming this is used to control the modal visibility
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter((employee) => {
        const fullName = `${employee.firstName} ${employee.lastName}`;
        return fullName.toLowerCase().includes(this.searchText.toLowerCase());
      });
    },
  },
  mounted() {
    this.fetchEmployees();
  },
  watch: {
    selectedEventId(newValue) {
      if (newValue !== null) {
        this.eventData.id = newValue;
        this.fetchEventDataById();
      } else {
        this.fetchEmployees();
        this.eventData.id = null;
        this.resetForm();
        this.selectedAssignees = [];
      }
    },
  },
  methods: {
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/allEmployees`);
        const currentUserId = this.$LocalUser.user.id;

        // If selectedEventId is not null, meaning editing an existing event
        if (this.selectedEventId !== null) {
          // Get the list of participants for the event
          const participantIds = this.eventData.participants.map(
            (participant) => participant.userId
          );

          // Set all employees data
          this.employees = response.data;

          // Filter employees to exclude participants and the current user
          this.filteredEmployees = this.employees.filter(
            (employee) => !participantIds.includes(employee.employeeUserId)
          );

          // Populate selectedAssignees with participant userIds
          this.selectedAssignees = [...participantIds];
        } else {
          // If creating a new event, exclude the current user only
          this.employees = response.data.filter(
            (employee) => employee.employeeUserId !== currentUserId
          );
          this.filteredEmployees = [...this.employees];
        }

        console.log(this.filteredEmployees); // Log the filtered employees
      } catch (error) {
        this.$handleError(error);
      }
    },

    searchEmployees() {
      // Filter employees based on searchText
      // This function can be left empty if the filtering is done client-side
    },
    removeEmployee(index) {
      const removedEmployeeId = this.selectedAssignees.splice(index, 1)[0]; // Remove the employee ID from selectedAssignees
      const employee = this.employees.find(
        (emp) => emp.employeeUserId === removedEmployeeId
      );
      if (employee) {
        this.filteredEmployees.push(employee); // Push the removed employee back to filteredEmployees
        this.filteredEmployees.sort((a, b) => a.employeeUserId - b.employeeUserId); // Sort the list to maintain consistency
      }
    },
    getEmployeeFullName(employeeId) {
      const employee = this.employees.find((emp) => emp.employeeUserId === employeeId);
      return employee ? `${employee.firstName} ${employee.lastName}` : "";
    },
    addEmployee() {
      if (this.selectedEmployee) {
        const employeeIndex = this.filteredEmployees.findIndex(
          (emp) => emp.employeeUserId === this.selectedEmployee
        );
        if (employeeIndex !== -1) {
          this.filteredEmployees.splice(employeeIndex, 1); // Remove the selected employee from filteredEmployees
        }
        this.selectedAssignees.push(this.selectedEmployee);
        this.selectedEmployee = ""; // Clear the selectedEmployee after adding
      }
    },
    async fetchEventDataById() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/events/${this.selectedEventId}`
        );
        this.eventData = response.data;
        this.selectedAssignees = this.eventData.participants.map(
          (participant) => participant.userId
        );
        console.log(this.eventData);
        await this.fetchEmployees();
      } catch (error) {
        this.$handleError(error);
      }
    },
    deleteEvent() {
      if (this.eventData.id) {
        axios
          .delete(`${this.$apiEndPoint}/events/${this.selectedEventId}`)
          .then((response) => {
            console.log("Event deleted successfully:", response.data);
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    // Method to post an event
    postEvent() {
      this.eventData.participants = this.selectedAssignees;
      console.log("Posting Event:", this.eventData);
      if (!this.eventData.id) {
        this.selectedAssignees.push(this.$LocalUser.user.id);

        axios
          .post(`${this.$apiEndPoint}/events`, this.eventData)
          .then((response) => {
            console.log("Event added successfully:", response.data);
            this.$emit("fetchData");
            this.eventModal = false; // Close the modal after successful submission

            this.name = " ";
            this.selectedAssignees = [];
            this.resetForm(); // Reset the form data
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        axios
          .put(`${this.$apiEndPoint}/events/${this.eventData.id}`, this.eventData)
          .then((response) => {
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
            console.log("Event added successfully:", response.data);
            this.taskModal = false; // Close the modal after successful submission
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    // Method to reset the form
    resetForm() {
      this.eventData = {
        id: null,
        title: "",
        date: null,
        fromTime: null,
        toTime: null,
        description: "",
        meetingLink: "",
        location: "",
        participants: [],
      };
      this.filteredPeople = []; // Clear any filtered people suggestions
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
input,
textarea,
select,
label {
  font-size: 14px;
}

/* Add your scoped styles here */
.selected-employee {
  margin-bottom: 5px;
}

.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
</style>
