<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request">
          <div class="row header mb-2">
            <div class="d-flex justify-content-between">
              <h5 class="ms-3 text-decoration-underline">Debit Note</h5>
              <div class="close">
                <img
                  src="../../assets/icons/cancel.png"
                  alt=""
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                />
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <div class="col-md-6 mb-3 d-flex align-items-center">
              <label for="projectName" class="col-md-4 col-sm-4 col-form-label"
                >Project Name:</label
              >
              <div class="col-md-8 col-sm-8">
                <select
                  class="form-control border-black form-select"
                  id="category"
                  v-model="form.projectId"
                  @click="fetchGrByProjectId(form.projectId)"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.projectId"
                    :value="Project.projectId"
                  >
                    {{ Project.projectName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center">
              <label for="priority" class="col-md-4 col-sm-4 col-form-label">GRN:</label>
              <div class="col-md-8 col-sm-8">
                <select
                  class="form-control border-black form-select"
                  id="category"
                  v-model="form.grnId"
                  @change="featchByGrnId(form.grnId)"
                >
                  <option v-for="Grn in GrnList" :key="Grn.id" :value="Grn.id">
                    {{ Grn.grnNumber }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <div class="col-md-6 mb-3 d-flex align-items-center">
              <label for="priority" class="col-md-4 col-sm-4 col-form-label"
                >Reference:</label
              >
              <div class="col-md-8 col-sm-8">
                <input
                  type="text"
                  class="form-control border-black border-1"
                  v-model="form.documentNo"
                  readOnly
                />
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center">
              <label for="date" class="col-md-4 col-sm-4 col-form-label"
                >Vendor Name:</label
              >
              <div class="col-md-8 col-sm-8">
                <input
                  type="text"
                  class="form-control border-black border-1"
                  v-model="form.vendorName"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <!-- Vendor Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
                @click="toggleBillingDropdown"
              >
                <label for="vendorAddress" class="form-label mb-0"
                  >Return/ Debit To:</label
                >
              </div>

              <div class="card mt-2" style="background-color: #f8f9fa">
                <div class="card-body">
                  <div
                    class="form-control border-black"
                    id="vendorAddress"
                    @click="fetchGrByProjectId(form.projectId)"
                    style="height: 130px"
                  >
                    {{ billingVendorAddressData.name }}<br />
                    {{ billingVendorAddressData.address
                    }}<span v-if="billingVendorAddressData.city">,</span>
                    {{ billingVendorAddressData.city }}<br />
                    {{ billingVendorAddressData.state
                    }}<span v-if="billingVendorAddressData.pincode">-</span
                    >{{ billingVendorAddressData.pincode }}<br />
                    {{ billingVendorAddressData.contactNo }}<br />
                    {{ billingVendorAddressData.email }}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container small-wrapper">
            <table class="small-table table table-bordered">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr class="table-header-heading">
                  <!-- Table headers -->
                  <th style="width: 80px">SL/No</th>
                  <th style="width: 200px">Product Name(Description)</th>
                  <!-- <th style="width: 300px">Product Description</th> -->
                  <th style="width: 100px">HSN Code</th>
                  <!-- <th style="width: 100px">Article No.</th>
                  <th style="width: 150px">Category</th> -->
                  <th style="width: 100px">Unit Type</th>
                  <th style="width: 100px">Quantity</th>
                  <th style="width: 100px">Discount(%)</th>
                  <th style="width: 100px">Unit Price</th>
                  <th style="width: 150px">Amount</th>
                  <th style="width: 100px">Gst</th>
                  <th style="width: 150px">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in Products" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>
                    <!-- <input
                      class="w-100 border-0"
                      v-model="product['productLists.productName']"
                      readonly
                    /> -->
                    {{ product["productLists.productName"] }}
                    <span
                      :title="product['productLists.description']"
                      v-if="product['productLists.description']"
                      >({{ product["productLists.description"] }})</span
                    >
                  </td>
                  <!-- <td class="pt-2">{{ product["productLists.description"] }}</td> -->
                  <td class="pt-2">{{ product["productLists.HSNCode"] }}</td>
                  <!-- <td class="pt-2">{{ product["productLists.articleNo"] }}</td>
                  <td class="pt-2">{{ product["productLists.category"] }}</td> -->
                  <td class="pt-2">{{ product["productLists.unitType"] }}</td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model="product.orderQuantity"
                      @change="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product['productLists.discount']"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model="product['productLists.unitPrice']"
                      @input="calculateAmounts(product)"
                    />
                  </td>

                  <td class="text-end pt-2">
                    {{ $formatToIndianCurrency(product.amount) }}
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model="product['productLists.GST']"
                      @change="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end pt-2">
                    {{ $formatToIndianCurrency(product.totalAmount) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between">
              <div>
                Reason for Debit Note :
                <textarea
                  id="description"
                  name="description"
                  v-model="form.reason"
                  rows="3"
                  cols="10"
                  style="width: 400px"
                  class="form-control mt-1"
                ></textarea>
              </div>
              <div>
                <button style="visibility: hidden"></button>

                <div class="d-flex justify-content-end mt-3">
                  <div
                    class="card"
                    style="
                    position: fixed;
      bottom: 60px;
      right: 20px;
      width: 300px;
      background-color: #f8f8f8;
      z-index: 1000;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      font-size: 14px;
      max-height: 400px; /* Set a max-height for the summary card */
      overflow-y: auto; /* Make the summary scrollable if it overflo
                    "
                  >
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <strong>Amount:</strong>
                        <span>{{ $formatToIndianCurrency(calculateTotalAmount()) }}</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <strong>CGST:</strong>
                        <span>{{ $formatToIndianCurrency(calculateCgst()) }}</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <strong>SGST:</strong>
                        <span>{{ $formatToIndianCurrency(calculateSgst()) }}</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <strong>Total Amount:</strong>
                        <span>{{ $formatToIndianCurrency(calculateGrandTotal()) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footerPuchaseRequest">
          <button
            class="btn btn-sm btn-primary"
            :disabled="!form.projectId"
            @click="saveAsDraft"
          >
            Save
          </button>
          <button class="save-button btn btn-sm btn-secondary" @click="goBack">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  name: "CreateDebitNote",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      selectedTargetId: null,
      Addresses: [],
      selectedBillingAddress: null,
      selectedShippingAddress: null,
      showBillingDropdown: true,
      showShippingDropdown: true,
      billingVendorAddressData: {},
      Vendors: [],
      GrnList: [],
      GrnObject: {},
      Projects: [],
      ProductList: [],
      Products: [],
      searchQuery: "",
      selectedProduct: null,
      form: {
        vendorId: null,
        projectId: null,
        poId: null,
        vendorAddressId: null,
        documentNo: null,

        reason: null,
      },
    };
  },
  mounted() {
    this.fetchProjects();
    this.fetchVendors();
    this.fetchProductsOfPO();
  },
  computed: {
    filteredProducts() {
      if (this.searchQuery.trim() === "") {
        return [];
      }
      return this.ProductList.filter((product) =>
        product.productName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  watch: {
    Products: {
      handler: function (newVal) {
        // Loop through products and calculate amounts
        newVal.forEach((product) => {
          this.calculateAmounts(product);
        });
      },
      deep: true, // Ensures it watches nested properties
    },
  },
  methods: {
    saveAsDraft() {
      console.log(this.Products);
      // Prepare the line items data
      const lineItems = this.Products.map((product) => ({
        productId: product.productId,
        quantity: product.orderQuantity,
        amount: product.amount,
        missingQuantity: product.missingQuantity,
        damagedQuantity: product.damagedQuantity,
        discount: product["productLists.discount"],
        gst: product["productLists.GST"], // GST amount for the product
        unitPrice: product["productLists.unitPrice"],
        totalAmount: product.totalAmount,
      }));

      // Prepare the payload for the request
      const payload = {
        grnId: this.form.grnId,
        projectId: this.form.projectId,
        poId: this.form.poId, // Example static value, change as necessary
        documentNo: this.form.documentNo,
        vendorAddressId: this.form.vendorAddressId,
        vendorId: this.form.vendorId,
        reason: this.form.reason,
        amount: this.calculateTotalAmount(), // Total amount without GST
        cgst: this.calculateCgst(), // CGST value
        sgst: this.calculateSgst(), // SGST value
        totalAmount: this.calculateGrandTotal(), // Total amount including GST
        products: lineItems,
      };

      console.log(payload);
      // Send the data to the backend using Axios
      axios
        .post(`${this.$apiEndPoint}/debitNote/`, payload)
        .then((response) => {
          this.$toast("Debit Note created successfully.", "success");
          this.goBack();
          // Handle the response from the backend
          console.log("Save as Draft successful:", response.data);
          // Optionally, navigate back or show a success message
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    // Calculate the total amount (without GST)
    calculateTotalAmount() {
      return this.Products.reduce(
        (sum, product) =>
          sum +
          product["productLists.unitPrice"] * product.orderQuantity -
          product["productLists.discount"],
        0
      );
    },

    // // Calculate the CGST amount (assuming GST is split equally between CGST and SGST)
    // calculateCgst() {
    //   return this.Products.reduce((sum, product) => sum + product.GST / 2, 0);
    // },

    // // Calculate the SGST amount (assuming GST is split equally between CGST and SGST)
    // calculateSgst() {
    //   return this.Products.reduce((sum, product) => sum + product.GST / 2, 0);
    // },

    calculateCgst() {
      if (!this.Products || this.Products.length === 0) return 0;
      return this.Products.reduce((sum, product) => {
        const cgstAmount = (product.amount * (product["productLists.GST"] / 2)) / 100;
        return sum + cgstAmount;
      }, 0);
    },

    calculateSgst() {
      if (!this.Products || this.Products.length === 0) return 0;
      return this.Products.reduce((sum, product) => {
        const sgstAmount = (product.amount * (product["productLists.GST"] / 2)) / 100;
        return sum + sgstAmount;
      }, 0);
    },

    // Calculate the grand total amount (total amount + total GST)
    calculateGrandTotal() {
      const totalAmount = this.calculateTotalAmount();
      const totalGst = this.calculateCgst() + this.calculateSgst();
      return totalAmount + totalGst;
    },

    // Other existing methods...

    openModal(TargetId) {
      this.selectedTargetId = TargetId;
    },
    handleInput(index) {
      this.Products[index].filteredProducts = this.getFilteredProducts(
        this.Products[index].searchQuery
      );
    },
    getFilteredProducts(query) {
      if (query.trim() === "") {
        return [];
      }
      return this.ProductList.filter((product) =>
        product.productName.toLowerCase().includes(query.toLowerCase())
      );
    },
    selectProduct(item, index) {
      this.Products[index] = {
        productId: item.id,
        productName: item.productName,
        searchQuery: item.productName,
        description: item.description,
        HSNCode: item.HSNCode,
        articleNo: item.articleNo,
        category: item.category,
        unitType: item.unitType,
        quantity: 0, // Default value or as needed
        discount: 0, // Default value or as needed
        unitPrice: item.unitPrice,
        amount: 0, // Default value or as needed
        GST: 0, // Default value or as needed
        totalAmount: 0, // Default value or as needed
        expectedDate: "",
        remark: "",

        filteredProducts: [], // Clear filtered products for the row
      };

      // Remove the selected product from the ProductList
      // this.ProductList = this.ProductList.filter(
      //   (product) => product.productName !== item.productName
      // );
    },

    isProductInList(item) {
      return (
        item && this.Products.some((product) => product.productName === item.productName)
      );
    },
    async fetchVendors() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/vendor/`);
        this.Vendors = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchAddressForPO() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/addressForPo/Billing/23`
        );
        this.Addresses = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async changeVendorAddress(vendorId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Vendor/${vendorId}`
        );
        this.billingVendorAddressData = response.data[0];
        this.form.vendorAddressId = response.data[0].id;
        this.fetchAddressForPO();
        this.fetchProductsOfPO();
      } catch (error) {
        this.$handleError(error);
      }
    },
    calculateAmounts(product) {
      const gstRate = parseFloat(product["productLists.GST"]) || 0;
      const quantity = parseFloat(product.orderQuantity) || 0;
      const unitPrice = parseFloat(product["productLists.unitPrice"]) || 0;
      const discount = parseFloat(product["productLists.discount"]) || 0;

      const initialAmount = quantity * unitPrice;
      const discountAmount = initialAmount * (discount / 100);
      product.amount = initialAmount - discountAmount;
      const gstAmount = product.amount * (gstRate / 100);
      product.totalAmount = product.amount + gstAmount;
    },
    addNewTask() {
      // Check if the Products array is empty
      if (this.Products.length === 0) {
        this.Products.push({
          productName: "",
          description: "",
          HSNCode: "",
          articleNo: "",
          category: "",
          unitType: "",
          quantity: 0,
          discount: 0,
          unitPrice: 0,
          amount: 0,
          GST: 0,
          totalAmount: 0,
          expectedDate: "",
          remark: "",
          searchQuery: "",
          filteredProducts: [],
          productId: "", // Assuming you have a productId field
        });
      } else {
        const lastProduct = this.Products[this.Products.length - 1];

        // Check if the last product is filled out (using optional chaining)
        if (lastProduct?.productName && lastProduct?.productId) {
          this.Products.push({
            productName: "",
            description: "",
            HSNCode: "",
            articleNo: "",
            category: "",
            unitType: "",
            quantity: 0,
            discount: 0,
            unitPrice: 0,
            amount: 0,
            GST: 0,
            totalAmount: 0,
            expectedDate: "",
            remark: "",
            searchQuery: "",
            filteredProducts: [],
            productId: "", // Assuming you have a productId field
          });
        } else {
          // Alert the error message
          alert("Please fill out the current product details before adding a new one.");

          // Focus on the first row's productName or searchQuery input field
        }
      }
    },

    async fetchProductsOfPO() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/productList`);
        this.ProductList = response.data;
        console.log(this.ProductList);
      } catch (error) {
        this.$handleError(error);
      }
    },

    toggleBillingDropdown() {
      this.showBillingDropdown = !this.showBillingDropdown;
      this.selectedBillingAddress = null;
    },
    toggleShippingDropdown() {
      this.showShippingDropdown = !this.showShippingDropdown;
      this.selectedShippingAddress = null;
    },
    updateBillingAddress(selectedBillingAddress) {
      this.form.shippingAddressId = selectedBillingAddress.id;
      this.showBillingDropdown = false;
    },
    updateShippingAddress(selectedShippingAddress) {
      this.form.billingAddressId = selectedShippingAddress.id;
      this.showShippingDropdown = false;
    },
    async fetchGrByProjectId(projectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteGrn/getGrnByProjectId/${projectId}`
        );
        this.GrnList = response.data;
        console.log(this.GrnList);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async featchByGrnId(Id) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteGrn/getById/${Id}`);
        this.GrnObject = response.data;
        this.form.grnId = this.GrnObject.id;
        this.form.projectId = this.GrnObject.projectId;
        this.form.poId = this.GrnObject.poId;
        this.form.documentNo = this.GrnObject.documentNo;
        this.form.vendorId = this.GrnObject["purchaseOrders.vendors.id"];
        this.form.vendorAddressId = this.GrnObject["purchaseOrders.vendorsAddress.id"];
        this.form.vendorName = this.GrnObject["purchaseOrders.vendors.companyName"];
        this.Products = this.GrnObject.lineItems;
        this.billingVendorAddressData.name = this.GrnObject[
          "purchaseOrders.vendorsAddress.name"
        ];
        this.billingVendorAddressData.address = this.GrnObject[
          "purchaseOrders.vendorsAddress.address"
        ];
        this.billingVendorAddressData.city = this.GrnObject[
          "purchaseOrders.vendorsAddress.city"
        ];
        this.billingVendorAddressData.state = this.GrnObject[
          "purchaseOrders.vendorsAddress.state"
        ];
        this.billingVendorAddressData.pincode = this.GrnObject[
          "purchaseOrders.vendorsAddress.pincode"
        ];
        this.billingVendorAddressData.contactNo = this.GrnObject[
          "purchaseOrders.vendorsAddress.contactNo"
        ];
        this.billingVendorAddressData.email = this.GrnObject[
          "purchaseOrders.vendorsAddress.email"
        ];

        console.log(this.GrnObject);
      } catch (error) {
        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
    removeRow(index) {
      this.rows.splice(index, 1);
    },
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/purchaseRequest/getProjectToCreatePr/Procurement`)
        .then((response) => {
          this.Projects = response.data;
          console.log(this.Projects);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
.card-body select {
  height: 130px;
}

.AddressCard div {
  height: 130px;
  overflow-y: auto;
}

.AddressCard div::-webkit-scrollbar {
  display: none;
}

.purchase-request {
  padding: 10px 10px;
  height: 83vh;
  width: 100%;
}

.purchase-request .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  width: 100%;
  margin-top: 20px;
  padding: 0px 20px;
  overflow-x: scroll;
  position: relative;
}

.small-wrapper {
  width: 100%;

  height: 89vh;
  /* Set a fixed height to make the table vertically scrollable */
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 100px;
  padding-bottom: 200px;
}

.small-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  border-collapse: collapse;
  /* Ensure the table layout is fixed and borders collapse */
}

.table-header-heading th {
  background-color: #ccc;
  text-align: center;
  position: sticky;
  top: 0;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
  /* Ensure the header stays on top */
}

.add-item {
  margin-top: 10px;
}

tbody td {
  padding: 2px 5px;
  margin: 0;
  width: 200px;
  max-width: fit-content;
  font-size: 14px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Ensures the overflowed text is hidden */
  text-overflow: ellipsis; /* Adds the ellipsis when text overflows */
}

.table-header-heading td:nth-child(1) {
  text-align: center;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest {
  margin-top: 20px;
  position: fixed;
  border-radius: 0 0 15px 15px;
  bottom: 12px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 74px);
}

.footerPuchaseRequest .btn {
  margin: 5px;
}

.input-bg {
  background-color: #fff;
}

.description {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-input {
  width: 100%;
  text-align: end;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

#date {
  border: 1px solid #000000;
}

.dropdown-menu {
  height: fit-content;
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 5px;
}
.form-label {
  color: white !important;
}
@media (max-width: 768px) {
  .purchase-request {
    padding: 5px 10px;
    height: 50%;
    width: 100%;
  }

  .d-flex.align-items-center {
    display: block !important;
  }

  .d-flex.align-items-center label,
  .d-flex.align-items-center .form-control {
    width: 100% !important;
  }

  .small-table {
    display: block;
    overflow-x: auto;
  }

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  label {
    flex: 0.5;
    white-space: nowrap;
  }

  input {
    flex: 0.5;
  }
}
</style>
