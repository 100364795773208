<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="EstimateRegister">
        <div class="container-fluid p-2">
          <div class="headerOfEstimate">
            <div class="row p-1">
              <div class="col-md-6">
                <div class="row">
                  <h5 class="mainHeading">New Estimate</h5>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-2 EstimateDate text-nowrap">
                <div class="row">
                  <div class="col-3">Date:</div>
                  <div class="col-8">
                    {{ formattedDate }}
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="EstimateFormContainer">
              <div class="EstimateForm">
                <div class="row p-1">
                  <!-- Left Side -->
                  <div class="col-md-6">
                    <div class="row p-1">
                      <div class="col-md-3">
                        <label for="clientName">Name :</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          name="clientName"
                          id="clientName"
                          placeholder="Search and Select Client"
                          v-model="routeParams.clientName"
                          @input="searchAndFillLeadDetails"
                        />
                      </div>
                      <div
                        v-if="searchResults.length && routeParams.clientName"
                        class="serchMyLeads"
                      >
                        <ul>
                          <li
                            v-for="result in searchResults"
                            :key="result.id"
                            @click="selectClient(result)"
                          >
                            {{ result.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col-md-3">
                        <label for="contactNumber">Contact No :</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          id=""
                          v-model="routeParams.contactNo"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col-md-3">
                        <label for="emailId">Email ID:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="routeParams.email"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Right Side -->
                  <div class="col-md-6">
                    <div class="row p-1">
                      <div class="col-md-3">
                        <label for="estimateNumber">Estimate Number:</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="routeParams.estimateId"
                          PlaceHolder=" Auto Generated"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col-md-3">
                        <label for="leadId">Property Address :</label>
                      </div>
                      <div class="col-md-8">
                        <textarea
                          type="text"
                          class="form-control"
                          v-model="routeParams.address"
                          rows="3"
                          readonly
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="EstimateBill mb-3">
            <table class="tableOfEstimate">
              <caption class="d-none">
                Table
              </caption>
              <thead class="subHeaderHeadingBgColor">
                <tr>
                  <th>SL.No</th>
                  <th>Description</th>
                  <th @click="toggleWidth">
                    Width (
                    <span v-if="routeParams.widthUnitType" style="font-size: 10px">
                      {{ routeParams.widthUnitType }}
                    </span>
                    <span v-else style="font-size: 10px"> select </span>
                    )
                    <span
                      v-if="!routeParams.widthUnitType"
                      class="position-absolute"
                      style="padding-right: 28px"
                    >
                      <select
                        v-model="routeParams.widthUnitType"
                        class="form-label"
                        style="
                          border: none;
                          background-color: transparent;
                          padding: 5px;
                          cursor: pointer;
                        "
                      >
                        <option
                          v-for="(unit, index) in unitTypes"
                          :key="index"
                          :value="unit"
                          style="margin-bottom: 30px"
                        >
                          {{ unit }}
                        </option>
                      </select>
                    </span>
                  </th>
                  <th @click="toggleHeight">
                    Height (
                    <span v-if="routeParams.widthUnitType" style="font-size: 10px">
                      {{ routeParams.widthUnitType }}
                    </span>
                    <span v-else style="font-size: 10px"> select </span>
                    )
                    <span
                      v-if="!routeParams.widthUnitType"
                      class="position-absolute"
                      style="padding-right: 28px; padding-bottom: 10px"
                    >
                      <select
                        v-model="routeParams.widthUnitType"
                        style="
                          border: none;
                          background-color: transparent;
                          padding: 5px;
                          cursor: pointer;
                        "
                      >
                        <option
                          v-for="(unit, index) in unitTypes"
                          :key="index"
                          :value="unit"
                          style="margin-bottom: 30px"
                        >
                          {{ unit }}
                        </option>
                      </select>
                    </span>
                  </th>
                  <th>Qty</th>
                  <th>unit</th>
                  <th>Rate(₹)</th>
                  <th>Discount(%)</th>
                  <th>GST(%)</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <div v-for="(item, index) in items" :key="index" class="itemBlock">
                <span class="ItemHeaderOfThings">
                  <input
                    type="text"
                    v-model="item.header"
                    placeholder="Header Name"
                    id="HeaderOfBillItem"
                    :ref="`header-${index}`"
                  />
                  <p>₹ {{ calculateTotalAmountofItem(item) }}</p>
                </span>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in item.rows"
                    :key="rowIndex"
                    id="itemRowEstimate"
                  >
                    <td class="d-flex align-item-end justify-content-around">
                      <span
                        id="btnGroupDrop1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="../../../assets/icons/threeDot.jpg"
                          alt=""
                          class="dropdown-toggle"
                          aria-expanded="false"
                          style="
                            width: 12px;
                            height: 12px;
                            margin-bottom: 4px;
                            cursor: pointer;
                          "
                        />
                      </span>
                      <div
                        class="dropdown-menu m-0 p-1 text-black p-2"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <p
                          @click="deleteLineItems(row.id, rowIndex, index)"
                          style="font-size: 12px"
                        >
                          Delete
                        </p>
                      </div>
                      <span>{{ rowIndex + 1 }}</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        v-model="row.description"
                        placeholder="description"
                        class="text-left position-relative"
                        @input="searchAndFillMaterialDetails(index, rowIndex)"
                        id="descriptionOfrow"
                        :ref="`row-${index}-${rowIndex}`"
                      />
                      <div
                        v-if="row.searchMaterials"
                        class="mt-1"
                        style="position: absolute; z-index: 100; left: 4.9%; width: 280px"
                      >
                        <ul
                          v-if="row.searchMaterials.length > 1"
                          style="
                            background-color: #ffffff;

                            text-align: left;
                            overflow-y: auto;
                            max-height: 120px;
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                          "
                        >
                          <li
                            v-for="result in row.searchMaterials"
                            :key="result.id"
                            style="margin: 5px 0; cursor: pointer"
                            class="text-left"
                            @click="selectMaterial(result, index, rowIndex)"
                          >
                            {{ result.materialName }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.width"
                        @input="calculateQuantity(row)"
                        placeholder="width"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.height"
                        @input="calculateQuantity(row)"
                        placeholder="height"
                      />
                    </td>
                    <td v-if="isQuantityReadOnly(row)">
                      <input
                        type="number"
                        v-model="row.quantity"
                        placeholder="quantity"
                        readonly
                      />
                    </td>
                    <td v-else>
                      <input
                        type="number"
                        v-model="row.quantity"
                        placeholder="quantity"
                        :ref="'quantity-' + index + '-' + rowIndex"
                      />
                    </td>

                    <td class="text-right">
                      <select
                        v-model="row.unitType"
                        style="border: none; background-color: transparent"
                        class="text-right"
                        :ref="'unitType-' + index + '-' + rowIndex"
                      >
                        <option
                          v-for="(unit, index) in filteredUnitTypes(row)"
                          :key="index"
                          :value="unit"
                        >
                          {{ unit }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.rate"
                        placeholder="rate"
                        :ref="'rate-' + index + '-' + rowIndex"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.discount"
                        placeholder="discount"
                      />
                    </td>
                    <td class="text-center">
                      <select v-model="row.GSTpercentage">
                        <option
                          v-for="option in GSTOptions"
                          :key="option"
                          :value="option"
                        >
                          {{ option }}%
                        </option>
                      </select>
                    </td>
                    <td>
                      <input type="number" v-model="row.amount" placeholder="" readonly />
                    </td>
                  </tr>
                </tbody>
                <button id="itemrowAdding" @click="addRow(index)">Add Row.....</button>
              </div>
            </table>
            <button @click="addItem" class="btn btn-sm btn-outline-dark btn-block">
              ADD ITEM +
            </button>
          </div>

          <div class="container-fluid" style="margin-bottom: 25px">
            <div class="row col-md-12">
              <div class="col-md-6 mt-2" style="padding-top: 3px">
                <h4 class="bodyText d-flex">Customer Note</h4>
                <section class="position-relative">
                  <textarea
                    class="form-control position-relative bodyText border-black text-start"
                    rows="7"
                    placeholder="Write here......"
                    v-model="routeParams.customerNote"
                  >
                  </textarea>
                </section>
              </div>

              <div class="col-md-6">
                <h4 style="visibility: hidden">C</h4>
                <div
                  class="row p-3 text-nowrap position-relative justify-content-start border-black"
                  style="border: 1px solid #000000; border-radius: 5px; font-weight: bold"
                >
                  <div class="row">
                    <div class="col-6">
                      <h6 style="font-weight: bold">
                        Total Amount
                        <span style="font-size: 14px">(Excluding GST)</span>
                      </h6>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <p class="text-right">
                        {{
                          $formatToIndianCurrency(
                            calculateFooterTotals().totalExcludingGST
                          )
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-for="(detail, gstPercentage) in calculateFooterTotals().gstDetails"
                    :key="gstPercentage"
                  >
                    <div class="row" v-if="gstPercentage > 0">
                      <div class="col-12"></div>
                      <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-end">
                          <span>CGST ({{ gstPercentage / 2 }}%):</span>
                          <span>SGST ({{ gstPercentage / 2 }}%):</span>
                          <!-- CGST amounts aligned at the start -->
                        </div>
                        <div
                          class="col-6"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: end;
                            text-align: right;
                          "
                        >
                          <span class="text-right">{{
                            $formatToIndianCurrency((detail / 2).toFixed(2))
                          }}</span>
                          <span class="text-right">{{
                            $formatToIndianCurrency((detail / 2).toFixed(2))
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="font-weight: bold">
                    <div class="col-6">
                      <h6 style="font-weight: bold">
                        Grand Total
                        <span style="font-size: 14px"> (Including GST)</span>
                      </h6>
                    </div>
                    <div
                      class="col-6 d-flex justify-content-end"
                      style="border-top: 2px solid #000"
                    >
                      <p class="text-right">
                        {{ $formatToIndianCurrency(calculateGrandTotal()) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footerOfEstimate mt-2">
            <div
              class="d-flex justify-content-center position-fixed"
              style="
                background-color: #ffffff !important;
                bottom: 12px;
                right: 20px;
                width: 94%;
                border-radius: 10px;
              "
            >
              <div style="width: 30%"></div>
              <div class="d-flex justify-content-around" style="bottom: 15px; width: 40%">
                <button
                  class="saveButton confirmationButton"
                  :class="{ 'confirmationButton--loading': isLoadingDraft }"
                  @click="DraftEstimate"
                >
                  <span class="confirmationButton__text">Save as Draft</span>
                </button>
                <button
                  class="saveButton confirmationButton"
                  :class="{ 'confirmationButton--loading': isLoadingFinalize }"
                  @click="FinalEstimate"
                >
                  <span class="confirmationButton__text">Finalize</span>
                </button>
                <button class="cancelButton me-1" @click="goBack">Close</button>
              </div>

              <div style="width: 30%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import axios from "axios";

export default {
  name: "CreateEstimate",
  components: {
    Header,
    Nav,
  },
  props: {
    leadIds: Array, // Define the expected type of passed data
  },
  data() {
    return {
      isItemsInitialized: false,
      LineItemId: false,
      makeFinalize: false,
      autoSaveInterval: null,
      Material: [],
      isLoadingDraft: false,
      isLoadingFinalize: false,
      gstDetails: [],
      GSTOptions: [0, 5, 12, 18, 28],
      QuantityunitTypes: [
        "sq.ft",
        "No's",
        "pcs",
        "box",
        "Pr",
        "Set",
        "unit",
        "Sheet",
        "Kg",
        "gm",
      ],
      SalesPersons: [],
      selectedSalesPerson: "",
      selectedProperty: "",
      showDropdown1: false,
      showDropdown: false,
      searchMaterials: [],
      searchResults: [],
      toggleWidththings: false,
      toggleHeightthings: false,
      estimateId: "",
      fetchedEstimateData: {},
      routeParams: {
        estimateId: this.$route.params.EstimateId,
        estimateIndex: this.$route.params.estimateIndex || "",
        clientName: this.$route.params.Clientname || "",
        propertyName: this.$route.params.propertyName || "",
        contactNo: this.$route.params.ContactNo || "",
        salesPerson: this.$route.params.salesPersonId || "",
        email: this.$route.params.Email || "",
        date: this.$route.params.date || "",
        address: this.$route.params.address || "",
        customerNote: "",
        leadId: this.$route.params.leadId || "",
        propertyId: this.$route.params.PropertyId,
        widthUnitType: "",
        heightUnitType: "",
        unitType: "",
      },
      unitTypes: ["mm", "cm", "meter", "inch", "feet"],
      items: [],
    };
  },
  computed: {
    formattedDate() {
      // Check if routeParams.date is present
      if (this.routeParams.date !== "") {
        return this.routeParams.date;
      } else {
        // If routeParams.date is not present, return the current date in the format "dd/MM/YYYY"
        const currentDate = new Date();
        return currentDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      }
    },

    filteredSalesPersons() {
      const searchTerm = this.selectedSalesPerson.toLowerCase();
      return this.SalesPersons.filter((salesPerson) => {
        const fullName = `${salesPerson["employees.firstName"]} ${salesPerson["employees.lastName"]}`.toLowerCase();
        return fullName.includes(searchTerm);
      });
    },
  },
  mounted() {
    this.routeParams.estimateId = this.$route.params.EstimateId;
    this.routeParams.estimateIndex = this.$route.params.estimateIndex;

    if (this.routeParams.estimateId || this.routeParams.estimateIndex) {
      this.fetchEstimateData();
    }

    this.featchMaterial();
    this.autoSaveInterval = setInterval(() => {
      this.LineItemId = true;
      this.DraftEstimate();
    }, 20000); // 10000 ms = 10 seconds
  },
  beforeUnmount() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
  },

  methods: {
    filterUnitTypes(row) {
      if (row.unitTypeSearch.trim() === "") {
        row.showUnitTypeSearch = false;
      } else {
        row.showUnitTypeSearch = true;
      }
    },
    filteredUnitTypes(row) {
      if (!row) return [];
      const searchTerm = (row.unitTypeSearch || "").toLowerCase();
      return this.QuantityunitTypes.filter((unit) =>
        unit.toLowerCase().includes(searchTerm)
      );
    },

    selectUnitType(row, unit) {
      row.unitType = unit;
      row.showUnitTypeSearch = false;
    },
    async deleteLineItems(lineItemIds, rowIndex, index) {
      // Check if the row being deleted is the last one in its group
      const isLastRow = this.items[index].rows.length === 1;

      // Remove the row from the item
      this.items[index].rows.splice(rowIndex, 1);
      if (isLastRow) {
        this.items.splice(index, 1);
      }

      try {
        // Make the HTTP request to delete the line items
        await axios.delete(`${this.$apiEndPoint}/estimates/lineItems/deleteLineItems`, {
          data: {
            id: [lineItemIds],
          },
        });

        // If it was the last row, clear the header or remove the item

        this.fetchEstimateData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    //featch the EstimateData
    async fetchEstimateData() {
      try {
        console.log("fetchEstimateData function called");
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/${this.routeParams.estimateIndex}`
        );
        this.fetchedEstimateData = response.data;
        console.log("response data ", response.data);
        console.log("fetchedEstimateData ", this.fetchedEstimateData);

        // Update routeParams with fetched data
        this.routeParams.widthUnitType = this.fetchedEstimateData?.unitType;
        this.routeParams.customerNote = this.fetchedEstimateData?.customerNote;
        this.routeParams.clientName =
          this.fetchedEstimateData?.["leads.name"] ||
          this.fetchedEstimateData?.["customers.name"];
        this.routeParams.contactNo =
          this.fetchedEstimateData?.["leads.contactNo"] ||
          this.fetchedEstimateData?.["customers.contactNo"];
        this.routeParams.email =
          this.fetchedEstimateData?.["leads.email"] ||
          this.fetchedEstimateData?.["customers.email"];
        this.routeParams.salesPerson = this.fetchedEstimateData?.salesPersonId || null;
        this.routeParams.date = this.fetchedEstimateData?.date || null;
        // Construct address string
        this.routeParams.address = `${
          this.fetchedEstimateData?.["addresses.address"] ||
          this.fetchedEstimateData?.["leadAddresses.address"] ||
          ""
        } ${
          this.fetchedEstimateData?.["addresses.city"] ||
          this.fetchedEstimateData?.["leadAddresses.city"] ||
          ""
        } ${
          this.fetchedEstimateData?.["addresses.state"] ||
          this.fetchedEstimateData?.["leadAddresses.state"] ||
          ""
        }`;

        this.routeParams.leadId = this.fetchedEstimateData?.leadId;

        // Update line items
        if (this.fetchedEstimateData?.lineItems) {
          if (this.LineItemId) {
            console.log("If condition triggered");

            // Check if items have not been initialized yet
            if (!this.isItemsInitialized) {
              // Initialize `this.items` once
              console.log("Initializing items for the first time");

              this.items = this.fetchedEstimateData.lineItems.map((item) => ({
                header: item?.header,
                rows: item?.Rows.map((row) => ({
                  ...row,
                  id: null,
                  estimateId: null,
                  GSTpercentage: row?.GSTpercentage, // Directly assign GSTpercentage
                })),
              }));

              // Mark items as initialized
              this.isItemsInitialized = true;
            }

            // Proceed with merging or other operations
            this.items = this.items.map((existingItem) => ({
              ...existingItem,
              rows: existingItem.rows.map((existingRow, index) => {
                if (!existingRow.id) {
                  const newRow = this.fetchedEstimateData.lineItems.find(
                    (item) => item?.header === existingItem.header
                  )?.Rows?.[index];
                  console.log("New Row: ", newRow);
                  if (newRow) {
                    return {
                      ...existingRow,
                      id: newRow?.id, // Update the id
                      estimateId: newRow?.estimateId,
                    };
                  }
                }
                return existingRow; // Keep existing row if not found
              }),
            }));
          } else {
            console.log("If condition not triggered");
            // Replace entire rows if LineItemId is not present
            this.items = this.fetchedEstimateData.lineItems.map((item) => ({
              header: item?.header,
              rows: item?.Rows.map((row) => ({
                ...row,
                GSTpercentage: row?.GSTpercentage, // Directly assign GSTpercentage
              })),
            }));
          }

          // Handle GST options and details
          this.GSTOptions.forEach((option) => {
            const gstData = this.fetchedEstimateData?.GSTgrouping?.find(
              (group) => group?.gst === option
            );
            if (gstData) {
              this.gstDetails.push({
                gstPercentage: gstData?.option,
                detail: gstData?.amount,
              });
            }
          });
        }

        if (this.$route.params?.EstimateDetails) {
          this.changeStatusEstimate(this.$route.params?.estimateIndex);
          this.DraftEstimate();
          this.$route.params.EstimateDetails = null;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async changeStatusEstimate(estimateindex) {
      try {
        await axios.put(`${this.$apiEndPoint}/estimates/${estimateindex}`, {
          status: "Revised",
        });
      } catch (error) {
        this.$handleError(error);
      }
    },
    //addItem and addRow funtions
    addItem() {
      if (!this.items.length) {
        if (!this.routeParams.widthUnitType) {
          alert("Please select height and width unit Types For The Estimate ");
          this.items.push(this.createEmptyItem());
        } else {
          this.items.push(this.createEmptyItem());
        }

        //
      } else if (this.isLastItemFilled()) {
        this.items.push(this.createEmptyItem());
      } else {
        alert("Please fill the previous item Header Name.");
        this.$nextTick(() => {
          const lastItemIndex = this.items.length - 1;
          this.$refs[`header-${lastItemIndex}`][0].focus();
        });
      }
    },
    addRow(index) {
      const currentItem = this.items[index];
      if (!Array.isArray(currentItem.rows)) {
        this.$set(this.items[index], "rows", []);
      }

      if (currentItem.rows.length === 0 || this.isLastRowFilled(currentItem)) {
        this.items[index].rows.push(this.createEmptyRow());
      } else {
        this.$nextTick(() => {
          const lastRowIndex = currentItem.rows.length - 1;
          this.$refs[`row-${index}-${lastRowIndex}`][0].focus();
        });
      }
    },
    isLastItemFilled() {
      const lastItem = this.items[this.items.length - 1];
      return lastItem.header.trim() !== "";
    },
    isLastRowFilled(item) {
      const lastRow = item.rows[item.rows.length - 1];
      return lastRow && lastRow.description.trim() !== "";
    },
    createEmptyItem() {
      return {
        header: "",
        rows: [this.createEmptyRow()],
      };
    },
    createEmptyRow() {
      return {
        id: null,
        description: "",
        width: 0,
        height: 0,
        quantity: 0,
        unitType: "",
        rate: 0,
        discount: 0,
        GSTpercentage: 0,
        amount: 0,
        searchMaterials: [],
      };
    },

    toggleWidth() {
      this.toggleWidththings = !this.toggleWidththings;
    },
    toggleHeight() {
      this.toggleHeightthings = !this.toggleHeightthings;
    },
    setWidthUnitType(unitType) {
      this.routeParams.widthUnitType = unitType;
    },
    setHeightUnitType(unitType) {
      this.routeParams.heightUnitType = unitType;
    },
    calculateQuantity(row) {
      const width = parseFloat(row.width);
      const height = parseFloat(row.height);
      let quantity = width * height; // Initial quantity calculation

      const unitType = this.routeParams.widthUnitType || this.routeParams.heightUnitType;
      switch (unitType) {
        case "mm":
          quantity /= 92903; // Convert mm to sqft
          break;
        case "cm":
          quantity /= 929.03; // Convert cm to sqft
          break;
        case "meter":
          quantity *= 10.7639; // Convert meter to sqft
          break;
        case "inch":
          quantity /= 144; // Convert inch to sqft
          break;
        case "feet":
          // Already in sqft, no need to convert
          break;
        default:
          break;
      }

      row.quantity = isNaN(quantity) ? 0 : quantity.toFixed(2);
      row.unitType = "sq.ft";
    },
    isQuantityReadOnly(row) {
      // Determine if the quantity input should be read-only
      return row.width && row.width !== 0 && row.height && row.height !== 0;
    },

    // Calculate amount for a single row
    calculateAmount(row) {
      const rate = parseFloat(row.rate);
      const quantity = parseFloat(row.quantity);
      const discount = parseFloat(row.discount || 0);

      // Calculate amount
      let amount = rate * quantity - (discount * rate * quantity) / 100;

      // Assign the calculated amount back to row.amount as a number
      row.amount = isNaN(amount) ? 0 : parseFloat(amount.toFixed(2));

      // Return the amount as a number
      return row.amount;
    },

    calculateTotalAmountofItem(item) {
      let totalAmount = 0;
      item.rows.forEach((row) => {
        // Calculate amount for the current row
        const amountForRow = parseFloat(this.calculateAmount(row) || 0);
        // Accumulate the amount to the total amount
        totalAmount += amountForRow;
      });
      // Return the total amount formatted to 2 decimal places
      return new Intl.NumberFormat("en-IN", {
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(totalAmount);
    },

    calculateFooterTotals() {
      let totalsByGST = {}; // Object to store totals categorized by GST percentage
      let totalExcludingGST = 0;
      let CGST = 0;
      let SGST = 0;
      // Calculate totals only if there are items
      if (this.items.length > 0) {
        this.items.forEach((item) => {
          item.rows.forEach((row) => {
            const amount = parseFloat(row.amount || 0);
            totalExcludingGST += amount;
            const gst = parseFloat(row.GSTpercentage || 0);
            const gstAmount = (gst / 100) * amount;

            // Grouping GST amounts by percentage
            if (!totalsByGST[gst]) {
              totalsByGST[gst] = 0;
            }
            totalsByGST[gst] += gstAmount;
          });
        });

        // Calculate CGST and SGST for each category
        for (let gst in totalsByGST) {
          const gstAmount = totalsByGST[gst];
          CGST += gstAmount / 2;
          SGST += gstAmount / 2;
        }
      }
      const grandTotal = totalExcludingGST + CGST + SGST;
      return {
        totalExcludingGST: isNaN(totalExcludingGST) ? 0 : totalExcludingGST.toFixed(2),
        CGST: isNaN(CGST) ? 0 : CGST.toFixed(2),
        SGST: isNaN(SGST) ? 0 : SGST.toFixed(2),
        gstDetails: totalsByGST, // Object containing GST details
        grandTotal: isNaN(grandTotal) ? 0 : grandTotal.toFixed(2),
      };
    },

    calculateGrandTotal() {
      const { totalExcludingGST, CGST, SGST } = this.calculateFooterTotals();

      // Calculate the grand total
      const grandTotal =
        parseFloat(totalExcludingGST || 0) +
        parseFloat(CGST || 0) +
        parseFloat(SGST || 0);

      // Return the grand total as a number, ensuring it's not NaN
      return isNaN(grandTotal) ? 0 : parseFloat(grandTotal.toFixed(2));
    },

    // Calculate final total including GST
    calculateFinalTotal() {
      const { grandTotal } = this.calculateFooterTotals();
      return parseFloat(grandTotal).toFixed(2);
    },

    async createEstimate(postData) {
      // Check if there's at least one line item with a header and rows
      const hasValidLineItems = postData.lineItems.some(
        (item) => item.header.trim() !== "" && item.Rows.length > 0
      );

      // Check if leadId is present
      if (!postData.leadId) {
        this.$toast("Lead  is required to create an estimate.", "error");
        this.isLoadingDraft = false;
        this.isLoadingFinalize = false;
        return;
      }

      if (!hasValidLineItems) {
        this.$toast(
          "At least one line item with a header and rows is required.",
          "error"
        );
        this.isLoadingDraft = false;
        this.isLoadingFinalize = false;
        return;
      }

      try {
        const response = await axios.post(`${this.$apiEndPoint}/estimates/`, postData);
        this.status = response.data.status;
        this.routeParams.estimateIndex = response.data.id;
        this.routeParams.estimateId = response.data.estimateId;
        this.fetchEstimateData();
        this.isLoadingDraft = false;
        this.isLoadingFinalize = false;
        this.$toast(
          `Estimate ${this.routeParams.estimateId} created successfully!`,
          "success"
        );

        if (response.data.status === "Finalized") {
          this.$router.push({
            name: "FinalEstimate",
            params: {
              EstimateId: response.data.estimateId,
              estimateIndex: response.data.id,
            },
          });
        }
      } catch (error) {
        this.isLoadingDraft = false;
        this.isLoadingFinalize = false;
        this.$handleError(error);
      }
    },

    async updateEstimate(postData) {
      console.log(postData);
      // Log makeFinalize status for debugging
      console.log(this.makeFinalize);

      console.log(postData.lineItems);

      console.log(this.fetchedEstimateData.lineItems);
      // Compare postData line items and customerNote with fetchedEstimateData
      const lineItemsChanged = !this.areLineItemsEqual(
        postData.lineItems,
        this.fetchedEstimateData.lineItems
      );

      const customerNoteChanged =
        postData.customerNote !== this.fetchedEstimateData.customerNote;

      // Determine if the estimate should be finalized
      const makeFinalized = this.makeFinalize === true;

      // If any changes are detected or finalization is requested, proceed with update
      if (lineItemsChanged || customerNoteChanged || makeFinalized) {
        try {
          // Perform the update request
          const response = await axios.put(`${this.$apiEndPoint}/estimates/`, postData);

          // Reset loading states after the request completes
          this.isLoadingDraft = false;
          this.isLoadingFinalize = false;

          // Refetch the estimate data after the update
          this.fetchEstimateData();

          // Display a success message
          this.$toast(
            `Estimate ${this.routeParams.estimateId} saved successfully!`,
            "success"
          );

          // Check the response data and redirect if finalized
          if (response.data && postData.status === "Finalized") {
            this.$router.push({
              name: "FinalEstimate",
              params: {
                EstimateId: this.routeParams.estimateId,
                estimateIndex: this.routeParams.estimateIndex,
              },
            });
          }
        } catch (error) {
          // Reset loading states in case of error
          this.isLoadingDraft = false;
          this.isLoadingFinalize = false;
          this.$handleError(error);
        }
      } else {
        // Optionally notify the user if no changes are detected
        // this.$toast("No changes detected, skipping update.", "info");
      }
    },

    areLineItemsEqual(lineItems1, lineItems2) {
      // Check if both arrays have the same length
      if (lineItems1.length !== lineItems2.length) {
        return false;
      }

      // Compare each line item in the arrays
      for (let i = 0; i < lineItems1.length; i++) {
        const item1 = lineItems1[i];
        const item2 = lineItems2[i];

        // Check if the headers are the same
        if (item1.header !== item2.header || item1.Rows.length !== item2.Rows.length) {
          return false;
        }

        // Compare each row in the line items
        for (let j = 0; j < item1.Rows.length; j++) {
          const row1 = item1.Rows[j];
          const row2 = item2.Rows[j];

          // Check if rows have the same id and properties
          if (
            row1.description !== row2.description ||
            row1.width !== row2.width ||
            row1.height !== row2.height ||
            row1.quantity !== row2.quantity ||
            row1.unitType !== row2.unitType ||
            row1.rate !== row2.rate ||
            row1.discount !== row2.discount
          ) {
            return false;
          }
        }
      }

      return true;
    },

    async DraftEstimate() {
      if (
        this.items.length === 0 ||
        !this.items.some(
          (item) =>
            item.header &&
            item.rows.length > 0 &&
            item.rows.some((row) => row.description)
        )
      ) {
        console.log(
          "Please ensure there is at least one header and one line item with a description."
        );
        this.isLoadingDraft = false; // Stop the loading spinner
        return;
      }

      this.isFinalEstimate = false; // Set status as Draft
      this.isLoadingDraft = true;
      const postData = this.createPostData();
      const estimateId = this.routeParams.estimateId || "";

      if (this.routeParams.estimateIndex && estimateId.trim() !== "") {
        await this.updateEstimate(postData);
      } else {
        await this.createEstimate(postData);
      }

      this.isLoadingDraft = false; // Stop the loading spinner after the API call is done
    },

    async FinalEstimate() {
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval);
      }

      this.isFinalEstimate = true;
      this.isLoadingFinalize = true;
      this.makeFinalize = true;

      const postData = this.createPostData();

      if (!this.validatePostData(postData)) {
        this.isLoadingFinalize = false; // Stop loading spinner if validation fails
        return; // Exit the function if validation fails
      }

      const estimateId = this.routeParams.estimateId || ""; // Provide a default value if undefined

      if (this.routeParams.estimateIndex && estimateId.trim() !== "") {
        this.updateEstimate(postData);
      } else {
        this.createEstimate(postData);
      }
    },

    createPostData() {
      const gstGrouping = {}; // Object to store GST grouping by percentage
      this.GSTOptions.forEach((option) => {
        gstGrouping[option] = 0; // Initialize each GST percentage with 0
      });
      // Calculate GST grouping
      this.items.forEach((item) => {
        item.rows.forEach((row) => {
          const gst = parseFloat(row.GSTpercentage || 0);
          const amount = parseFloat(row.amount || 0);
          const gstAmount = (amount * gst) / 100; // Calculate GST amount
          gstGrouping[gst] += gstAmount;
        });
      });

      let unitType = this.routeParams.widthUnitType || "feet";

      const postDataObj = {
        id: this.routeParams.estimateIndex || "",
        leadId: this.routeParams.leadId || "",
        unitType: unitType,
        customerNote: this.routeParams.customerNote,
        status: this.isFinalEstimate ? "Finalized" : "Draft",
        total: this.calculateFooterTotals().totalExcludingGST,
        CGST: this.calculateFooterTotals().CGST,
        SGST: this.calculateFooterTotals().SGST,
        grandTotal: this.calculateFooterTotals().grandTotal,
        lineItems: this.items.map((item) => ({
          header: item.header,
          Rows: item.rows.map((row) => ({
            ...row,
            width: row.width || 0,
            height: row.height || 0,
            GSTpercentage: row.GSTpercentage, // Include GSTpercentage in each row
          })),
        })),
        GSTgrouping: Object.keys(gstGrouping)
          .map((gst) => ({
            gst: gst,
            amount: gstGrouping[gst].toFixed(2), // Round to 2 decimal places
          }))
          .filter((group) => group.amount > 0), // Filter out GST with 0 amount
      };

      return postDataObj;
    },

    validatePostData(postData) {
      return postData.lineItems.every((item, itemIndex) =>
        item.Rows.every((row, rowIndex) => this.validateRow(row, itemIndex, rowIndex))
      );
    },

    validateRow(row, itemIndex, rowIndex) {
      return (
        this.validateField(
          row.description.trim(),
          "Description is required.",
          `row-${itemIndex}-${rowIndex}`
        ) &&
        this.validateField(
          row.quantity > 0,
          "Quantity must be greater than 0.",
          `quantity-${itemIndex}-${rowIndex}`
        ) &&
        this.validateField(
          row.unitType.trim(),
          "Unit Type is required.",
          `unitType-${itemIndex}-${rowIndex}`
        ) &&
        this.validateField(
          row.rate > 0,
          "Rate must be greater than 0.",
          `rate-${itemIndex}-${rowIndex}`
        ) &&
        this.validateField(
          row.GSTpercentage >= 0,
          "GST percentage must be non-negative.",
          `GSTpercentage-${itemIndex}-${rowIndex}`
        )
      );
    },

    validateField(condition, message, refKey) {
      if (!condition) {
        alert(message);
        this.$nextTick(() => {
          this.$refs[refKey][0].focus();
        });
        return false;
      }
      return true;
    },

    searchSalesPerson() {
      this.showDropdown1 = this.selectedSalesPerson.trim() !== "";
    },
    selectSalesPerson(salesPerson) {
      this.selectedSalesPerson = salesPerson["users.firstname"];
      this.routeParams.salesPerson = salesPerson.id;
      this.showDropdown1 = false;
    },

    searchAndFillLeadDetails() {
      // Make an API call to search for leads
      console.log(this.routeParams.clientName);
      axios
        .post(`${this.$apiEndPoint}/leads/searchLeadForEstimate`, {
          searchString: this.routeParams.clientName,
        })
        .then((response) => {
          console.log(response.data);
          // Filter leads based on the presence of the input in their names
          this.searchResults = response.data;
          // .filter((lead) =>
          //   this.containsInput(lead.name, this.routeParams.clientName)
          // );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    featchMaterial() {
      axios
        .get(`${this.$apiEndPoint}/materialLibrary/`)
        .then((response) => {
          this.Material = response.data;
        })
        .catch(() => {});
    },
    searchAndFillMaterialDetails(itemIndex, rowIndex) {
      const description = this.items[itemIndex]?.rows?.[rowIndex]?.description;
      if (!description) {
        return;
      }
      this.items[itemIndex].rows[
        rowIndex
      ].searchMaterials = this.Material.filter((material) =>
        this.containsInput(material.materialName, description)
      );
    },

    containsInput(name, input) {
      if (!input || !name) return false;
      const normalizedInput = input.toUpperCase();
      const normalizedName = name.toUpperCase();
      return normalizedName.includes(normalizedInput);
    },

    startsWithSameLetter(name, input) {
      if (!input || !name) return false;
      const firstChar = input.charAt(0).toUpperCase();
      return name.toUpperCase().startsWith(firstChar);
    },
    selectMaterial(result, itemIndex, rowIndex) {
      this.items[itemIndex].rows[rowIndex].description = result.materialName;
      this.items[itemIndex].rows[rowIndex].discount = result.discount;
      this.items[itemIndex].rows[rowIndex].rate = result.unitPrice;
      this.items[itemIndex].rows[rowIndex].searchMaterials = [];
    },

    // Method for selecting a client from search results
    async selectClient(client) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/getLeadForEstimate/${client.id}`
        );

        // Update the clientName in routeParams with the selected client's name
        this.routeParams.clientName = response.data.name;
        this.routeParams.contactNo = response.data.contactNo;
        this.routeParams.leadId = response.data.id;
        this.routeParams.propertyName = response.data["property.name"];
        this.routeParams.email = response.data.email;
        if (response.data["leadAddresses.address"]) {
          this.routeParams.address = `${response.data["leadAddresses.address"] ?? ""} ${
            response.data["leadAddresses.city"] ?? ""
          } ${response.data["leadAddresses.state"] ?? ""}`;
        } else {
          this.routeParams.address = `${response.data["addresses.address"] ?? ""} ${
            response.data["addresses.city"] ?? ""
          } ${response.data["addresses.state"] ?? ""}`;
        }
        this.routeParams.salesPerson = response.data.salesPersonId;
        // Clear the search results
        this.searchResults = [];
      } catch (error) {
        this.$handleError(error);
      }
    },

    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
  },
};
</script>

<style scoped>
select {
  width: auto;
  outline: none;
  background-color: transparent;
  text-align: left;
  padding: 6px 8px;

  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin: 0 8px 8px 0px;
  /* Add padding to the select */
}

/* Style the options within the dropdown */
select option {
  font-size: 14px;
  text-align: left;
  padding: 8px;
  /* Add padding to each option */
  margin-bottom: 5px;
  /* Add margin between options */
  background-color: #ffffff;
  /* Optional: Background color for options */
  color: #333333;
  /* Optional: Text color for options */
}

.dropdown-menu {
  width: 50px !important;
}

.EstimateDate {
  margin-left: 50px;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  /* Add a custom focus shadow */
}

.EstimateForm label {
  font-weight: 500;
  color: black;
  font-size: 14px;
}

.EstimateForm input,
.EstimateForm textarea,
.EstimateForm select {
  border: 1px solid #ccc;
  outline: none !important;
  font-size: 14px;
}

.serchMyLeads {
  position: absolute;
  top: 13%;
  left: 13.5%;
  width: 31%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  max-height: 120px;
  overflow-y: auto;
  z-index: 100;
  color: #000000;
}

.serchMyLeads ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.serchMyLeads li {
  font-size: 14px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.serchMyLeads li:hover {
  background-color: #f8f8f8;
  border-radius: 10px;
}

.EstimateRegister {
  width: 100%;
  height: 100%;
}

.EstimateBill::-webkit-scrollbar {
  display: none;
}

.EstimateBill {
  width: 99%;
  padding: 0% 0.5%;
  height: 100%;
  margin-top: 20px;
}

.tableOfEstimate thead tr th {
  background-color: #bcbfc6;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableOfEstimate tbody {
  overflow: scroll;
}

.tableOfEstimate tbody::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate thead tr {
  font-size: 14px;
}

.tableOfEstimate tbody tr {
  height: 35px;
  font-size: 14px;
}

.tableOfEstimate tbody tr,
.tableOfEstimate thead tr {
  display: flex;
}

.tableOfEstimate thead tr th,
.tableOfEstimate tbody tr td {
  min-width: 9%;
  max-width: 10%;
  text-align: center;
}

.tableOfEstimate tbody tr td {
  border: 1px solid #edeae9;
}

.tableOfEstimate thead tr th:last-child,
.tableOfEstimate tbody tr td:last-child {
  min-width: 10%;
  max-width: 10%;
  text-align: right;
}

.tableOfEstimate thead th:nth-child(1),
.tableOfEstimate tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
  padding-top: 6px;
  font-size: 14px;
}

.tableOfEstimate tbody tr td input {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableOfEstimate tbody tr td input[type="number"] {
  background-color: transparent;
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: right;
  justify-content: right;
  text-align: right;
  font-size: 14px;
}

/* Remove increment and decrement buttons (spinners) */
.tableOfEstimate tbody tr td input[type="number"]::-webkit-inner-spin-button,
.tableOfEstimate tbody tr td input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  /* Optional: adjust if needed */
}

.tableOfEstimate tbody tr td:not(:nth-child(2)) input[type="text"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.tableOfEstimate tbody tr td select {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.tableOfEstimate tbody tr td:nth-child(6) select {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.tableOfEstimate tbody tr td:last-child {
  text-align: right;
}

.tableOfEstimate tbody td:nth-child(2),
.tableOfEstimate thead th:nth-child(2) {
  min-width: 20%;
  max-width: 20%;
  word-break: break-all;
}

.tableOfEstimate thead th:nth-child(3),
.tableOfEstimate thead th:nth-child(4) {
  min-width: 11%;
  max-width: 11%;
  word-break: break-all;
}

/* Align all table cells in the tbody, except the second child, to the right */
.tableOfEstimate thead tr th:not(:nth-child(2)) {
  text-align: center;
}

.itemBlock {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.ItemHeaderOfThings {
  display: flex;
  white-space: nowrap;

  align-items: center;
}

.ItemHeaderOfThings p {
  position: relative;
  margin: 0 1%;
  color: #000000;
  font-weight: bold;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.itemBlock span button {
  background: transparent;
  display: flex;
  padding: 0.5%;
  font-size: 10px;
  border-radius: 10px;
  color: #ffbd59;
  outline: none;
  border: none;
}

#itemRowEstimate {
  border-bottom: 1px solid #000000;
}

.itemBlock #HeaderOfBillItem {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}

#itemAdding {
  display: flex;
  background-color: #084f5b;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid #ffaa23;
}

#itemrowAdding {
  display: flex;
  color: #000000;
  margin-left: 1%;
  font-size: 12px;
  padding: 0.5% 1%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}

.toggleWidththings,
.toggleHeightthings {
  position: fixed;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  letter-spacing: 2px;
}

.toggleWidththings p,
.toggleHeightthings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
  font-weight: 100;
}

/* Responsive table styles */
.tableOfEstimate {
  width: 100%;
  border-collapse: collapse;
}

.tableOfEstimate th,
.tableOfEstimate td {
  padding: 4px 8px;
}

.ItemHeaderOfThings input {
  flex: 1;
  margin-right: 10px;
}

.dropdownToggle {
  cursor: pointer;
}

/* Responsive table container */
.table-responsive {
  overflow-x: auto;
}

/* Additional styling for form inputs */
input[type="text"],
input[type="number"],
select {
  width: 100%;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  margin-top: 10px;
}

/* Styles for responsiveness */
@media only screen and (max-width: 768px) {
  .tableOfEstimate tbody td,
  .tableOfEstimate thead th {
    white-space: nowrap;
    min-width: 120px;
    position: relative;
  }

  .tableOfEstimate tbody tr,
  .tableOfEstimate thead tr {
    display: flex;
    overflow: scroll;
  }

  .footerOfEstimate {
    margin-bottom: 50px;
  }

  .serchMyLeads {
    position: absolute;
    top: 21%;
    left: 5%;
    width: 80%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    max-height: 120px;
    overflow-y: auto;
    z-index: 100;
    color: #000000;
  }

  .serchMyLeads::-webkit-scrollbar {
    display: none;
  }

  .serchMyLeads ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .serchMyLeads li {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .serchMyLeads li:hover {
    background-color: #bcbfc6;
    border-radius: 20px;
    color: white;
  }

  .EstimateDate {
    margin-left: 5px;
  }
}
</style>

/* calculateAmount(row) { const rate = parseFloat(row.rate); const quantity =
parseFloat(row.quantity); const discount = parseFloat(row.discount || 0); const gst =
parseFloat(row.GST || 0); let amount = rate * quantity - (discount * rate * quantity) /
100; let gstAmount = (gst / 100) * amount; amount += gstAmount; // Adding GST to the
amount // Save the GST amount for display purposes row.gstAmount = isNaN(gstAmount) ? 0 :
gstAmount.toFixed(2); return isNaN(amount) ? 0 : amount.toFixed(2); },*/
