import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import './assets/sass/style.scss';
import setAuthHeader from './utilToken/setAuthHeader';
import { showToast } from './utilToken/toast';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import UserObject from './utilToken/UserObject';
import ApexCharts from 'apexcharts';
import capitalizeFirstLetter from './utilToken/capitalizeFirstLetter.js';
import axios from 'axios';

if (sessionStorage.jwtToken) {
    setAuthHeader(sessionStorage.jwtToken);
} else {
    setAuthHeader(false);
}
const app = createApp(App);
app.config.globalProperties.$LocalUser = UserObject;


// Define the API endpoint
const apiEndPoint = process.env.VUE_APP_BACKEND_URL;

function isExecutive(Team)
{
    const roles = UserObject.employeeRoles;
    console.log(roles);
    return roles.some((role) => role.team === Team && role.role === "Executive");
}

function isManager(Team)
{
    let roles =UserObject.employeeRoles;
    return roles.some((role) => role.team === Team && role.role === "Manager");

}


function handleGlobalError(error) {
    let errorMessage;
    if (error.response) {
        if (error.response.status === 500) {
            errorMessage = "Something went wrong, Please try again later.";
        } else if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        }
    } else if (error.message) {
        errorMessage = error.message;
    }
    if(errorMessage)
    {
    this.$toast(errorMessage, "error");
    }
}

// Define the currency formatter function
function formatToIndianCurrency(amount) {
    if (amount == null || isNaN(amount)) {
        return '00.00';
    }
    if (amount == 0) {
        return '00.00';
    }
    if (typeof amount !== "number") {
        amount = parseFloat(amount);
    }
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    }).format(amount);
}

function formatDateDMY(dateString) {
    if (dateString == null) {
        return '-';
    }

    // Regular expression to check if the date string is in DD/MM/YYYY format
    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (dateFormatRegex.test(dateString)) {
        return dateString;
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return '-';
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
}


// Fetch and set company data and logo as global properties
function fetchCompanyData() {
    const user = sessionStorage.getItem("user-info");
    if (user) {
        const userData = JSON.parse(user);
        const companyId = userData.user.companyId;
        
        axios.get(`${apiEndPoint}/company/${companyId}`)
            .then((response) => {
                app.config.globalProperties.$CompanyData = response.data;
            })
            .catch(() => {
               
            });

        const payload = {
            entityId: companyId,
            fileCategory: "logo",
            moduleName: "company",
        };

        axios.post(`${apiEndPoint}/company/getFileSignedURL/`, payload)
            .then((response) => {
                const logoUrl = Object.values(response.data)[0];
                app.config.globalProperties.$CompanyLogoUrl = logoUrl;

                // Use XMLHttpRequest to fetch the image as a Blob
                const xhr = new XMLHttpRequest();
                xhr.open("GET", logoUrl, true);
                xhr.responseType = "blob";
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        const reader = new FileReader();
                        reader.readAsDataURL(xhr.response);
                        reader.onloadend = function() {
                            const base64data = reader.result;
                            // Set the base64 image URL to a global property
                            app.config.globalProperties.$CompanyLogoUrl = base64data;
                       
                        }
                    } else {
                        console.error("Error fetching image:", xhr.statusText);
                    }
                };
                xhr.onerror = function() {
                    console.error("Error making XMLHttpRequest");
                };
                xhr.send();
            })
            .catch(() => {
                
            });
    }
}


fetchCompanyData();





// Make ApexCharts available globally
app.config.globalProperties.$apexcharts = ApexCharts;
// Make UserObject available globally
// Make API endpoint available globally
app.config.globalProperties.$apiEndPoint = apiEndPoint;

// Make showToast method globally available
app.config.globalProperties.$toast = showToast;

// Register the custom directive globally
app.directive('capitalize-first-letter', capitalizeFirstLetter);
app.config.globalProperties.$handleError = handleGlobalError;
app.config.globalProperties.$isExecutive = isExecutive;
app.config.globalProperties.$isManager = isManager;
// Make formatToIndianCurrency function globally available
app.config.globalProperties.$formatToIndianCurrency = formatToIndianCurrency;
app.config.globalProperties.$formatDateDMY = formatDateDMY;

app.config.globalProperties.$downloadFile = function (AwsFileName, FileName, FileCategory, ModuleName) {
    const PayLoad = {
        awsFileName: AwsFileName,
        fileCategory: FileCategory,
        moduleName: ModuleName,
        fileName: FileName,
    };

    axios.post(`${apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
            const purchaseInvoiceFileUrl = Object.values(response.data)[0];
            if (purchaseInvoiceFileUrl) {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", purchaseInvoiceFileUrl);
                xhr.responseType = "blob";

                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const blob = xhr.response;
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = FileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                       console.error("Error downloading file:", xhr.statusText);
                    }
                };

                xhr.onerror = function () {
                console.error("Error downloading file:", xhr.statusText);
                };

                xhr.send();
            }
        })
        .catch(() => {
         
        });
};

// app.config.globalProperties.$viewFile = function (AwsFileName, FileName, FileCategory, ModuleName) {
//     const PayLoad = {
//         awsFileName: AwsFileName,
//         fileCategory: FileCategory,
//         moduleName: ModuleName,
//         fileName: FileName,
//     };

//     axios
//         .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
//         .then((response) => {
//             this.PurchaseInvoiceFileUrl = Object.values(response.data)[0];
//             if (this.PurchaseInvoiceFileUrl) {
//                 window.open(this.PurchaseInvoiceFileUrl, "_blank");
//             }
//         })
//         .catch(() => {
      
//         });
// };

app.use(router).mount('#app');

