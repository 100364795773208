<template>
    <div>
        <Header />
        <div class="content-Container">
            <Nav />
            <div class="containerChild">
                <h5 style="font-weight: bold; padding-left: 20px; padding-top: 12px">My Task</h5>

                <!-- view contents -->
                <div class="d-flex align-items-center justify-content-between px-3 pt-2 mt-0">
                    <div class="d-flex align-items-center gap-3">
                        <div>
                            <!-- <button class="btn btn-sm custom-btn">
                <span>
                  <img
                    src="../../assets/icons/report.png"
                    style="
                      width: 13px;
                      height: 13px;
                      padding-right: 2px;
                      padding-bottom: 2px;
                    "
                    alt=""
                  />
                </span>
                Card View
              </button> -->
                            <button type="button" class="btn btn-outline-primary">
                                <span>
                                    <img src="../../assets/icons/report.png" style="
                      width: 13px;
                      height: 13px;
                      padding-right: 2px;
                      padding-bottom: 2px;
                    " alt="" /> </span>Card View
                            </button>
                        </div>

                        <div>
                            <button style="cursor: not-allowed" title="Card view is active, list view coming soon"
                                type="button" class="btn btn-outline-secondary">
                                <span>
                                    <img src="../../assets/icons/threeDot.jpg"
                                        style="width: 13px; height: 13px; padding: 2px" alt="" />
                                </span>List View
                            </button>

                            <!-- <button class="btn btn-sm custom-btn" style="cursor: not-allowed"
                title="Card view is active, list view coming soon">

                List View
              </button> -->
                        </div>
                    </div>

                    <div style="border-bottom: 2px solid black">
                        <span>
                            <img src="../../assets/icons/search.png" class="search-img" alt="" />
                        </span>
                        <input type="text" class="search" placeholder="Search" v-model="searchString"
                            @input="searchLeads"
                            style="width: 200px; border: none; border-radius: 0; font-size: small" />
                        <span>
                            <img src="../../assets/icons/filter.png" class="filter-img" alt="" />
                        </span>
                    </div>
                </div>

                <hr class="line-break" />

                <!-- Task buttons with fixed positioning when scrolling -->
                <div class="task-buttons-wrapper">
                    <!-- task buttons -->
                    <div class="d-flex justify-content-between align-items-center child-2 container-bg">
                        <div class="task-buttons">
                            <!-- "Do Today" button -->
                            <button ref="todayTasksButton" class="btn btn-m"
                                :class="{ active: selectedTask === 'Today' }" @click="handleButtonClick('Today')">
                                Do Today
                                <span class="task-count">{{ this.Today.length }}</span>
                            </button>

                            <!-- "Do Later" button -->
                            <button class="btn btn-m" :class="{ active: selectedTask === 'Pending' }"
                                @click="handleButtonClick('Pending')">
                                Do Later
                                <span class="task-count">{{ this.Pending.length }}</span>
                            </button>

                            <!-- "Overdue" button -->
                            <button class="btn btn-m" :class="{ active: selectedTask === 'Overdue' }"
                                @click="handleButtonClick('Overdue')">
                                Overdue
                                <span class="task-count">{{ this.Overdue.length }}</span>
                            </button>

                            <!-- "Completed" button -->
                            <button class="btn btn-m" :class="{ active: selectedTask === 'Completed' }"
                                @click="handleButtonClick('Completed')">
                                Completed
                                <span class="task-count">{{ this.Completed.length }}</span>
                            </button>
                        </div>

                        <div class="add-task">
                            <button class="btn btn-primary" @click="openTaskmodal(null)" data-bs-toggle="modal"
                                data-bs-target="#taskModal">
                                <span><img style="height: 15px; width: 15px; padding: 2px; margin-bottom: 2px"
                                        src="../../assets/icons/plusIconSales.png" /></span>Add Task
                            </button>
                        </div>
                    </div>
                </div>

                <div style="height: 100%; background-color: #fcfcfc">
                    <div class="cards" style="background-color: #fcfcfc">
                        <div class="row col-md-12">
                            <div v-for="task in tasks" :key="task.id" class="col-md-3">
                                <div class="task-card">
                                    <div class="row col-md-12">
                                        <div class="col-md-1">
                                            <img v-if="task.status === 'Completed'" class="tick-Icon mb-2"
                                                src="../../assets/icons/completedTask.png" alt="Green Tick"
                                                @click="markAsInComplete(task.id)" />
                                            <img v-else class="tick-Icon greyicon mb-2" src="../../assets/icons/T.png"
                                                alt="Grey Tick" @click="markAsCompleted(task.id)" />
                                        </div>
                                        <div class="col-md-10" data-bs-toggle="modal" data-bs-target="#taskModal"
                                            @click="openTaskmodal(task.id)">
                                            <p :title="task.title" style="
                          font-size: 13px;
                          font-weight: bold;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        ">
                                                {{ task.title ? truncateText(task.title, 30) :
                                                "" }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row col-md-12" style="margin-left: 15px" data-bs-toggle="modal"
                                        data-bs-target="#taskModal" @click="openTaskmodal(task.id)">
                                        <p :title="task.description"
                                            style="font-size: 10px; text-overflow: ellipsis; overflow: hidden">
                                            {{ task.description ? truncateText(task.description,
                                                35) : "No Description" }}
                                        </p>
                                    </div>
                                    <div class="row col-md-12 d-flex align-items-center" data-bs-toggle="modal"
                                        data-bs-target="#taskModal" @click="openTaskmodal(task.id)">
                                        <div class="col-md-1">
                                            <img class="tick-Icon mb-1" src="../../assets/icons/profile.png" alt="" />
                                        </div>
                                        <div class="col-md-9 d-flex align-items-start">
                                            <label style="font-size: 12px">Assignee:</label>
                                            <span style="font-size: 12px; font-weight: bold; margin-left: 17px;">
                                                {{ task["users.firstName"] }} {{
                                                    task["users.lastName"] }}<br />
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-md-12 d-flex align-items-center" data-bs-toggle="modal"
                                        data-bs-target="#taskModal" @click="openTaskmodal(task.id)">
                                        <div class="col-md-1">
                                            <img class="tickIcon mb-1" src="../../assets/icons/calandar.png" alt="" />
                                        </div>
                                        <div class="col-md-7 d-flex align-items-start">
                                            <label style="font-size: 12px; margin-right: 5px">Due
                                                Date:</label>
                                            <span style="font-size: 12px; font-weight: bold; margin-left: 10px">
                                                {{ $formatDateDMY(task.date) }}
                                            </span>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-end" data-bs-toggle="modal"
                                            data-bs-target="#taskModal" @click="openTaskmodal(task.id)">
                                            <button class="priority-button mb-2" v-if="task.priority"
                                                :class="getPriorityClass(task.priority)" style="
                          font-size: 12px;
                          padding: 4px 8px;
                          width: 100px;
                          line-height: 1;
                        ">
                                                {{ task.priority }}
                                            </button>
                                        </div>
                                    </div>
                                    <div style="border-bottom: 1px solid rgba(128, 128, 128, 0.5)">
                                    </div>
                                    <div class="row col-md-12" data-bs-toggle="modal" data-bs-target="#taskModal"
                                        @click="openTaskmodal(task.id)">
                                        <div class="col-md-2">
                                            <img src="../../assets/icons/attachment.png" class="attachment-icon"
                                                alt="" /><span style="font-size: x-small; padding-left: 2px">0</span>
                                        </div>
                                        <div class="col-md-2">
                                            <img src="../../assets/icons/subtask.png" class="subtask-icon"
                                                alt="" /><span style="font-size: x-small; padding-left: 2px">
                                                {{ task.subtaskCnt }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Side Panel modal-->
        <taskSidePanel @submit-form="addTask" @subtaskAdded="addTask" @updateTaskById="handleTaskUpdatebyId"
            @task-deleted="handleTaskDeleted" @subtask-deleted="handleTaskDeleted" @task-updated="handleTaskComplete"
            @subtaskAdding="handleSubtaskAdded" @CompletedAction="taskcompleted"
            :selectedTaskId="this.selectedTaskId" />
    </div>
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import taskSidePanel from "../Modal/taskSidePanel.vue";

export default {
    components: {
        Header,
        Nav,
        taskSidePanel,
    },
    data() {
        return {
            selectedTask: "Today",
            selectedTaskId: null,
            searchString: "",
            tasks: [], // Ensure this is initialized as an array (TodayTasks)
            Today: [],
            Pending: [],
            Overdue: [],
            Completed: [],
        };
    },

    mounted() {
        this.fetchTodayTasks();
    },
    methods: {

        //Onclick image function to complete 
        async markAsCompleted(taskId) {
            try {
                // Update task on the server
                await axios.put(
                    `${this.$apiEndPoint}/tasks/updateTask/${taskId}`,
                    {
                        status: "Completed"
                    }
                );
                this.handleTaskComplete(taskId);
                // Optionally provide user feedback here (e.g., toast notification)
            } catch (error) {
                console.error("Error updating task status:", error);
            }
        },

        //Onclick image function to Incomplete
        async markAsInComplete(taskId) {
            try {
                // Update task on the server
                await axios.put(
                    `${this.$apiEndPoint}/tasks/updateTask/${taskId}`,
                    {
                        status: "Pending"
                    }
                );
                this.handleTaskComplete(taskId);
                // Optionally provide user feedback here (e.g., toast notification)
            } catch (error) {
                console.error("Error updating task status:", error);
            }
        },

        //completed tasks updated
        async handleTaskComplete(taskId) {
            try {
                // Find the task in the Completed array
                const completedTaskIndex = this.Completed.findIndex((task) => task.id === taskId);
                if (completedTaskIndex !== -1) {
                    // Task is currently completed, revert it
                    const revertedTask = this.Completed[completedTaskIndex];

                    // Remove from the Completed array
                    this.Completed.splice(completedTaskIndex, 1);

                    // Determine the correct status array based on due date
                    const taskDueDate = new Date(revertedTask.date); // Convert to Date object
                    const today = new Date(); // Get today's date

                    // Add back to the appropriate array
                    if (this.isSameDay(taskDueDate, today)) {
                        // Add to Today
                        this.Today.unshift(revertedTask);
                    } else if (taskDueDate < today) {
                        // Add to Overdue
                        this.Overdue.unshift(revertedTask);
                    } else {
                        // Add to Pending
                        this.Pending.unshift(revertedTask);
                    }

                    // Update the status of the task
                    revertedTask.status = "Pending"; // Set status to Pending

                    // Optionally, make an API call to update the task status on the server
                    await axios.put(
                        `${this.$apiEndPoint}/tasks/updateTask/${revertedTask.id}`,
                        revertedTask
                    );
                } else {
                    // If the task is not found in Completed, mark it as completed
                    const updatedTaskIndex = this.tasks.findIndex((task) => task.id === taskId);
                    if (updatedTaskIndex !== -1) {
                        // Get the task details and update its status
                        const updatedTask = { ...this.tasks[updatedTaskIndex], status: "Completed" };

                        // Remove from the displayed tasks list
                        this.tasks.splice(updatedTaskIndex, 1);

                        // Add the updated task to the Completed array
                        this.Completed.unshift(updatedTask);

                        // Update the displayed tasks based on the current view
                        // this.updateTasksDisplay();

                        // Optionally, make an API call to update the task on the server
                        await axios.put(
                            `${this.$apiEndPoint}/tasks/updateTask/${updatedTask.id}`,
                            updatedTask
                        );
                    } else {
                        console.error("Task not found.");
                    }
                }
            } catch (error) {
                // this.$handleError(error);
                console.error("Error handling task update:", error);
                // Handle any necessary rollback or error feedback here
            }
        },

        //search tasks
        searchLeads() {
            // Check if searchString is empty
            if (!this.searchString.trim()) {
                // If empty, clear the tasks or take appropriate action
                return; // Exit the function early
            }

            let searchData = {
                searchString: this.searchString,
            };

            axios
                .post(`${this.$apiEndPoint}/tasks/searchTask/${this.selectedTask}`, searchData)
                .then((response) => {
                    // Filter out null entries from response data
                    this.tasks = response.data;
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error("Error searching leads:", error);
                });
        },

        // Handle button click and focus without changing the original ChangeTask function
        handleButtonClick(task) {
            this.selectedTask = task; // Track the selected button
            this.ChangeTask(task); // Call the original ChangeTask function
            this.searchLeads();
        },

        taskcompleted(status) {
            console.log(status);
            this.ChangeTask(status);
        },

        addTask(task) {
            const taskDate = new Date(task.date);
            const today = new Date();

            if (taskDate.toDateString() === today.toDateString()) {
                this.Today.push(task);
            } else if (taskDate > today) {
                this.Pending.push(task);
            } else {
                this.Overdue.push(task);
            }
        },


        ChangeTask(ArrayName) {
            this.tasks = [];
            if (ArrayName === "Today") {
                // this.fetchTodayTasks();
                this.tasks = this.Today;
            } else if (ArrayName === "Pending") {
                // this.fetchPendingTasks();

                this.tasks = this.Pending;
            } else if (ArrayName === "Overdue") {
                // this.fetchOverdueTasks();

                this.tasks = this.Overdue;
            } else if (ArrayName === "Completed") {
                // this.fetchCompletedTasks();
                this.tasks = this.Completed;
            }
        },

        handleTaskDeleted(taskId) {
            console.log(`Handling task deletion for task with ID: ${taskId}`);

            // Update the filtered task array
            this.tasks = this.tasks.filter((task) => task.id !== taskId);

            // Remove the task from each original array (Today, Pending, Overdue, Completed)
            this.Today = this.Today.filter((task) => task.id !== taskId);
            this.Pending = this.Pending.filter((task) => task.id !== taskId);
            this.Overdue = this.Overdue.filter((task) => task.id !== taskId);
            this.Completed = this.Completed.filter((task) => task.id !== taskId);

            console.log("Updated tasks and original arrays after deletion:");
            console.log("Today tasks:", this.Today);
            console.log("Pending tasks:", this.Pending);
            console.log("Overdue tasks:", this.Overdue);
            console.log("Completed tasks:", this.Completed);
        },

        async handleTaskUpdatebyId(taskId) {
            console.log("Current tasks array:", this.tasks);

            try {
                // Fetch updated task details from the API
                const response = await axios.get(`${this.$apiEndPoint}/tasks/${taskId}`);
                const updatedTask = response.data;
                console.log("Fetched updated task:", updatedTask);

                // Step 1: Update the task in the main `tasks[]` array
                this.updateTaskInArray(this.tasks, updatedTask);

                // Step 2: Update the task in the specific status arrays
                ["Today", "Pending", "Overdue", "Completed"].forEach((status) => {
                    this.updateTaskInArray(this[status], updatedTask);
                });

                // Step 3: Re-sort and place the task in the appropriate status array
                this.updateTaskStatusArray(updatedTask);

                console.log("Task updated and sorted successfully.");
                this.selectedTaskId = null; // Optionally reset selectedTaskId after the update
            } catch (error) {
                console.error("Error fetching task details:", error);
            }
        },

        // Helper function to update the task in a specific array
        updateTaskInArray(array, updatedTask) {
            const taskIndex = array.findIndex((task) => task.id === updatedTask.id);
            if (taskIndex !== -1) {
                // Replace the existing task details with the updated task details
                array[taskIndex] = { ...array[taskIndex], ...updatedTask }; // Merging changes
                console.log(`Task updated in array at index ${taskIndex}.`);
            }
        },

        // Function to update task's status based on due date
        updateTaskStatusArray(updatedTask) {
            const today = new Date();
            const taskDueDate = new Date(updatedTask.date); // Convert the task date to a Date object

            // Step 1: Remove the task from all arrays
            this.removeTaskFromArray(this.tasks, updatedTask.id);
            ["Today", "Pending", "Overdue", "Completed"].forEach((statusArray) => {
                this.removeTaskFromArray(this[statusArray], updatedTask.id);
            });

            // Step 2: Determine the correct status array and add the task
            if (this.isSameDay(taskDueDate, today)) {
                this.Today.unshift(updatedTask); // Add to the start of the Today array
                console.log(`Task added to Today array.`);
            } else if (taskDueDate < today) {
                this.Overdue.unshift(updatedTask); // Add to the start of the Overdue array
                console.log(`Task added to Overdue array.`);
            } else {
                this.Pending.unshift(updatedTask); // Add to the start of the Pending array
                console.log(`Task added to Pending array.`);
            }
            // Step 3: Add the task back to the main `tasks` array
            // this.tasks.unshift(updatedTask);
            console.log(`Task added back to main tasks array.`);
        },

        // Utility function to remove the task from a specific array
        removeTaskFromArray(array, taskId) {
            const taskIndex = array.findIndex((task) => task.id === taskId);
            if (taskIndex !== -1) {
                array.splice(taskIndex, 1); // Remove task from array
                console.log(`Task removed from array at index ${taskIndex}.`);
            }
        },

        // Utility function to compare if two dates are the same (ignores time)
        isSameDay(date1, date2) {
            return (
                date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate()
            );
        },

        openTaskmodal(id) {
            this.selectedTaskId = id;
        },

        truncateText(text, length) {
            if (text && text.length > length) {
                return text.substring(0, length) + "...";
            }
            return text;
        },
        handleTaskSubmit(task) {
            console.log("Current tasks:", task);
            this.fetchTodayTasks(); // Call the specific method
        },
        getPriorityClass(priority) {
            if (!priority) return ""; // Default case for missing or invalid priority
            priority = String(priority); // Ensure priority is a string
            switch (priority) {
                case "High":
                    return "priority-high";
                case "Medium":
                    return "priority-medium";
                case "Low":
                    return "priority-low";
                default:
                    return "priority-none"; // Optionally handle unexpected values
            }
        },

        getPriorityLabel(priority) {
            if (!priority) return "None"; // Default case for missing or invalid priority
            priority = String(priority); // Ensure priority is a string
            switch (priority) {
                case "1":
                    return "High";
                case "2":
                    return "Medium";
                case "3":
                    return "Low";
                default:
                    return "None"; // Handle unexpected values
            }
        },
        async fetchTodayTasks() {
            try {
                const response = await axios.get(
                    `${this.$apiEndPoint}/tasks/getTasksForUser/Today`
                );
                this.Today = response.data; // Update the tasks array with the response data
                this.tasks = this.Today;
                this.fetchOverdueTasks();
                this.fetchCompletedTasks();
                this.fetchPendingTasks();
            } catch (error) {
                this.handleFetchError(error); // Use the same error handling function
            }
        },

        // Fetch pending tasks
        async fetchPendingTasks() {
            try {
                const response = await axios.get(
                    `${this.$apiEndPoint}/tasks/getTasksForUser/Pending`
                );
                this.Pending = response.data;
                console.log("Pending Tasks:", response.data);
            } catch (error) {
                this.handleFetchError(error);
            }
        },

        // Fetch overdue tasks
        async fetchOverdueTasks() {
            try {
                const response = await axios.get(
                    `${this.$apiEndPoint}/tasks/getTasksForUser/Overdue`
                );
                this.Overdue = response.data;
                console.log("Overdue Tasks:", response.data);
            } catch (error) {
                this.handleFetchError(error);
            }
        },

        // Fetch completed tasks
        async fetchCompletedTasks() {
            try {
                const response = await axios.get(
                    `${this.$apiEndPoint}/tasks/getTasksForUser/Completed`
                );
                this.Completed = response.data;

                console.log("Completed Tasks:", response.data);
            } catch (error) {
                this.handleFetchError(error);
            }
        },
        // Error handling for failed requests
        handleFetchError(error) {
            console.error("Error fetching tasks:", error.message);
            if (error.response) {
                console.error("Response error data:", error.response.data);
                console.error("Response error status:", error.response.status);
            } else if (error.request) {
                console.error("Request error data:", error.request);
            }
        },
    },
};
</script>

<style scoped>
.task-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-left: 5px;
    width: 28px;
    height: 28px;
    font-size: small;
}

.task-buttons-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Ensures it stays on top of other content */
    background-color: #fff;
    /* Set a background color to prevent blending */
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: adds subtle shadow */
}

/* Default button style with no borders */
.btn-m {
    background-color: #f0f0f0;
    position: relative;
    border: none;
    /* Remove all borders */
    padding: 10px 15px;
    /* Adjust padding for better layout */
}

/* Add bottom line animation using the ::after pseudo-element */
.btn-m::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    /* Thickness of the bottom line */
    background-color: #0d6efd;
    /* Line color */
    transition: width 0.3s ease;
    /* Smooth animation */
}

/* Active button style (when clicked) */
.active {
    background-color: #0d6efd;
    /* Change this color to your desired active color */
    color: #0d6efd !important;
}

.active::after {
    width: 100%;
    /* Animate to full width when active */
}

.container-bg {
    background-color: #fcfcfc;
}

.add-task button {
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 20px;
}

.add-task {
    padding-right: 20px;
}

.custom-btn {
    background-color: #f8f8f8;
    color: black;
    border: none;
    box-shadow: 0 2px 4px rgba(13, 13, 13, 0.2);
    transition: all 0.3s ease;
}

.custom-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
}

.search-img {
    width: 15px;
    height: 15px;
}

.filter-img {
    width: 17px;
    height: 13px;
}

.search:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #141414;
    font-size: small;
}

.btn-group {
    padding-left: 1.5%;
}

.subtask-icon {
    width: 15px;
    height: 13px;
    margin-top: none;
    padding-left: 3px;
}

.attachment-icon {
    width: 15px;
    height: 13px;
    margin-bottom: none;
    padding-left: 3px;
}

body,
.content-Container {
    margin: 0;
    padding: 0;
}

.task-list {
    margin-top: 20px;
}

.task-list ul {
    list-style-type: none;
    padding: 0;
}

.task-list li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.task-list h5 {
    margin: 0;
    font-size: 1.2em;
}

.task-list p {
    margin: 5px 0;
}

.taskName {
    border: none;
    background-color: inherit;
}

.solid-line {
    border: 1.5px solid rgb(0, 0, 0);
    margin: 10px 0;
}

.line-break {
    border: 1.5px solid rgb(95, 95, 95);
    width: 98%;
    display: block;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px;
    /* Centers the element horizontally */
}

.sub-task {
    background-color: #ff69b4;
    width: 7%;
    border-color: #ff69b4;
    color: white;
    padding: 6px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    /* Center the text and icon vertically */
    justify-content: space-between;
    /* Ensure the text and icon are properly spaced */
    gap: 5px;
    /* Space between the text and the icon */
    transition: background-color 0.3s ease;
    /* Smooth transition on hover */
}

@media (max-width: 750px) {
    .task-card {
        background-color: #ffffff;
        border-width: 1;
        border-color: #ff1493;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 15px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        flex-basis: calc(25% - 20px);
        box-sizing: border-box;
        margin-bottom: 15px;
        width: 100%;
    }

    .task-card h6 {
        font-size: 16px;
    }

    .task-card .task-desc,
    .task-card p {
        font-size: 12px;
    }
}

.sub-task:hover {
    background-color: #ff1493;
    /* Darker pink on hover */
}

.plus-icon {
    font-size: 20px;
    /* Adjust the size of the icon */
    font-weight: bold;
    color: white;
    /* White color for the icon */
}

.activity {
    background-color: white;
    color: black;
    width: auto;
}

.task-buttons {
    display: flex;
    margin-left: 10px;
    gap: 10px;
    font-size: 14px;
    flex-wrap: nowrap;
    /* Prevent wrapping */
    align-items: center;

    /* Center vertically */
}

.task-buttons button {
    background-color: transparent;
    /* Button background color */
    color: rgb(10, 1, 1);
    /* Button text color */
    font-size: 14px;

    /* Border with opacity */
    padding: 10px 20px;
    /* Button padding adjusted to previous size */
    border-radius: 4px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    white-space: nowrap;
    /* Prevent text wrapping */
    display: flex;
    /* Align icon and text */
    align-items: center;
    /* Center icon and text vertically */
    gap: 5px;
    /* Space between icon and text */
    transition: background-color 0.3s ease;
    /* Smooth transition on hover */
    height: 35px;
}

.red-icon {
    background-color: #f92727;
}

.green-icon {
    background-color: #38b525;
}

.yellow-icon {
    background-color: #f78318;
}

.blue-icon {
    background-color: #a83bce;
}

.image-size {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-top: -10px;
    margin-right: 10px;
}

/* Side Panel */
.side-panel {
    position: fixed;
    top: 8.5%;
    bottom: 7%;
    right: -100%;
    /* Initially hidden off-screen */
    width: 35%;
    height: 90%;
    margin-bottom: 10%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    transition: right 0.7s ease;
    z-index: 1000;
    border-radius: 5px 0 0 5px;
    /* Rounded corners on the left */
}

.side-panel.active {
    right: 0;
    /* Slide in from the right */
    margin-bottom: 300px;
}

.panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-icon {
    font-size: 20px;
    cursor: pointer;
}

.task-details {
    margin-top: 20px;
}

.form-control,
.form-control-file,
.form-check-input,
.form-check-label {
    display: block;
    margin: 5px 0;
    width: 100%;
}

.form-control {
    padding: 5px;
}

.btn {
    display: block;
    width: 100%;
    margin: 5px 0;
    height: fit-content;
    font-size: small;
}

.save-button {
    width: 28%;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/* Date and Priority in One Line */
.form-row {
    display: flex;
    gap: 20px;
    /* Adjust the gap between date and priority fields */
}

.form-group {
    flex: 1;
    /* Allow the form groups to take equal space */
}

.subtasks-activities {
    margin-top: 20px;
    display: flex;
    gap: 40%;
    flex-direction: row;
    border-radius: 6px;
}

.cards {
    width: 100%;
    max-height: 90%;
    overflow-y: scroll;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    /* Allow wrapping to next line */
    /* Space between cards */
    background-color: #f8f8f8;
    /* Light grey background */
    justify-content: flex-start;
    box-sizing: border-box;
    align-items: stretch;
    /* Ensures all items stretch to the same height */
}

.cards::-webkit-scrollbar {
    width: 8px;
    /* Decrease scrollbar width */
}

.cards::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Change scrollbar thumb color */
    border-radius: 10px;
    /* Round the scrollbar edges */
}

.cards::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darken scrollbar thumb on hover */
}

.cards::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Change scrollbar track color */
}

.task-card {
    cursor: pointer;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Keeps equal space distribution */
    min-height: 158px;
    /* Set a minimum height to avoid shrinking */
}

.task-title,
.task-description {
    min-height: 20px;
    /* Adjust this value as needed */
    margin-bottom: 10px;
}

.task-title::before,
.task-description::before {
    content: "\00a0";
    /* Non-breaking space to ensure the block always takes up space */
    display: block;
}

.task-card:hover {
    background-color: rgb(255, 255, 255);
    /* Slightly darker grey on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    /* Elevate the shadow on hover */
    transform: translateY(-5px);
    /* Slight upward movement on hover */
}

/* Task name styling */
.task-card h6 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 8px 0;
}

/* Task details styling */
.task-card p {
    font-size: 0.9rem;
    color: #555555;
    margin-bottom: 8px;
}

/* Default button styling */
.task-card button {
    border: none;
    color: white;
    font-size: 0.9rem;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Priority-specific button styling */
.priority-low {
    background-color: lightpink;
}

.priority-medium {
    background-color: rgb(255, 106, 0);
}

.priority-high {
    background-color: rgb(228, 56, 56);
}

/* Hover effect for the priority button */
.task-card button:hover {
    opacity: 0.8;
}

.iconDiv {
    margin-top: -1%;
}

.tickIcon {
    width: 15px;
    height: 15px;
}

.tick-Icon {
    width: 15px;
    height: 15px;
}

.dotDiv {
    margin-top: -2%;
    padding-right: 10px;
}

.dotIcon {
    width: 15px;
    height: 5px;
}

@media (max-width: 480px) {
    .task-card {
        flex-basis: calc(100% - 50px);
    }

    .containerChild {
        padding: 5px;
    }

    .task-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .side-panel {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        padding: 10px;
        margin-bottom: 0;
        border-radius: 0;
    }

    .save-button {
        width: 60%;
        bottom: 10px;
        right: 10px;
    }
}
</style>
