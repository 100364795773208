<template>
  <AddOnSiteCategoryModal @fetchCategories="fetchCategories" />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="contractorDetails">
        <div class="contractorDetailsHeader">
          <section class="headerTitle mainHeading">New Contractor Detail</section>
          <section>
            <img
              src="../../../assets/icons/cancel.png"
              alt="Cancel Icon"
              class="headerIcon"
              @click="goBack"
            />
          </section>
        </div>
        <div class="contractorPrimaryDetails p-3">
          <p class="row redSubHeading">Primary Contact Details</p>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contractorName" class="form-label">Contractor Name</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contractorName"
                  v-model="form.name"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contractorID" class="form-label">Contractor ID</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contractorID"
                  v-model="form.contractorID"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactNumber" class="form-label">Contact number</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contactNo"
                  v-model="form.contactNo"
                />
              </div>
            </div>
          </div>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="emailID" class="form-label">Email ID</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="emailID"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="category" class="form-label">Category</label>
              </div>
              <div class="col-md-10 d-flex align-items-center select-image-wrapper">
                <select
                  class="form-control no-border"
                  id="category"
                  v-model="form.contractorCategory"
                >
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.categoryName }}
                  </option>
                </select>

                <img
                  src="../../../assets/icons/addImage.png"
                  class="icon"
                  data-bs-toggle="modal"
                  data-bs-target="#AddOnSiteCategory"
                  id="AddOnSiteCategory"
                  alt="icon"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="address" class="form-label">Address</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  v-model="form.address"
                />
              </div>
            </div>
          </div>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="city" class="form-label">City</label>
              </div>
              <div class="col-md-10">
                <input type="text" class="form-control" id="city" v-model="form.city" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="state" class="form-label">State</label>
              </div>
              <div class="col-md-10">
                <input type="text" class="form-control" id="state" v-model="form.state" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="pinCode" class="form-label">Pin Code</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="pinCode"
                  v-model="form.pinCode"
                />
              </div>
            </div>
          </div>
          <hr />
          <p class="row redSubHeading">Primary Contact Details</p>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactPerson" class="form-label">Contact Person</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contactPerson"
                  v-model="form.secondaryContactName"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactEmailID" class="form-label">Email ID</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contactEmailID"
                  v-model="form.secondaryContactEmail"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactNumber" class="form-label">Contact number</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contactNumber"
                  v-model="form.secondaryContactNumber"
                />
              </div>
            </div>
          </div>
          <hr />
          <p class="row redSubHeading mt-2">Bank Details</p>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="beneficiaryName" class="form-label">Beneficiary Name</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="beneficiaryName"
                  v-model="bankDetails.beneficiaryName"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="accountNumber" class="form-label">Account Number</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="accountNumber"
                  v-model="bankDetails.accountNumber"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="ifscCode" class="form-label">IFSC Code</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="ifscCode"
                  v-model="bankDetails.IFSC"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="branchName" class="form-label">Branch Name</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="branchName"
                  v-model="bankDetails.bankName"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="bankAddress" class="form-label">Bank Address</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="bankAddress"
                  v-model="bankDetails.branchAddress"
                />
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <button class="btn btn-sm btn-primary" @click="saveForm">Save</button>
          <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import AddOnSiteCategoryModal from "@/components/Modal/AddOnSiteCategoryModal.vue";

export default {
  name: "CreateContractor",
  components: {
    Header,
    Nav,
    AddOnSiteCategoryModal,
  },
  data() {
    return {
      form: {
        name: "",
        contactNo: "",
        email: "",
        contractorCategory: null,
        address: "",
        city: "",
        state: "",
        pinCode: "",
        secondaryContactName: "",
        secondaryContactEmail: "",
        secondaryContactNumber: "",
      },
      categories: [],
      bankDetails: {
        beneficiaryName: "",
        accountName: "",
        accountNumber: "",
        bankName: " ",
        IFSC: "",
        branchAddress: " ",
        entityId: null,
        entityName: "Contractor",
        id: null,
      },
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteCategory`);
        this.categories = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async saveForm() {
      const contractorDetails = {
        ...this.form,
        contractorCategory: [this.form.contractorCategory],
      };
      console.log(contractorDetails);
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/onSiteContractor/`,
          contractorDetails
        );
        console.log("Contractor details created:", response.data);
        this.createBankDetails(response.data.createNewContractor.id);
        this.goBack();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async createBankDetails(contractorID) {
      try {
        this.bankDetails.entityId = contractorID;
        const response = await axios.post(
          `${this.$apiEndPoint}/bankDetails`,
          this.bankDetails
        );
        console.log("Bank details created:", response.data);
        this.goBack();
      } catch (error) {
        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1);

      // Navigate back one step in the history stack
    },
  },
};
</script>

<style scoped>
.redSubHeading {
  color: #ff6b00;
  font-size: 16px;
  margin-left: 3px;
}

.contractorDetails {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.contractorDetailsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 2px solid #000000;
}

.contractorPrimaryDetails {
  margin-bottom: 30px;
}

.headerTitle {
  flex: 1;
  text-align: left;
  padding: 10px;
}

.headerIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

label,
input {
  font-size: 14px;
}

.footer {
  position: fixed;
  bottom: 12px;
  border-radius: 0 0 15px 15px;

  width: calc(100% - 74px);
  background: white;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  /* Box shadow at the top */
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
.select-image-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.no-border {
  border: none;
  box-shadow: none;
  flex: 1;
}

.icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .headerTitle {
    margin-bottom: 10px;
  }

  .footer {
    position: fixed;
    bottom: 0px;
    border-radius: 0 0 15px 15px;
    width: calc(100%);
    background: white;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    /* Box shadow at the top */
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .contractorPrimaryDetails {
    margin-bottom: 120px;
  }

  .headerIcon {
    align-self: flex-end;
  }
}
</style>
