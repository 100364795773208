<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request">
          <div class="row header mb-0">
            <div class="d-flex justify-content-between">
              <h5 class="ms-3 text-decoration-underline">Purchase Request</h5>
              <div class="close">
                <img
                  src="../../../assets/icons/cancel.png"
                  alt="Close"
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                />
              </div>
            </div>
          </div>

          <div class="row ms-2">
            <div class="col-md-7 mb-3 d-flex align-items-center">
              <label for="projectName" class="col-md-4 col-sm-4 col-form-label"
                >Project Name:</label
              >
              <div class="col-md-6 col-sm-6">
                <select
                  class="form-control border-black"
                  id="category"
                  v-model="form.projectId"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.projectId"
                    :value="Project.projectId"
                  >
                    {{ Project.projectName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4 mb-3 d-flex align-items-center">
              <label for="date" class="col-md-4 col-sm-4 col-form-label">Date:</label>
              <div class="col-md-8 col-sm-8">
                <input type="date" class="form-control border-black w-100" id="date" />
              </div>
            </div>
          </div>

          <div class="table-container small-wrapper">
            <table class="small-table table table-bordered">
              <thead>
                <tr class="table-header-heading">
                  <th>SL.No</th>
                  <th class="text-start">Product Name</th>
                  <th class="text-start">Product Description</th>
                  <th>Unit Type</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <span>
                      <input
                        ref="productNameInputs"
                        class="w-100"
                        v-model="row.productName"
                        @focus="activeRow = index"
                        @input="searchProduct(row.productName, index)"
                        @change="fillProductDetails(index)"
                      />
                      <ul
                        v-if="row.filteredProducts.length > 0 && activeRow === index"
                        class="dropdown-menu show productDropDown"
                      >
                        <li
                          v-for="product in row.filteredProducts"
                          :key="product.id"
                          @click="selectProduct(product, index)"
                        >
                          {{ product.productName }}
                        </li>
                      </ul>
                    </span>
                  </td>

                  <td class="description">
                    <input
                      type="text"
                      v-model="row.description"
                      class="form-control text-start"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control border-black"
                      v-model="row.unitType"
                      @focus="activeRow = index"
                      @input="filterUnitTypes(row.unitType, index)"
                    />
                    <!-- Show dropdown only for the current active row -->
                    <ul
                      v-if="row.filteredUnitTypes.length > 0 && activeRow === index"
                      class="dropdown-menu unitDropDown show"
                    >
                      <li
                        v-for="unit in row.filteredUnitTypes"
                        :key="unit.value"
                        @click="selectUnitType(unit, index)"
                      >
                        {{ unit.label }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <input
                      type="number"
                      v-model="row.quantity"
                      class="form-control quantity-input"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="add-item btn btn-sm btn-primary" @click="addNewTask">
              Add Item +
            </button>
          </div>
          <div class="row">
            <div class="footerPuchaseRequest">
              <button
                class="save-button btn btn-primary"
                :disabled="!form.projectId"
                @click="saveAsDraft"
              >
                Save
              </button>
              <button class="save-button btn btn-secondary" @click="goBack">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";

export default {
  name: "PurchaseRequestDetails",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      activeRow: null, // To track the current row being interacted with
      Projects: [],
      form: {
        projectId: "",
        priority: "Low",
        comments: " ",
      },
      rows: [
        {
          productName: "",
          description: "",
          unitType: "",
          quantity: 0,
          filteredProducts: [], // Track filtered products per row
          filteredUnitTypes: [], // Track filtered unit types per row
        },
      ],
      unitTypes: [
        { label: "Millimeter - mm", value: "mm" },
        { label: "Centimeter - cm", value: "cm" },
        { label: "Meter - m", value: "m" },
        { label: "Inch - In", value: "In" },
        { label: "Foot - ft", value: "ft" },
        { label: "Square Millimeter - sq.mm", value: "sq.mm" },
        { label: "Square Centimeter - sq.cm", value: "sq.cm" },
        { label: "Square Meter - sq.m", value: "sq.m" },
        { label: "Square Inch - sq.In", value: "sq.In" },
        { label: "Square Foot - sq.ft", value: "sq.ft" },
        { label: "Numbers - No's", value: "No's" },
        { label: "Pieces - pcs", value: "pcs" },
        { label: "Box - box", value: "box" },
        { label: "Pair - Pr", value: "Pr" },
        { label: "Set - Set", value: "Set" },
        { label: "Unit - unit", value: "unit" },
        { label: "Running Feet - RFT", value: "RFT" },
        { label: "Running Meter - RMT", value: "RMT" },
        { label: "Kilogram - Kg", value: "Kg" },
        { label: "Gram - gm", value: "gm" },
      ],
    };
  },
  mounted() {
    this.fetchProjects();
    if (this.$route.params.ProjectId) {
      this.form.projectId = this.$route.params.ProjectId;
    }
  },
  methods: {
    selectProduct(product, index) {
      // Set the selected product details in the row
      this.rows[index].productName = product.productName;
      this.rows[index].description = product.description;
      this.rows[index].unitType = product.unitType;

      // Clear the filtered products list after selection
      this.rows[index].filteredProducts = [];

      // Optionally focus on the next input field after selecting the product
      this.$nextTick(() => {
        this.$refs.productNameInputs[index + 1]?.focus();
      });
    },
    goBack() {
      let activetab = "purchaseRequest";
      const department =
        this.$route.params.moduleName === "All"
          ? "Procurement"
          : this.$route.params.moduleName;
      if (this.$route.params.ProjectId && department && this.$route.params.Id) {
        this.$router.push({
          name: "ProcurementProjectProfile",
          params: { activeTab: activetab, Id: this.$route.params.Id },
        });
      } else {
        this.$router.go(-1);
      }
    },
    fetchProjects() {
      const department =
        this.$route.params.moduleName === "All"
          ? "Procurement"
          : this.$route.params.moduleName;
      console.log(department);
      axios
        .get(`${this.$apiEndPoint}/purchaseRequest/getProjectToCreatePr/${department}`)
        .then((response) => {
          this.Projects = response.data;
          console.log(this.Projects);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchProduct(searchString, index) {
      if (searchString.length > 0) {
        axios
          .post(`${this.$apiEndPoint}/productList/searchProducts`, { searchString })
          .then((response) => {
            this.rows[index].filteredProducts = response.data; // Update the filtered products for the specific row
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        this.rows[index].filteredProducts = []; // Clear the product list for that row if no search string
      }
    },
    fillProductDetails(index) {
      const product = this.rows[index].filteredProducts.find(
        (p) => p.productName === this.rows[index].productName
      );
      if (product) {
        this.rows[index].description = product.description;
        this.rows[index].unitType = product.unitType;
      }
    },
    saveAsDraft() {
      // Determine the department based on the moduleName
      const department =
        this.$route.params.moduleName === "All"
          ? "Procurement"
          : this.$route.params.moduleName;

      const data = {
        projectId: this.form.projectId,
        department: department, // Use the determined department
        comments: this.form.comments,
        lineitems: this.rows.map((row) => ({
          productName: row.productName,
          description: row.description,
          unitType: row.unitType,
          quantity: row.quantity,
          category: row.category,
        })),
      };

      axios
        .post(`${this.$apiEndPoint}/purchaseRequest/`, data)
        .then((response) => {
          console.log(response.data);
          this.$toast("Purchase Request created successfully.", "success");
          this.goBack();
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    addNewTask() {
      if (this.rows.length > 0 && !this.rows[this.rows.length - 1].productName) {
        this.$refs.productNameInputs[this.rows.length - 1].focus();
        return;
      }
      this.rows.push({
        productName: "",
        description: "",
        unitType: "",
        quantity: 0,
        filteredProducts: [], // Initialize filtered products for the new row
        filteredUnitTypes: [], // Initialize filtered unit types for the new row
      });
      this.$nextTick(() => {
        this.$refs.productNameInputs[this.rows.length - 1].focus();
      });
    },
    filterUnitTypes(searchString, index) {
      if (searchString.length > 0) {
        this.rows[index].filteredUnitTypes = this.unitTypes.filter((unit) =>
          unit.value.toLowerCase().includes(searchString.toLowerCase())
        );
      } else {
        this.rows[index].filteredUnitTypes = []; // Clear the unit type list for that row
      }
    },
    selectUnitType(unit, index) {
      this.rows[index].unitType = unit.value;
      this.rows[index].filteredUnitTypes = []; // Clear the dropdown after selection
    },
  },
};
</script>

<style scoped>
.purchase-request {
  padding: 10px 10px;
  height: 100%;
  width: 100%;
}
.purchase-request .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  width: 100%;
  margin-top: 20px;
  padding: 0px 20px;
  overflow-x: scroll;
  position: relative;
}

.small-wrapper {
  width: 100%;
  height: 70vh;
  /* Set a fixed height to make the table vertically scrollable */
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 100px;
  padding-bottom: 200px;
}

.small-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  border-collapse: collapse;
  /* Ensure the table layout is fixed and borders collapse */
}

.table-header-heading th {
  background-color: #ccc;
  text-align: center;
  position: sticky;
  top: 0;
}

.table-header-heading th:nth-child(1) {
  width: 5%;
  text-align: center;
}
.table-header-heading th:nth-child(2) {
  width: 20%;
}

.table-header-heading th:nth-child(4),
.table-header-heading th:nth-child(5),
.table-header-heading th:nth-child(6) {
  width: 10%;
}

.table-header-heading th:nth-child(3) {
  width: 25%;
}
tbody td {
  padding: 0px 5px;
  margin: 0;
  text-align: left;
}
table td:nth-child(1) {
  text-align: center;
}

input {
  border: none;
  padding: 5px;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest {
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 74px);
}

.footerPuchaseRequest .btn {
  margin: 5px;
}

.input-bg {
  background-color: #fff;
}

.description {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-input {
  width: 100%;
  text-align: end;
}
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
#date {
  border: 1px solid #000000;
}
/* Dropdown Menu Container */
.dropdown-menu {
  position: absolute;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  margin-top: 5px;
  width: max-content;
  max-height: 200px; /* Max height with scroll */
  overflow-y: auto;
}

/* Dropdown Menu Items */
.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
  list-style-type: none; /* Removes bullet points */
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.2s ease-in-out;
}

/* Hover Effect for Menu Items */
.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

/* Selected Item Style */
.dropdown-menu li.selected {
  background-color: #007bff;
  color: white;
}

/* Last item doesn't need border */
.dropdown-menu li:last-child {
  border-bottom: none;
}

/* Empty state when no results are found */
.no-results {
  padding: 8px 12px;
  color: #999;
  text-align: center;
}

@media (max-width: 768px) {
  .purchase-request {
    padding: 5px 10px;
    height: 50%;
    width: 100%;
  }
  .d-flex.align-items-center {
    display: block !important;
  }
  .d-flex.align-items-center label,
  .d-flex.align-items-center .form-control {
    width: 100% !important;
  }
  .small-table {
    display: block;
    overflow-x: auto;
  }

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  label {
    flex: 0.5;
    white-space: nowrap;
  }
  input {
    flex: 0.5;
  }
}
</style>
