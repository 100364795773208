<template>
  <div
    class="modal fade"
    id="OnsiteEntriesModal"
    tabindex="-1"
    aria-labelledby="OnsiteEntriesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="min-width: 60%; overflow: auto"
    >
      <div class="modal-content">
        <div class="modal-header" style="margin-bottom: 0">
          <div class="d-flex justify-content-between align-items-center w-100">
            <h6 class="modal-title">Outward Entry</h6>
            <div class="d-flex align-items-center">
              <label for="date" class="col-form-label text-nowrap">date-</label>
              <input type="date" class="form-control p-1 ms-3" v-model="form.date" />
            </div>
            <div class="d-flex align-items-center">
              <label for="projectName" class="col-form-label text-nowrap"
                >To Store -</label
              >
              <select
                class="form-control p-1 ms-3 w-100"
                id="category"
                v-model="form.toStore"
              >
                <option v-for="Project in Projects" :key="Project.id" :value="Project.id">
                  {{ Project.storeName }}
                </option>
              </select>
            </div>
            <img
              src="../../assets/icons/cancel.png"
              alt="Close"
              style="width: 20px; height: 20px; cursor: pointer"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow: auto">
          <table
            class="table files-table table-responsive"
            style="background-color: #ccc"
          >
            <caption class="d-none">
              Product Details
            </caption>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Unit Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in Rows"
                :key="index"
                @mouseover="deleteItems = true"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <input
                    class="w-100 form-control"
                    list="products"
                    v-model="row.productName"
                    @change="fillProductDetails(index)"
                    :ref="'productNameInput' + index"
                  />
                  <datalist id="products" style="background-color: whitesmoke">
                    <option
                      v-for="product in Products"
                      :key="product.id"
                      :value="product.productName"
                    ></option>
                  </datalist>
                </td>
                <td>
                  <input type="number" class="form-control" v-model="row.sentQuantity" />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="row.unitType"
                    readOnly
                  />
                </td>
                <td>
                  <img
                    v-if="deleteItems"
                    style="width: 13px; height: 13px; cursor: not-allowed"
                    src="../../assets/icons/redDelete.png"
                    data-toggle="tooltip"
                    data-placement="top-start"
                    title="Delete"
                    alt="Delete"
                    @click="removeRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="addRow" class="btn btn-sm btn-primary">Add Row</button>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="browse"
            @click="CreateOutWordEntry"
            data-bs-dismiss="modal"
          >
            Save
          </button>
          <button type="button" class="cancelButton" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnsiteEntriesModal",
  props: {
    FromStore: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      deleteItems: false,
      Projects: [],
      Products: [],
      Rows: [{ productName: "", sentQuantity: 0, unitType: "", productId: "" }],
      form: {
        date: null,
        fromStore: null,
        toStore: null,
        status: "Sent",
        Rows: [],
      },
    };
  },
  mounted() {
    this.fetchProjects();
    this.fetchProductList();
  },

  watch: {
    FromStore(newValue) {
      this.localQuotationId = newValue;
      console.log(this.localQuotationId);
      this.form.fromStore = this.localQuotationId;
      console.log(this.form.fromStore);
    },
  },
  methods: {
    fetchProductList() {
      axios
        .get(`${this.$apiEndPoint}/productList`)
        .then((response) => {
          this.Products = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fillProductDetails(index) {
      const product = this.Products.find(
        (p) => p.productName === this.Rows[index].productName
      );
      if (product) {
        this.Rows[index].unitType = product.unitType;
        this.Rows[index].productId = product.id;
      }
    },
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/store/getStores`)
        .then((response) => {
          this.Projects = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    addRow() {
      if (this.Rows.length > 0) {
        const lastRow = this.Rows[this.Rows.length - 1];
        if (lastRow.productName.trim() !== "") {
          this.Rows.push({ productName: "", sentQuantity: 0, unitType: "" });
        } else {
          this.$nextTick(() => {
            this.$refs[`productNameInput${this.Rows.length - 1}`][0].focus();
          });
        }
      } else {
        this.Rows.push({ productName: "", sentQuantity: 0, unitType: "" });
      }
    },
    removeRow(index) {
      this.Rows.splice(index, 1);
    },
    CreateOutWordEntry() {
      this.form.Rows = [...this.Rows];
      console.log(this.form);
      // Send this.form to the server
      axios
        .post(`${this.$apiEndPoint}/inwardOutwardEntries`, this.form)
        .then((response) => {
          this.$emit("fetchOutwardEntries");

          console.log("Entry created successfully:", response.data);
          this.form = {};
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.selected-employee {
  margin-bottom: 5px;
}
.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}
input,
select {
  outline: none !important;
  padding: 2px !important;
}
select {
  height: fit-content;
}
.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table .sticky-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(2),
.files-table td:nth-child(2) {
  width: 60%;
  text-align: left;
}
</style>
