<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="section-wrapper">
          <div class="section blackHeadSection">
            <div class="row justify-content-between p-2">
              <div class="col-auto p-4" style="font-size: 20px">
                {{ this.BoardHeader }} Dashboard
              </div>
              <div class="col-auto text-center mt-5">
                <p class="welcome m-0">Hi {{ fullName }}, {{ greeting }}</p>
              </div>
              <div class="col-auto p-4 text-left">
                <p class="m-0">{{ currentDay }}</p>
                <p class="m-0">{{ formattedDate }}</p>
              </div>
            </div>
            <div
              class="d-flex justify-content-end"
              style="display: flex; margin-left: 3%"
            >
              <div
                class="d-flex justify-content-end"
                style="margin-right: 3.4%"
                v-if="showExecative"
              >
                <p class="mt-1">View :</p>
                <select
                  style="
                    margin-left: 3px;
                    font-size: 12px;
                    height: 30px;
                    width: 150px;
                    cursor: pointer;
                    :focus {
                      outline: none;
                      box-shadow: none;
                    }
                  "
                  class="form-select"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                >
                  <option value="All" selected>Team</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="section middleHeadSection"></div>
          <component
            :is="currentDashboard"
            :SelectedEmployeeId="selectedEmployeeId"
            @fetchTasks="fetchTasks()"
            @task-selected="onTaskSelected"
            @PR-selected="onPrSelected"
            @PI-selected="onPISelected"
          />
        </div>
      </div>
    </div>
    <taskSidePanel
      :selectedTaskId="selectedTaskId"
      @updateTaskById="fetchTasks()"
      @task-deleted="fetchTasks()"
    />
    <PRDetailsModal :PurchaseRequestId="selectedPRId" ref="PRDetailsModal" />
    <PODetailsModal :PurchaseOrderId="selectedPIId" ref="PODetailsModal" />
  </div>
</template>

<script>
import axios from "axios";
import PODetailsModal from "./Modal/PODetailsModal.vue";
import PRDetailsModal from "./Modal/PRDetailsModal.vue";
import taskSidePanel from "./Modal/taskSidePanel.vue";
import DesignDashBoard from "./Design/DesignDashBoard.vue";
import SalesDashBoard from "./Sales/SalesDashboard.vue";
import OnsiteDashboard from "./Onsite/OnsiteDashboard.vue";
import ProcurementDashboard from "./Procurement/ProcurementDashboard.vue";

import Header from "./Header.vue";
import Nav from "./Nav.vue";

export default {
  name: "GlobalDashboard",
  components: {
    Header,
    Nav,
    SalesDashBoard,
    DesignDashBoard,
    OnsiteDashboard,
    ProcurementDashboard,
    taskSidePanel,
    PRDetailsModal,
    PODetailsModal,
  },
  data() {
    return {
      selectedPIId: null,
      selectedPRId: null,
      selectedTaskId: null,
      BoardHeader: null,
      showExecative: false,
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      employees: [],
      currentDashboard: null,
      currentDay: "",
      formattedDate: "",
      fullName: "",
      greeting: "",
      employeeRoles: [],
    };
  },
  mounted() {
    this.setCurrentDate();
    let user = sessionStorage.getItem("user-info");
    if (user) {
      try {
        const userData = JSON.parse(user);
        const userObject = userData.user;
        this.fullName = userObject.fullname;
        this.greeting = this.getGreeting();
        this.employeeRoles = userData.employeeRoles;
      } catch (error) {
        this.$handleError(error);
      }
    }

    this.showDashboard(this.$route.params.DashboardType);
    console.log(this.$route.params.DashboardType);
  },
  watch: {
    "$route.params.DashboardType": "updateDashboard",
  },
  computed: {
    priorityButtonClass() {
      return (task) => {
        // Determine which class to apply based on data.priority
        switch (task.priority) {
          case "Low":
            return "btn-priority-low"; // Define this class in your CSS
          case "Medium":
            return "btn-priority-medium"; // Define this class in your CSS
          case "High":
            return "btn-priority-high"; // Define this class in your CSS
          default:
            return ""; // Default class if no priority is set or unknown priority
        }
      };
    },
    titleBackgroundColorClass() {
      return (priority) => {
        if (priority === "Low") {
          return "priority-low";
        } else if (priority === "Medium") {
          return "priority-medium";
        } else if (priority === "High") {
          return "priority-high";
        }
        return "";
      };
    },
  },
  methods: {
    fetchTasks() {
      // Trigger the fetch task event to the active dashboard component
      this.$emit("fetchTasks");
      console.log("Home");
    },
    onPISelected(selectedPIId) {
      this.selectedPIId = selectedPIId;
    },
    onTaskSelected(taskId) {
      // Update the selected task ID
      this.selectedTaskId = taskId;
      console.log(this.selectedTaskId);
    },
    onPrSelected(SelectedPRId) {
      this.selectedPRId = SelectedPRId;
      console.log(this.selectedPRId);
    },
    updateDashboard(newDashboardType) {
      this.showDashboard(newDashboardType);
    },
    setSelectedEmployee() {
      console.log("Selected employee ID:", this.selectedEmployeeId);
    },
    async fetchEmployees(Team) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/${Team}`);
        this.employees = response.data;
        console.log(this.employees);
      } catch (error) {
        this.$handleError(error);
      }
    },
    checkExecutiveView(Team) {
      this.showExecative = this.employeeRoles.some(
        (role) => role.team === Team && role.role === "Manager"
      );
    },
    showDashboard(dashboardType) {
      if (dashboardType === "SalesDashBoard") {
        this.BoardHeader = "Sales";
        this.currentDashboard = "SalesDashBoard";

        this.fetchEmployees("sales");
        this.checkExecutiveView("Sales");
      } else if (dashboardType === "DesignDashBoard") {
        this.BoardHeader = "Design";
        this.currentDashboard = "DesignDashBoard";
        this.fetchEmployees("Designing");
        this.checkExecutiveView("Designing");
      } else if (dashboardType === "OnsiteDashboard") {
        this.BoardHeader = "Onsite";
        this.currentDashboard = "OnsiteDashboard";
        this.fetchEmployees("Onsite");
        this.checkExecutiveView("Onsite");
      } else if (dashboardType === "ProcurementDashboard") {
        this.BoardHeader = "Procurement";
        this.currentDashboard = "ProcurementDashboard";
        this.fetchEmployees("Procurement");
        this.checkExecutiveView("Procurement");
      }
    },
    setCurrentDate() {
      const date = new Date();
      this.currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        date
      );
      const options = { day: "numeric", month: "long", year: "numeric" };
      this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    },
    getGreeting() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      if (currentHour < 12) {
        return "Good Morning";
      } else if (currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    isSalesTeam() {
      return this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Sales";
    },
    isDesignTeam() {
      return this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Design";
    },
    isOnsiteTeam() {
      return this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Onsite";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/inter");
.HomePageDetails {
  height: 100%;
  width: 100%;
}

.HomePageDetails header {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  text-align: center;
}

.welcomeDateTime {
  font-size: 15.4px;
  font-weight: bold;
  padding-top: 30px;
}

.welcome {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  max-width: 100%;
  position: relative;
  background-color: #f4f4f4;
  /* Relative positioning for the wrapper */
}

.blackHeadSection {
  background-color: #292929;
  height: 32%;
  color: #ffffff;
}

.middleHeadSection {
  height: 14%;
  background-color: #eff1f7;
}

.WhiteHeadSection {
  background-color: #eff1f7;
  height: 54%;
  margin-left: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  /* Hide the horizontal scrollbar */
}

.roles-container {
  display: flex;
  flex-wrap: wrap;
  color: black;
  margin-bottom: 10px;
  font-size: 12px;
}

.role-tab {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #f8f8f8;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.role-tab:hover {
  background-color: #e0e0e0;
}

.role-name {
  display: inline-block;
}

.role-tab:hover {
  background-color: #f0f0f0;
}

.role-tab.active {
  background-color: #007bff;
  color: #fff;
}

.role-name {
  font-weight: bold;
}
</style>
