<template>
  <ProcurementProductList
    @fetchData="fetchProductsFromAPI(this.Products.length - 1)"
    :selectedTargetId="selectedTargetId"
  />
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request">
          <div class="row header mb-2">
            <div class="d-flex justify-content-between">
              <h5 class="ms-3 text-decoration-underline">Purchase Order</h5>
              <div class="close">
                <img
                  src="../../../assets/icons/cancel.png"
                  alt=""
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                />
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <div class="col-md-4 mb-3 d-flex align-items-center">
              <label for="projectName" class="col-md-4 col-sm-4 col-form-label"
                >Project Name:</label
              >
              <div class="col-md-8 col-sm-8">
                <select
                  class="form-control border-black form-select"
                  id="category"
                  v-model="form.projectId"
                  @click="fetchPurchaseRequestById(form.projectId)"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.projectId"
                    :value="Project.projectId"
                  >
                    {{ Project.projectName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 mb-3 d-flex align-items-center">
              <label for="priority" class="col-md-4 col-sm-4 col-form-label"
                >Reference:</label
              >
              <div class="col-md-8 col-sm-8">
                <select
                  class="form-control border-black form-select"
                  id="category"
                  v-model="form.prId"
                >
                  <option value="null">Direct PO</option>
                  <option
                    v-for="PurchaseRequest in PurchaseRequests"
                    :key="PurchaseRequest.id"
                    :value="PurchaseRequest.id"
                  >
                    {{ PurchaseRequest.prNumber }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 mb-3 d-flex align-items-center">
              <label for="date" class="col-md-4 col-sm-4 col-form-label"
                >Vendor Name:</label
              >
              <div class="col-md-8 col-sm-8">
                <select
                  v-model="form.vendorId"
                  @change="changeVendorAddress(form.vendorId)"
                  class="form-control border-black form-select"
                  id="contractor"
                >
                  <option v-for="Vendor in Vendors" :key="Vendor.id" :value="Vendor.id">
                    {{ Vendor.companyName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <!-- Vendor Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
              >
                <label for="vendorAddress" class="form-label mb-0">Vendor Address:</label>
              </div>

              <div class="mt-2" style="background-color: #f8f9fa">
                <div class="AddressCard">
                  <div
                    class="form-control"
                    id="vendorAddress"
                    @click="fetchPurchaseRequestById(form.projectId)"
                    style="height: 130px"
                  >
                    {{ billingVendorAddressData.name }}<br />
                    {{ billingVendorAddressData.address
                    }}<span v-if="billingVendorAddressData.city">,</span>
                    {{ billingVendorAddressData.city }}<br />
                    {{ billingVendorAddressData.state
                    }}<span v-if="billingVendorAddressData.pincode">-</span
                    >{{ billingVendorAddressData.pincode }}<br />
                    {{ billingVendorAddressData.contactNo }}<br />
                    {{ billingVendorAddressData.email }}<br />
                  </div>
                </div>
              </div>
            </div>

            <!-- Billing Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
                @click="toggleBillingDropdown"
              >
                <label for="billingAddress" class="form-label mb-0"
                  >Billing Address:</label
                >
                <img
                  src="../../../assets/icons/downArrow.png"
                  alt=""
                  @click="toggleBillingDropdown"
                  style="width: 14px; height: 8px; cursor: pointer"
                />
              </div>
              <div
                v-if="showBillingDropdown"
                class="mt-2 position-absolute"
                style="z-index: 999; width: 31.2%"
              >
                <select
                  class="form-select border-black"
                  id="billingAddress"
                  v-model="selectedBillingAddress"
                  @change="updateBillingAddress(selectedBillingAddress)"
                >
                  <option
                    v-for="address in BillingAddresses"
                    :key="address.id"
                    :value="address"
                  >
                    {{ address.name }} - {{ address.city }}
                  </option>
                </select>
              </div>
              <div class="mt-2" style="background-color: #f8f9fa">
                <div class="AddressCard">
                  <div class="form-control" style="height: 130px">
                    {{ selectedBillingAddress.name }}<br />
                    {{ selectedBillingAddress.address
                    }}<span v-if="selectedBillingAddress.city">,</span>
                    {{ selectedBillingAddress.city }}<br />
                    {{ selectedBillingAddress.state
                    }}<span v-if="selectedBillingAddress.pincode">-</span
                    >{{ selectedBillingAddress.pincode }}<br />
                    {{ selectedBillingAddress.contactNo }}<br />
                    {{ selectedBillingAddress.email }}<br />
                  </div>
                </div>
              </div>
            </div>

            <!-- Shipping Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
                @click="toggleShippingDropdown"
              >
                <label for="shippingAddress" class="form-label mb-0"
                  >Shipping Address:</label
                >
                <img
                  src="../../../assets/icons/downArrow.png"
                  alt=""
                  style="width: 14px; height: 8px; cursor: pointer"
                />
              </div>
              <div
                v-if="showShippingDropdown"
                class="mt-2 position-absolute"
                style="z-index: 999; width: 31.2%"
              >
                <select
                  class="form-select border-black"
                  id="shippingAddress"
                  placeholder="Select"
                  v-model="selectedShippingAddress"
                  @change="updateShippingAddress(selectedShippingAddress)"
                >
                  <option
                    v-for="address in ShippingAddresses"
                    :key="address.id"
                    :value="address"
                  >
                    {{ address.name }} - {{ address.city }}
                  </option>
                </select>
              </div>
              <div class="mt-2" style="background-color: #f8f9fa">
                <div class="AddressCard">
                  <div class="form-control" style="height: 130px">
                    {{ selectedShippingAddress.name }}<br />
                    {{ selectedShippingAddress.address
                    }}<span v-if="selectedShippingAddress.city">,</span>
                    {{ selectedShippingAddress.city }}<br />
                    {{ selectedShippingAddress.state
                    }}<span v-if="selectedShippingAddress.pincode">-</span
                    >{{ selectedShippingAddress.pincode }}<br />
                    {{ selectedShippingAddress.contactNo }}<br />
                    {{ selectedShippingAddress.email }}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container small-wrapper mb-4">
            <table class="small-table table table-bordered">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr class="table-header-heading">
                  <!-- Table headers -->
                  <th style="width: 80px">SL/No</th>
                  <th style="width: 200px">Product Name</th>
                  <th style="width: 300px">Product Description</th>
                  <th style="width: 100px">HSN Code</th>
                  <th style="width: 100px">Article No.</th>
                  <th style="width: 150px">Category</th>
                  <th style="width: 100px">Unit Type</th>
                  <th style="width: 100px">Quantity</th>
                  <th style="width: 100px">Discount(%)</th>
                  <th style="width: 100px">Unit Price</th>
                  <th style="width: 150px">Amount</th>
                  <th style="width: 100px">Gst</th>
                  <th style="width: 150px">Total Amount</th>
                  <th style="width: 150px">Expected Date</th>
                  <th style="width: 150px">Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in Products" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>
                    <input
                      class="w-100"
                      v-model="product.searchQuery"
                      @input="fetchProductsFromAPI(index)"
                      :ref="'searchQuery-' + index"
                      placeholder="Search product..."
                    />
                    <ul
                      v-if="!product.productName && product.searchQuery.trim() !== ''"
                      class="dropdown-menu show"
                    >
                      <li
                        v-for="(item, idx) in product.filteredProducts"
                        :key="idx"
                        class="dropdown-item"
                        @click="selectProduct(item, index)"
                      >
                        <span>{{ item.productName }}</span>
                      </li>
                      <li
                        v-if="product.filteredProducts.length === 0"
                        class="dropdown-item"
                      >
                        <button
                          class="btn btn-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#ProcurementProductList"
                          @click="openModal(null)"
                        >
                          Add Product
                        </button>
                      </li>
                    </ul>
                  </td>
                  <td class="pt-2" :title="product.description">
                    {{ product.description }}
                  </td>
                  <td class="pt-2">{{ product.HSNCode }}</td>
                  <td class="pt-2">{{ product.articleNo }}</td>
                  <td class="pt-2">{{ product.category }}</td>
                  <td class="pt-2">{{ product.unitType }}</td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.quantity"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.discount"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.unitPrice"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end pt-2">
                    {{ $formatToIndianCurrency(product.amount) }}
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.GST"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end pt-2">
                    {{ $formatToIndianCurrency(product.totalAmount) }}
                  </td>
                  <td>
                    <input
                      type="date"
                      class="form-control"
                      v-model="product.expectedDate"
                    />
                  </td>
                  <td>
                    <input type="text" class="form-control" v-model="product.remark" />
                  </td>
                  <span
                    ><img
                      src="../../../assets/icons/redDelete.png"
                      alt=""
                      srcset=""
                      style="width: 15px; height: 15px"
                      @click="removeRow(index)"
                    />
                  </span>
                </tr>
              </tbody>
            </table>
            <button class="add-item btn btn-sm btn-primary" @click="addNewTask">
              Add Item +
            </button>
            <div class="d-flex justify-content-end">
              <div
                class="card"
                style="
                position: fixed;
  bottom: 60px;
 
  right: 20px;
  width: 300px;
  background-color: #f8f8f8;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  max-height: 400px; /* Set a max-height for the summary card */
  overflow: auto; /* Make the summary scrollable if it overflo
                "
              >
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <strong>Amount:</strong>
                    <span>{{ $formatToIndianCurrency(calculateTotalAmount()) }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <strong>CGST:</strong>
                    <span>{{ $formatToIndianCurrency(calculateCgst()) }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <strong>SGST:</strong>
                    <span>{{ $formatToIndianCurrency(calculateSgst()) }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <strong>Total Amount:</strong>
                    <span>{{ $formatToIndianCurrency(calculateGrandTotal()) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footerPuchaseRequest">
          <button
            class="save-button btn btn-primary"
            :disabled="!form.projectId"
            @click="saveAsDraft"
          >
            Save
          </button>
          <button class="save-button btn btn-secondary" @click="goBack">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProcurementProductList from "../../Modal/ProcurementProductList.vue";
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";

export default {
  name: "CreatePurchaseOrder",
  components: {
    Header,
    Nav,
    ProcurementProductList,
  },
  data() {
    return {
      selectedTargetId: null,
      BillingAddresses: [],
      ShippingAddresses: [],
      selectedBillingAddress: {},
      selectedShippingAddress: {},
      showBillingDropdown: false,
      showShippingDropdown: false,
      billingVendorAddressData: {},
      Vendors: [],
      PurchaseRequests: [],
      Projects: [],
      ProductList: [],
      Products: [],
      searchQuery: "",
      selectedProduct: null,
      form: {
        vendorId: null,
        projectId: null,
        prId: null,
        vendorAddressId: null,
        shippingAddressId: null,
        billingAddressId: null,
      },
    };
  },
  mounted() {
    this.fetchProjects();
    this.fetchVendors();
    this.fetchProductsOfPO();
  },
  computed: {
    filteredProducts() {
      if (this.searchQuery.trim() === "") {
        return [];
      }
      return this.ProductList.filter((product) =>
        product.productName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },

  methods: {
    async fetchProductsFromAPI(index) {
      const query = this.Products[index].searchQuery.trim();
      if (!query) {
        this.Products[index].filteredProducts = [];
        return;
      }

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/productList/searchProducts`,
          { searchString: query }
        );
        this.Products[index].filteredProducts = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    saveAsDraft() {
      console.log(this.Products);
      // Prepare the line items data
      const lineItems = this.Products.map((product) => ({
        productId: product.productId,
        quantity: product.quantity,
        discount: product.discount,
        gst: product.GST, // GST amount for the product
        unitPrice: product.unitPrice,
        totalAmount: product.totalAmount,
        expectedDate: product.expectedDate,
        remark: product.remark,
      }));

      // Prepare the payload for the request
      const payload = {
        prId: this.form.prId,
        projectId: this.form.projectId,
        vendorId: this.form.vendorId,
        vendorAddressId: this.form.vendorAddressId,
        billingAddressId: this.form.billingAddressId,
        shippingAddressId: this.form.shippingAddressId,
        poStatus: "Pending", // Example status, change as necessary
        amount: this.calculateTotalAmount(), // Total amount without GST
        CGST: this.calculateCgst(), // CGST value
        SGST: this.calculateSgst(), // SGST value
        totalAmount: this.calculateGrandTotal(), // Total amount including GST
        lineitems: lineItems,
      };

      console.log(payload);

      // Send the data to the backend using Axios
      axios
        .post(`${this.$apiEndPoint}/purchaseOrder/`, payload)
        .then((response) => {
          this.$toast("Purchase Order created successfully.", "success");
          this.goBack();
          // Handle the response from the backend
          console.log("Save as Draft successful:", response.data);
          // Optionally, navigate back or show a success message
        })
        .catch((error) => {
          this.$handleError(error);
          // this.goBack();
        });
    },

    // Calculate the total amount (without GST)
    calculateTotalAmount() {
      return this.Products.reduce(
        (sum, product) => sum + product.unitPrice * product.quantity - product.discount,
        0
      );
    },

    calculateCgst() {
      if (!this.Products || this.Products.length === 0) return 0;
      return this.Products.reduce((sum, product) => {
        const cgstAmount = (product.amount * (product.GST / 2)) / 100;
        return sum + cgstAmount;
      }, 0);
    },

    calculateSgst() {
      if (!this.Products || this.Products.length === 0) return 0;
      return this.Products.reduce((sum, product) => {
        const sgstAmount = (product.amount * (product.GST / 2)) / 100;
        return sum + sgstAmount;
      }, 0);
    },

    // Calculate the grand total amount (total amount + total GST)
    calculateGrandTotal() {
      const totalAmount = this.calculateTotalAmount();
      const totalGst = this.calculateCgst() + this.calculateSgst();
      return totalAmount + totalGst;
    },

    // Other existing methods...

    openModal(TargetId) {
      this.selectedTargetId = TargetId;
    },
    handleInput(index) {
      this.Products[index].filteredProducts = this.getFilteredProducts(
        this.Products[index].searchQuery
      );
    },
    getFilteredProducts(query) {
      if (query.trim() === "") {
        return [];
      }
      return this.ProductList.filter((product) =>
        product.productName.toLowerCase().includes(query.toLowerCase())
      );
    },
    selectProduct(item, index) {
      this.Products[index] = {
        productId: item.id,
        productName: item.productName,
        searchQuery: item.productName,
        description: item.description,
        HSNCode: item.HSNCode,
        articleNo: item.articleNo,
        category: item.category,
        unitType: item.unitType,
        quantity: 0, // Default value or as needed
        discount: item.discount, // Default value or as needed
        unitPrice: item.unitPrice,
        amount: 0, // Default value or as needed
        GST: item.GST, // Default value or as needed
        totalAmount: 0, // Default value or as needed
        expectedDate: null,
        remark: "",

        filteredProducts: [], // Clear filtered products for the row
      };

      // Remove the selected product from the ProductList
      // this.ProductList = this.ProductList.filter(
      //   (product) => product.productName !== item.productName
      // );
    },

    isProductInList(item) {
      return (
        item && this.Products.some((product) => product.productName === item.productName)
      );
    },
    async fetchVendors() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/vendor/`);
        this.Vendors = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchBillingAddressForPO() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/addressForPo/Billing/${this.form.projectId}`
        );
        this.BillingAddresses = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchShippingAddressForPO() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/addressForPo/Shipping/${this.form.projectId}`
        );
        this.ShippingAddresses = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async changeVendorAddress(vendorId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Vendor/${vendorId}`
        );
        this.billingVendorAddressData = response.data[0];
        this.form.vendorAddressId = response.data[0].id;
      } catch (error) {
        this.$handleError(error);
      }
    },
    calculateAmounts(product) {
      const gstRate = parseFloat(product.GST) || 0;
      const quantity = parseFloat(product.quantity) || 0;
      const unitPrice = parseFloat(product.unitPrice) || 0;
      const discount = parseFloat(product.discount) || 0;

      const initialAmount = quantity * unitPrice;
      const discountAmount = initialAmount * (discount / 100);
      product.amount = initialAmount - discountAmount;
      const gstAmount = product.amount * (gstRate / 100);
      product.totalAmount = product.amount + gstAmount;
    },
    addNewTask() {
      // Check if the Products array is empty
      if (this.Products.length === 0) {
        this.Products.push({
          productName: "",
          description: "",
          HSNCode: "",
          articleNo: "",
          category: "",
          unitType: "",
          quantity: 0,
          discount: 0,
          unitPrice: 0,
          amount: 0,
          GST: 0,
          totalAmount: 0,
          expectedDate: null,
          remark: "",
          searchQuery: "",
          filteredProducts: [],
          productId: "", // Assuming you have a productId field
        });
      } else {
        const lastProduct = this.Products[this.Products.length - 1];

        // Check if the last product has productName and productId filled out
        if (lastProduct?.productName && lastProduct.productId) {
          this.Products.push({
            productName: "",
            description: "",
            HSNCode: "",
            articleNo: "",
            category: "",
            unitType: "",
            quantity: 0,
            discount: 0,
            unitPrice: 0,
            amount: 0,
            GST: 0,
            totalAmount: 0,
            expectedDate: null,
            remark: "",
            searchQuery: "",
            filteredProducts: [],
            productId: "", // Assuming you have a productId field
          });
        } else {
          // Alert the error message
          alert("Please fill out the current product details before adding a new one.");

          // You could also focus on the first row's productName or searchQuery input field
          // For example, if you're using refs in Vue, you can do something like this:
          // this.$refs['productName'].focus();
        }
      }
    },

    async fetchProductsOfPO() {
      try {
        // Simplified request without conditional logic
        const response = await axios.get(`${this.$apiEndPoint}/productList`);
        this.ProductList = response.data;
        console.log(this.ProductList);
      } catch (error) {
        this.$handleError(error);
      }
    },

    toggleBillingDropdown() {
      this.showBillingDropdown = !this.showBillingDropdown;
      //this.selectedBillingAddress = null;
    },
    toggleShippingDropdown() {
      this.showShippingDropdown = !this.showShippingDropdown;
      //this.selectedShippingAddress = null;
    },
    updateBillingAddress(selectedBillingAddress) {
      this.form.billingAddressId = selectedBillingAddress.id;
      this.showBillingDropdown = false;
    },
    updateShippingAddress(selectedShippingAddress) {
      this.form.shippingAddressId = selectedShippingAddress.id;
      this.showShippingDropdown = false;
    },
    async fetchPurchaseRequestById(projectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/getApprovedPr/${projectId}`
        );
        this.PurchaseRequests = response.data;
        this.fetchBillingAddressForPO();
        this.fetchShippingAddressForPO();
        this.fetchProductsOfPO();
        console.log(this.PurchaseRequests);
      } catch (error) {
        this.fetchBillingAddressForPO();
        this.fetchShippingAddressForPO();
        this.fetchProductsOfPO();
        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
    removeRow(index) {
      this.Products.splice(index, 1);
    },
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/purchaseRequest/getProjectToCreatePr/Procurement`)
        .then((response) => {
          this.Projects = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
.card-body select {
  height: 130px;
}

.AddressCard div {
  height: 130px;
  overflow-y: auto;
}

.AddressCard div::-webkit-scrollbar {
  display: none;
}

.purchase-request {
  padding: 10px 10px;
  height: 83vh;
  width: 100%;
}

.purchase-request .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  width: 100%;
  margin-top: 20px;
  padding: 0px 20px;
  overflow-x: scroll;
  position: relative;
}

.small-wrapper {
  width: 100%;

  height: 89vh;
  /* Set a fixed height to make the table vertically scrollable */
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 100px;
  padding-bottom: 200px;
}

.small-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  border-collapse: collapse;
  /* Ensure the table layout is fixed and borders collapse */
}

.table-header-heading th {
  background-color: #ccc;
  text-align: center;
  position: sticky;
  top: 0;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
  /* Ensure the header stays on top */
}

.add-item {
  margin-top: 10px;
}

tbody td {
  padding: 2px 5px;
  margin: 0;
  width: 200px;
  max-width: fit-content;
  font-size: 14px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Ensures the overflowed text is hidden */
  text-overflow: ellipsis; /* Adds the ellipsis when text overflows */
}

.table-header-heading td:nth-child(1) {
  text-align: center;
}

input {
  border: none;
  padding: 5px;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest {
  margin-top: 20px;
  position: fixed;
  border-radius: 0 0 15px 15px;
  bottom: 12px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 74px);
}

.footerPuchaseRequest .btn {
  margin: 5px;
}

.input-bg {
  background-color: #fff;
}

.description {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-input {
  width: 100%;
  text-align: end;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

#date {
  border: 1px solid #000000;
}

.dropdown-menu {
  height: fit-content;
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 5px;
}
.form-label {
  color: white !important;
}
@media (max-width: 768px) {
  .purchase-request {
    padding: 5px 10px;
    height: 50%;
    width: 100%;
  }

  .d-flex.align-items-center {
    display: block !important;
  }

  .d-flex.align-items-center label,
  .d-flex.align-items-center .form-control {
    width: 100% !important;
  }

  .small-table {
    display: block;
    overflow-x: auto;
  }

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  label {
    flex: 0.5;
    white-space: nowrap;
  }

  input {
    flex: 0.5;
  }
}
</style>

<!-- 
<td>
  <input
    class="w-100"
    v-model="product.searchQuery"
    @input="handleInput(index)"
    :ref="'searchQuery-' + index"
    placeholder="Search product..."
  />
  <ul
    v-if="!product.productName && product.searchQuery.trim() !== ''"
    class="dropdown-menu show"
  >
    <li
      v-for="(item, idx) in getFilteredProducts(product.searchQuery)"
      :key="idx"
      class="dropdown-item"
      @click="selectProduct(item, index)"
    >
      <span>{{ item.productName }}</span>
    </li>
    <li
      v-if="getFilteredProducts(product.searchQuery).length === 0"
      class="dropdown-item"
    >
      <button
        class="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target="#ProcurementProductList"
        @click="openModal(null)"
      >
        Add Product
      </button>
    </li>
  </ul>
</td> -->
