import Home from "./components/Home.vue";
import SignUp from "./components/SignUp.vue";
import Login from "./components/Login.vue";
// import Task from "./components/Task/Task.vue";
import Calendar from "./components/Calendar/Calendar.vue";
import Month from "./components/Calendar/Month.vue";
import Template from "./components/Template.vue";
import EmployeeList from "./components/EmployeeRegistration/EmployeeList.vue";
import Day from "./components/Calendar/Day.vue";
import PropertyList from "./components/Sales/Property/PropertyList.vue";
import CreateProperty from "./components/Sales/Property/CreateProperty.vue";
import SalesSource from "./components/Sales/SalesSource.vue";
import EstimateList from "./components/Sales/Estimate/EstimateList.vue";
import CreateEstimate from "./components/Sales/Estimate/CreateEstimate.vue";
import CreateSalesOrder from "./components/Sales/SalesOrder/CreateSalesOrder.vue";
import Customer from "./components/Sales/Customer.vue";
import Setting from "./components/Sales/Setting.vue";
import MyLead from "./components/Sales/Lead/MyLead.vue";
import FinalEstimate from "./components/Sales/Estimate/FinalEstimate.vue";
import SalesOrderList from "./components/Sales/SalesOrder/SalesOrderList.vue";
import EmployeeSignUp from "./components/EmployeeSignUp.vue";
import SalesDashboard from "./components/Sales/SalesDashboard.vue";
import DesignDashBoard from "./components/Design/DesignDashBoard.vue";
import DesignProjectProfile from "./components/Design/DesignProjectProfile.vue";
import DesignProjectTable from "./components/Design/DesignProjectTable.vue";
import DesignProjectTableByStatus from "./components/Design/DesignProjectTableByStatus.vue";
import ProcurementDashboard from "./components/Procurement/ProcurementDashboard.vue";
import SourceCreate from "./components/Modal/SourceCreate.vue";
import CreateLeadModal from "./components/Modal/CreateLeadModal";
import ProcurementVendorList from "./components/Procurement/ProcurementVendor/ProcurementVendorList.vue";
import ProcurementVendorDetails from "./components/Procurement/ProcurementVendor/ProcurementVendorDetails.vue";
import PurchaseRequestList from "./components/Procurement/PurchaseRequest/PurchaseRequestList.vue";
import PurchaseOrderList from "./components/Procurement/PurchaseOrder/PurchaseOrderList.vue";
import PurchaseInvoiceList from "./components/Procurement/PurchaseInvoice/PurchaseInvoiceList.vue";
import AddTicket from "./components/Procurement/procurementTickets/addTicket.vue";
import PurchaseRequestDetails from "./components/Procurement/PurchaseRequest/PurchaseRequestDetails.vue";
import LeadDetailsModal from "./components/Modal/LeadDetailsModal.vue";
import CustomerDetailsModal from "./components/Modal/CustomerDetailsModal.vue";
import CreateDesignProjectModal from "./components/Modal/CreateDesignProjectModal.vue";
import DesignProjectListBoard from "./components/Design/DesignProjectListBoard.vue";
import DeleteDesignProjectModal from "./components/Modal/DeleteDesignProjectModal.vue";
import ShareWithProjectModal from "./components/Modal/ShareWithProjectModal.vue";
import CreateReminderModal from "./components/Modal/CreateReminderModal.vue";
import CreateTaskModal from "./components/Modal/CreateTaskModal.vue";
import CreateEventModal from "./components/Modal/CreateEventModal.vue";
import MyTaskModal from "./components/Modal/MyTaskModal.vue";
import CustomerCreateModal from "./components/Modal/CustomerCreateModal.vue";
import AddMaterialModal from "./components/Modal/AddMaterialModal.vue";
import MailSentModal from "./components/Modal/MailSentModal.vue";
import CreateEmployeeModal from "./components/Modal/CreateEmployeeModal.vue";
import EmployeeProfileModal from "./components/Modal/EmployeeProfileModal.vue";
import EmployeeProfile from "./components/EmployeeProfile.vue";
import SalesDocument from "./components/Sales/SalesDocument.vue";
import UpdatePassword from "./components/UpdatePassword.vue";
import EstimateApproval from "./components/Sales/Estimate/EstimateApproval.vue";
import PrintEstimate from "./components/Sales/PrintEstimate.vue";
import BulkCreateLeadModal from "./components/Modal/BulkCreateLeadModal.vue";
import UserInbox from "./components/UserInbox.vue";
import SalesTarget from "./components/Sales/SalesTarget.vue";
import SalesTargetModal from "./components/Modal/SalesTargetModal";
import ProcurementProjectProfile from "./components/Procurement/ProcurementProjectProfile.vue";
import AddPurchaseOrderInvoiceModal from "./components/Modal/AddPurchaseOrderInvoiceModal.vue";
import ProcurementProductList from "./components/Modal/ProcurementProductList.vue";
import AddDesignActivityModal from "./components/Modal/AddDesignActivityModal.vue";
import GlobalDashboard from "./components/GlobalDashboard.vue";
import DesignProjectInfoModal from "./components/Modal/DesignProjectInfoModal";
import OnsiteDashboard from "./components/Onsite/OnsiteDashboard";
import OnSiteTableByProjectStatus from "./components/Onsite/OnSiteTableByProjectStatus.vue";
import OnSiteProjectProfile from "./components/Onsite/OnSiteProjectProfile";
import CreateContractor from "./components/Onsite/Contractor/CreateContractor.vue";
import ContractorsList from "./components/Onsite/Contractor/ContractorsList.vue";
import UpdateContractor from "./components/Onsite/Contractor/UpdateContractor.vue";
import AddQuotationModal from "./components/Modal/AddQuotationModal.vue";
import AddOnSiteCategoryModal from "./components/Modal/AddOnSiteCategoryModal.vue";
import AddContractorModal from "./components/Modal/AddContractorModal.vue";
import OnsiteContractorDetailsModal from "./components/Modal/OnsiteContractorDetailsModal.vue";
import PettyCashOnsite from "./components/Onsite/PettyCashOnsite.vue";
import AddOnSitePettyCashModal from "./components/Modal/AddOnSitePettyCashModal.vue";
import CreatePurchaseInvoice from "./components/Modal/CreatePurchaseInvoice.vue";
import OnsiteEntriesModal from "./components/Modal/OnsiteEntriesModal";
import OnSiteOutwordEntriesModal from "./components/Modal/OnSiteOutwordEntriesModal.vue";
import OnSiteStockConsumptionModal from "./components/Modal/OnSiteStockConsumptionModal.vue";
import PRDetailsModal from "./components/Modal/PRDetailsModal.vue";
import PODetailsModal from "./components/Modal/PODetailsModal.vue";
import OnSiteGrnDetailsModal from "./components/Modal/OnSiteGrnDetailsModal.vue";
import UpdateDesignProjectModal from "./components/Modal/UpdateDesignProjectModal.vue";
import InwardEntriesDetailsModal from "./components/Modal/InwardEntriesDetailsModal.vue";
import PettyCashReportModal from "./components/Modal/PettyCashReportModal.vue";
import ProcurementProjectTableByStatus from './components/Procurement/ProcurementProjectTableByStatus.vue'
import CreatePurchaseOrder from './components/Procurement/PurchaseOrder/CreatePurchaseOrder.vue'
import AddAddressesModal from './components/Modal/AddAddressesModal.vue'
import ProcurementDebitNotes from './components/Procurement/ProcurementDebitNotes.vue'
import ProductMaster from './components/Procurement/ProductMaster.vue'
import ProcurementTransitList from './components/Procurement/procurementTickets/ProcurementTransitList.vue'
import CreateScopeOfWork from './components/Modal/CreateScopeOfWork.vue'
import CreateDebitNote from './components/Procurement/CreateDebitNote.vue'
import PurchaseReport from './components/Procurement/PurchaseReport.vue'
import ProcurementTicketDetails from './components/Modal/ProcurementTicketDetails.vue'
import ToolCenterLoader from './components/ToolCenterLoader'
import signOffApproval from './components/Design/signOffApproval'
import TotalSales from './components/Sales/TotalSales.vue'
import CompanyDocuments from "./components/CompanyDocuments";
import Mytask from "./components/Task/mytask.vue";
import taskSidePanel from "./components/Modal/taskSidePanel.vue";
import LeadFilterModal from "./components/Modal/LeadFilterModal.vue";
import CreateProjectModal from "./components/Modal/CreateProjectModal.vue"
import DebitNoteDetailsModal from "./components/Modal/DebitNoteDetailsModal.vue";
import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    name: "Home",
    component: Home,
    path: "/:home?",
    meta: { title: "Home" ,teams: ["Admin", "Sales"] },
  },
  {
    name: "SignUp",
    component: SignUp,
    path: "/sign-up",
    meta: { title: "Signup" },
  },
  {
    name:"ToolCenterLoader",
    component:ToolCenterLoader,
    path:"/ToolCenterLoader",
    meta:{title : "ToolCenterLoader"}

  },
  {
    name: "Login",
    component: Login,
    path: "/login",
    meta: { title: "Login" },
  },
  {
    name: "UserInbox",
    component: UserInbox,
    path: "/UserInbox",
    meta: { title: "Inbox" },
  },

  {
    name: "UpdatePassword",
    component: UpdatePassword,
    path: "/updatePassword/:UUID",
    meta: { title: "UpdatePassword" },
  },
  {
    name: "AddPurchaseOrderInvoiceModal",
    component: AddPurchaseOrderInvoiceModal,
    path: "/AddPurchaseOrderInvoiceModal",
    meta: { title: "AddPurchaseOrderInvoiceModal" },
  },
  {
    name: "Task",
    component: Mytask,
    path: "/myTask",
    meta: { title: "MyTasks" },
  },

  {
    name: "taskSidePanel",
    component: taskSidePanel,
    path: "/taskSidepanel",
    mata: {title: "TaskSidePanel"},
  },

  {
    name: "MyTaskModal",
    component: MyTaskModal,
    path: "/MyTaskModal",
    meta: { title: "Tasks" },
  },

  {
    name: "ProcurementProductList",
    component: ProcurementProductList,
    path: "/ProcurementProductList",
    meta: { title: "ProcurementProductList" },
  },
  {
    name: "Calendar",
    component: Calendar,
    path: "/Calendar",
    meta: { title: "Calendar" },
  },
  {
    name: "Month",
    component: Month,
    path: "/Calendar/:year/:month",
    meta: { title: "Calendar" },
    props: true,
  },
  {
    name: "Day",
    component: Day,
    meta: { title: "Calendar" },
    path: "/Calendar/:year/:month/:day",
    props: true, // This allows route parameters to be passed as props
  },

  {
    name: "Template",
    component: Template,
    path: "/template",
    meta: { title: "Template" },
  },


  {
    name: "EmployeeList",
    component: EmployeeList,
    path: "/employeeList",
    meta: { title: "EmployeeList" },
  },

  {
    name: "ProcurementProjectProfile",
    component: ProcurementProjectProfile,
    path: "/procurementProjectProfile/:Id",
    meta: { title: "ProcurementProjectProfile" },
  },

  {
    name: "SalesSource",
    component: SalesSource,
    path: "/SalesSource",
    meta: { title: "Source" },
  },

  {
    name: "CreateProperty",
    component: CreateProperty,
    path: "/CreateProperty",
    meta: { title: "CreateProperty" },
  },

  {
    name: "PropertyList",
    component: PropertyList,
    path: "/PropertyList/:Id",
    meta: { title: "PropertyList" },
  },


  {
    name: "MyLead",
    component: MyLead,
    path: "/MyLead",
    meta: { title: "MyLead" },
  },
  {
    name: "EstimateList",
    component: EstimateList,
    path: "/EstimateList",
    meta: { title: "EstimateList" },
  },
  {
    name: "CreateEstimate",
    component: CreateEstimate,
    path: "/CreateEstimate",
    meta: { title: "CreateEstimate" },
  },
  {
    name: "FinalEstimate",
    component: FinalEstimate,
    path: "/FinalEstimate/:estimateIndex",
    meta: { title: "FinalEstimates" },
  },

  {
    name: "EstimateApproval",
    component: EstimateApproval,
    path: "/EstimateApproval/:UUID",
    meta: { title: "EstimateApproval" },
  },
  {
    name: "CreateSalesOrder",
    component: CreateSalesOrder,
    path: "/CreateSalesOrder/:SalesOrderId",
    meta: { title: "CreateSalesOrder" },
  },
  {
    name: "SalesOrderList",
    component: SalesOrderList,
    path: "/SalesOrderList",
    meta: { title: "SalesOrderList" },
  },
  {
    name: "SalesDashboard",
    component: SalesDashboard,
    path: "/SalesDashboard",
    meta: { title: "Dashboard" },
  },
  {
    name: "Setting",
    component: Setting,
    path: "/Setting",
    meta: { title: "Setting" },
  },
  {
    name: "Customer",
    component: Customer,
    path: "/Customer",
    meta: { title: "Customer" },
  },
  {
    name: "EmployeeSignUp",
    component: EmployeeSignUp,
    path: "/EmployeeSignUp/:uuidToken",
    meta: { title: "SignUp" },
  },
  {
    name: "signOffApproval",
    component: signOffApproval,
    path: "/signOffApproval/:UUID",
    meta: { title: "SignOffApproval" },
  },
  
  {
    name: "DesignDashBoard",
    component: DesignDashBoard,
    path: "/DesignDashBoard",
    meta: { title: "DesignDashBoard" },
  },
  {
    name: "DesignProjectProfile",
    component: DesignProjectProfile,
    path: "/DesignProjectProfile/:Id",
    meta: { title: "ProjectProfile" },
    props: true,
  },
  {
    name: "DesignProjectTable",
    component: DesignProjectTable,
    path: "/designProjectTable/:stageName/:selectedEmployeeId",
    meta: { title: "DesignProjectTable" },
    props: true,
  },
  {
    name: "DesignProjectTableByStatus",
    component: DesignProjectTableByStatus,
    path: "/DesignProjectTableByStatus/:statusName/:selectedEmployeeId",
    meta: { title: "DesignProjectTableByStatus" },
    props: true,
  },

  {
    name: "ProcurementDashboard",
    component: ProcurementDashboard,
    path: "/ProcurementDashboard",
    meta: { title: "ProcurementDashboard" },
  },
 
  {
    name: "SourceCreate",
    component: SourceCreate,
    path: "/SourceCreate",
    meta: { title: "SourceCreate" },
  },
  {
    name: "CreateLeadModal",
    component: CreateLeadModal,
    path: "/CreateLeadModal",
    meta: { title: "CreateLeadModal" },
  },
  {
    name: "ProcurementVendorList",
    component: ProcurementVendorList,
    path: "/ProcurementVendorList",
    meta: { title: "VendorList" },
  },
  {
    name: "ProcurementVendorDetails",
    component: ProcurementVendorDetails,
    path: "/ProcurementVendorDetails/:vendorId?",
    meta: { title: "VendorDetails" },
  },
  {
    name: "PurchaseRequestList",
    component: PurchaseRequestList,
    path: "/PurchaseRequestList/:moduleName",
    meta: { title: "PurchaseRequestList" },
  },
  {
    name: "PurchaseOrderList",
    component: PurchaseOrderList,
    path: "/PurchaseOrderList",
    meta: { title: "PurchaseOrderList" },
  },
  {
    name: "PurchaseInvoiceList",
    component: PurchaseInvoiceList,
    path: "/PurchaseInvoiceList",
    meta: { title: "PurchaseInvoiceList" },
  },

  {
    name: "AddTicket",
    component: AddTicket,
    path: "/AddTicket/:TicketId?",
    meta: { title: "AddTicket" },
  },
  {
    name: "PurchaseRequestDetails",
    component: PurchaseRequestDetails,
    path: "/PurchaseRequestDetails",
    meta: { title: "PurchaseRequestDetails" },
  },

  {
    name: "LeadDetailsModal",
    component: LeadDetailsModal,
    path: "/LeadDetailsModal",
    meta: { title: "LeadDetailsModal" },
  },
  {
    name: "CustomerDetailsModal",
    component: CustomerDetailsModal,
    path: "/CustomerDetailsModal",
    meta: { title: "CustomerDetailsModal" },
  },
  {
    name: "CreateDesignProjectModal",
    component: CreateDesignProjectModal,
    path: "/CreateDesignProjectModal",
    meta: { title: "CreateProject" },
  },
  {
    name: "DesignProjectListBoard",
    component: DesignProjectListBoard,
    path: "/DesignProjectListBoard",
    meta: { title: "ProjectList" },
  },
  {
    name: "DeleteDesignProjectModal",
    component: DeleteDesignProjectModal,
    path: "/DeleteDesignProjectModal",
    meta: { title: "DeleteDesignProjectModal" },
  },
  {
    name: "ShareWithProjectModal",
    component: ShareWithProjectModal,
    path: "/ShareWithProjectModal",
    meta: { title: "ShareWithProjectModal" },
  },
  {
    name: "CreateReminderModal",
    component: CreateReminderModal,
    path: "/CreateReminderModal",
    meta: { title: "CreateReminderModal" },
  },
  {
    name: "CreateTaskModal",
    component: CreateTaskModal,
    path: "/CreateTaskModal",
    meta: { title: "CreateTaskModal" },
  },
  {
    name: "CreateEventModal",
    component: CreateEventModal,
    path: "/CreateEventModal",
    meta: { title: "CreateEventModal" },
  },
  {
    name: "CustomerCreateModal",
    component: CustomerCreateModal,
    path: "/CustomerCreateModal",
    meta: { title: "CustomerCreateModal" },
  },
  {
    name: "AddMaterialModal",
    component: AddMaterialModal,
    path: "/AddMaterialModal",
    meta: { title: "AddMaterialModal" },
  },
  {
    name: "MailSentModal",
    component: MailSentModal,
    path: "/MailSentModal",
    meta: { title: "MailSentModal" },
  },
  {
    name: "CreateEmployeeModal",
    component: CreateEmployeeModal,
    path: "/CreateEmployeeModal",
    meta: { title: "CreateEmployeeModal" },
  },
  {
    name: "EmployeeProfileModal",
    component: EmployeeProfileModal,
    path: "/EmployeeProfileModal",
    meta: { title: "EmployeeProfileModal" },
  },
  {
    name: "EmployeeProfile",
    component: EmployeeProfile,
    path: "/EmployeeProfile/:employeeId",
    meta: { title: "EmployeeProfile" },
  },
  {
    name: "SalesDocument",
    component: SalesDocument,
    path: "/SalesDocument",
    meta: { title: "SalesDocument" },
  },

  {
    name: "PrintEstimate",
    component: PrintEstimate,
    path: "/PrintEstimate",
    meta: { title: "PrintEstimate" },
  },

  {
    name: "BulkCreateLeadModal",
    component: BulkCreateLeadModal,
    path: "/BulkCreateLeadModal",
    meta: { title: "BulkCreateLeadModal" },
  },
  {
    name: "SalesTarget",
    component: SalesTarget,
    path: "/SalesTarget",
    meta: { title: "SalesTarget" },
  },
  {
    name: "SalesTargetModal",
    component: SalesTargetModal,
    path: "/SalesTargetModal",
    meta: { title: "SalesTargetModal" },
  },

  {
    name: "AddDesignActivityModal",
    component: AddDesignActivityModal,
    path: "/AddDesignActivityModal",
    meta: { title: "AddDesignActivityModal" },
  },
  {
    name: "GlobalDashboard",
    component: GlobalDashboard,
    path: "/GlobalDashboard/:DashboardType",
    meta: { title: "GlobalDashboard" },
  },
  {
    name: "DesignProjectInfoModal",
    component: DesignProjectInfoModal,
    path: "/DesignProjectInfoModal",
    meta: { title: "DesignProjectInfoModal" },
  },
  {
    name: "OnsiteDashboard",
    component: OnsiteDashboard,
    path: "/OnsiteDashboard",
    meta: { title: "OnsiteDashboard" },
  },

  {
    name: "OnSiteTableByProjectStatus",
    component: OnSiteTableByProjectStatus,
    path: "/OnSiteTableByProjectStatus/:statusName",
    meta: { title: "OnSiteTableByProjectStatus" },
  },
  {
    name: "OnSiteProjectProfile",
    component: OnSiteProjectProfile,
    path: "/OnSiteProjectProfile/:Id",
    meta: { title: "OnSiteProjectProfile" },
    props: true,
  },
  {
    name: "ContractorsList",
    component: ContractorsList,
    path: "/ContractorsList",
    meta: { title: "ContractorsList" },
  },

  {
    name: "CreateContractor",
    component: CreateContractor,
    path: "/CreateContractor",
    meta: { title: "CreateContractor" },
  },
  {
    name: "UpdateContractor",
    component: UpdateContractor,
    path: "/UpdateContractor/:contractorId",
    meta: { title: "UpdateContractor" },
  },
  {
    name: "AddQuotationModal",
    component: AddQuotationModal,
    path: "/AddQuotationModal",
    meta: { title: "AddQuotationModal" },
  },
  {
    name: "AddOnSiteCategoryModal",
    component: AddOnSiteCategoryModal,
    path: "/AddOnSiteCategoryModal",
    meta: { title: "AddOnSiteCategoryModal" },
  },
  {
    name: "AddContractorModal",
    component: AddContractorModal,
    path: "/AddContractorModal",
    meta: { title: "AddContractorModal" },
  },
  {
    name: "OnsiteContractorDetailsModal",
    component: OnsiteContractorDetailsModal,
    path: "/OnsiteContractorDetailsModal",
    meta: { title: "OnsiteContractorDetailsModal" },
  },
  {
    name: "PettyCashOnsite",
    component: PettyCashOnsite,
    path: "/PettyCashOnsite",
    meta: { title: "PettyCashOnsite" },
  },
  {
    name: "PRDetailsModal",
    component: PRDetailsModal,
    path: "/PRDetailsModal",
    meta: { title: "PRDetailsModal" },
  },
  {
    name: "PODetailsModal",
    component: PODetailsModal,
    path: "/PODetailsModal",
    meta: { title: "PODetailsModal" },
  },

  {
    name: "AddOnSitePettyCashModal",
    component: AddOnSitePettyCashModal,
    path: "/AddOnSitePettyCashModal",
    meta: { title: "AddOnSitePettyCashModal" },
  },
  {
    name: "CreatePurchaseInvoice",
    component: CreatePurchaseInvoice,
    path: "/CreatePurchaseInvoice",
    meta: { title: "CreatePurchaseInvoice" },
  },
  {
    name: "OnsiteEntriesModal",
    component: OnsiteEntriesModal,
    path: "/OnsiteEntriesModal",
    meta: { title: "OnsiteEntriesModal" },
  },
  {
    name: "OnSiteOutwordEntriesModal",
    component: OnSiteOutwordEntriesModal,
    path: "/OnSiteOutwordEntriesModal",
    meta: { title: "OnSiteOutwordEntriesModal" },
  },
  {
    name: "OnSiteStockConsumptionModal",
    component: OnSiteStockConsumptionModal,
    path: "/OnSiteStockConsumptionModal",
    meta: { title: "OnSiteStockConsumptionModal" },
  },
  {
    name: "CompanyDocuments",
    component: CompanyDocuments,
    path: "/CompanyDocuments/:moduleName",
    meta: { title: "CompanyDocuments" },
  },
  {
    name: "OnSiteGrnDetailsModal",
    component: OnSiteGrnDetailsModal,
    path: "/OnSiteGrnDetailsModal",
    meta: { title: "OnSiteGrnDetailsModal" },
  },
  {
    name: "UpdateDesignProjectModal",
    component: UpdateDesignProjectModal,
    path: "/UpdateDesignProjectModal",
    meta: { title: "UpdateDesignProjectModal" },
  },
  {
    name: "InwardEntriesDetailsModal",
    component: InwardEntriesDetailsModal,
    path: "/InwardEntriesDetailsModal",
    meta: { title: "InwardEntriesDetailsModal" },
  },
  {
    name: "PettyCashReportModal",
    component: PettyCashReportModal,
    path: "/PettyCashReportModal",
    meta: { title: "PettyCashReportModal" },
  },
  {
    name: "ProcurementProjectTableByStatus",
    component: ProcurementProjectTableByStatus,
    path: "/ProcurementProjectTableByStatus/:statusName",
    meta: { title: "ProcurementProjectTableByStatus" },
    props: true,
  },
  {
    name: "CreatePurchaseOrder",
    component: CreatePurchaseOrder,
    path: "/CreatePurchaseOrder",
    meta: { title: "CreatePurchaseOrder" },
    props: true,
  },
  {
    name: "AddAddressesModal",
    component: AddAddressesModal,
    path: "/AddAddressesModal",
    meta: { title: "AddAddressesModal" },
    props: true,
  },
  {
    name: "ProcurementDebitNotes",
    component: ProcurementDebitNotes,
    path: "/ProcurementDebitNotes",
    meta: { title: "ProcurementDebitNotes" },
    props: true,
  },
  {
    name: "ProductMaster",
    component: ProductMaster,
    path: "/ProductMaster",
    meta: { title: "ProductMaster" },
    props: true,
  },
  {
    name: "ProcurementTransitList",
    component: ProcurementTransitList,
    path: "/ProcurementTransitList",
    meta: { title: "ProcurementTransitList" },
    props: true,
  },
  {
    name: "CreateScopeOfWork",
    component: CreateScopeOfWork,
    path: "/CreateScopeOfWork",
    meta: { title: "CreateScopeOfWork" },
    props: true,
  },
  {
    name: "CreateDebitNote",
    component: CreateDebitNote,
    path: "/CreateDebitNote",
    meta: { title: "CreateDebitNote" },
    props: true,
  },
  {
    name: "PurchaseReport",
    component: PurchaseReport,
    path: "/PurchaseReport",
    meta: { title: "PurchaseReport" },
    props: true,
  },
  {
    name: "ProcurementTicketDetails",
    component: ProcurementTicketDetails,
    path: "/ProcurementTicketDetails",
    meta: { title: "ProcurementTicketDetails" },
    props: true,
  },
  {
    name: "TotalSales",
    component: TotalSales,
    path: "/TotalSales",
    meta: { title: "TotalSales" },
    props: true,
  },
  {
    name: "LeadFilterModal",
    component: LeadFilterModal,
    path: "/LeadFilterModal",
    meta: { title: "LeadFilterModal" },
    props: true,
  },
  {
    name: "CreateProjectModal",
    component: CreateProjectModal,
    path: "/CreateProjectModal",
    meta: { title: "CreateProjectModal" },
    props: true,
    
  },
  {
    name: "DebitNoteDetailsModal",
    component: DebitNoteDetailsModal,
    path: "/DebitNoteDetailsModal",
    meta: { title: "DebitNoteDetailsModal" },
    props: true,
    
  }
  
  
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  document.title = to.meta.title || "Default Title";
  next();

  // const userRoles = JSON.parse(localStorage.getItem("employeeRoles"));
  // const requiredTeams = to.meta.teams || [to.meta.team];

  // if (requiredTeams) {
  //   const hasAccess = userRoles.some(role => requiredTeams.includes(role.team));

  //   if (hasAccess) {
  //     next();
  //   } else {
  //     next("/home"); // Or show an unauthorized message
  //   }
  // } else {
  //   next();
  // }
});

export default router;
