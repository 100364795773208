<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request" v-if="pettyCash">
          <div class="row header mb-2">
            <div class="d-flex justify-content-between">
              <h5 class="ms-3">Petty Cash</h5>
              <div class="close">
                <img
                  src="../../assets/icons/search.png"
                  alt=""
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                />
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <div class="col-md-4 mb-3 d-flex align-items-center">
              <label for="projectName" class="col-md-4 col-sm-4 col-form-label"
                >Total Income:</label
              >
              <div class="col-md-4 col-sm-8 w-50">
                <span class="form-control input-bg w-100">
                  {{ $formatToIndianCurrency(PettyCash.totalIncome) }}
                </span>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="col-md-4 mb-3 d-flex align-items-center">
              <div class="col-md-2"></div>
              <label for="priority" class="col-md-4 col-sm-4 col-form-label"
                >Total Expense:</label
              >
              <div class="col-md-4 col-sm-8 w-50">
                <span class="form-control input-bg w-100">
                  {{ $formatToIndianCurrency(PettyCash.totalExpense) }}
                </span>
              </div>
            </div>

            <div class="col-md-4 mb-3 d-flex align-items-center">
              <div class="col-md-2"></div>

              <label for="date" class="col-md-4 col-sm-4 col-form-label"
                >Available Balance:</label
              >
              <div class="col-md-4 col-sm-8 w-50">
                <span class="form-control input-bg w-100">
                  {{ $formatToIndianCurrency(PettyCash.balance) }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between ms-1">
            <div class="d-flex">
              <button
                @click="AddonSitePettyCash('null')"
                class="btn btn-sm btn-primary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#AddonSitePettyCash"
              >
                + Add Income/Expense
              </button>
              <button
                class="btn btn-sm btn-secondary ms-5"
                @click="goToProjectExpenseSheet()"
              >
                Project Expense Sheet
              </button>
              <button
                class="btn btn-sm btn-light ms-5 align-content-center"
                @click="openApprovalModal()"
              >
                <img
                  src="../../assets/icons/Calendar.png"
                  class="mb-1"
                  data-toggle="tooltip"
                  data-placement="top-start"
                  style="width: 18px; height: 18px"
                  alt=""
                />
                Report
              </button>
            </div>
          </div>

          <div class="table-resonsive DesignProjectTimeLine">
            <table class="files-table mt-3">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project Name</th>
                  <th>Account</th>
                  <th>Detail</th>
                  <th>Cash In</th>
                  <th>Cash Out</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in getIncomeAndExpense" :key="index">
                  <td>{{ formatDate(row.date) }}</td>
                  <td class="text-nowrap text-left">{{ row["projects.projectName"] }}</td>
                  <td>{{ row.account }}</td>
                  <td>
                    {{ row.description }}
                  </td>
                  <td class="text-right">{{ $formatToIndianCurrency(row.cashIn) }}</td>
                  <td class="text-right">{{ $formatToIndianCurrency(row.cashOut) }}</td>

                  <td>
                    <div class="ActionImage">
                      <span
                        @click="AddonSitePettyCash(row.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#AddonSitePettyCash"
                      >
                        <img
                          src="../../assets/icons/view.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Edit"
                          alt=""
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="purchase-request" v-else>
          <div class="row header mb-2">
            <div v-if="PettyCashByAllProjects">
              <div class="d-flex justify-content-between">
                <h5 class="ms-3 text-decoration-underline">Project Expense Sheet</h5>
                <div class="close">
                  <button
                    type="button"
                    class="btn-close"
                    @click="goToProjectExpenseSheet()"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div class="table-resonsive DesignProjectTimeLine">
                <table class="files-table mt-3">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead>
                    <tr>
                      <th>Sl/No</th>
                      <th>Project Name</th>
                      <th class="text-end">Expense Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in getAllProjects" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td
                        class="text-nowrap text-left"
                        @click="
                          gotoProjectInfo(row.projectId, row['projects.projectName'])
                        "
                      >
                        {{ row["projects.projectName"] }}
                      </td>
                      <td class="text-end">
                        {{ $formatToIndianCurrency(row.totalExpense) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div class="d-flex justify-content-between">
                <h5 class="ms-3 text-decoration-underline">Project Expense Sheet</h5>
                <div class="ActionImage">
                  <img
                    src="../../assets/icons/print.png"
                    data-toggle="tooltip"
                    data-placement="top-start"
                    title="print"
                    class="mb-2"
                    @click="downloadPDF"
                    alt=""
                  />
                  <button
                    type="button"
                    class="btn-close"
                    @click="this.PettyCashByAllProjects = true"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <section ref="printContent">
                <div
                  class="d-flex justify-content-end"
                  style="margin-right: 20px"
                  v-if="DateFormate"
                >
                  Date:{{ currentDate }}
                </div>
                <div
                  class="mt-1 d-flex justify-content-between"
                  style="margin-left: 14px; font-weight: 500"
                >
                  <section>
                    ProjectName: <strong>{{ this.ProjectName }} </strong>
                  </section>
                  <section style="margin-right: 20px">
                    Total amount:
                    <strong>
                      {{
                        $formatToIndianCurrency(this.getPettyCashByProject.totalExpense)
                      }}
                    </strong>
                  </section>
                </div>
                <div class="table-resonsive DesignProjectTimeLine p-2">
                  <table class="files-table mt-3">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl/No</th>
                        <th>Date</th>
                        <th>Detail</th>
                        <th class="text-right">Expense Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, index) in getPettyCashByProject.getProjects"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ formatDate(row.date) }}</td>

                        <td>{{ row.description }}</td>
                        <td class="text-right">
                          {{ $formatToIndianCurrency(row.cashOut) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <div v-if="showModal" class="modal-overlay" @click="openApprovalModal"></div>
  </transition>
  <transition name="slide">
    <div class="modal show d-block" v-if="showModal" style="z-index: 9999999">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="pettyCashReportModalLabel">Report Data</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="showModal = false"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitReport">
              <div class="mb-3">
                <label for="fromDate" class="form-label">From Date</label>
                <input
                  type="date"
                  v-model="fromDate"
                  class="form-control"
                  id="fromDate"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="toDate" class="form-label">To Date</label>
                <input
                  type="date"
                  v-model="toDate"
                  class="form-control"
                  id="toDate"
                  required
                />
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <button type="submit" class="btn btn-primary" @click="submitReport()">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <AddOnSitePettyCashModal :quotationId="PettyCashId" @fetchData="featchPettyCash" />
  <PettyCashReportModal />
</template>

<script>
import PettyCashReportModal from "../Modal/PettyCashReportModal.vue"; // Import the ReportModal component
import html2pdf from "html2pdf.js";
import axios from "axios";
import AddOnSitePettyCashModal from "../Modal/AddOnSitePettyCashModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
export default {
  name: "PettyCashOnsite",
  components: {
    Header,
    Nav,
    AddOnSitePettyCashModal,
    PettyCashReportModal,
  },
  data() {
    return {
      fromDate: "",
      toDate: "",
      showModal: false,
      DateFormate: false,
      pettyCash: true,
      PettyCash: {},
      PettyCashByAllProjects: true,
      getIncomeAndExpense: [],
      PettyCashId: null,
      getAllProjects: [],
      getPettyCashByProject: {},
      currentDate: this.formatCurrentDate(),
    };
  },
  mounted() {
    this.featchPettyCash();
  },
  methods: {
    submitReport() {
      const reportData = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      axios
        .post(`${this.$apiEndPoint}/pettyCash/pettyCashReport`, reportData)
        .then((response) => {
          console.log(response.data);
          this.getIncomeAndExpense = response.data;
          this.showModal = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    openApprovalModal() {
      this.showModal = !this.showModal;
    },
    formatCurrentDate() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    downloadPDF() {
      this.DateFormate = true;
      setTimeout(() => {
        const contentToPrint = this.$refs.printContent;
        const fileName = `ProjectExpenceSheet_${this.ProjectName}.pdf`;
        html2pdf(contentToPrint, {
          filename: fileName,
          margin: [10, 10, 10, 10], // top, left, bottom, right
          html2canvas: { scale: 2 },
          jsPDF: { format: "a4", orientation: "portrait" },
        });
      }, 0);
    },
    gotoProjectInfo(projectId, projectName) {
      this.PettyCashByAllProjects = !this.PettyCashByAllProjects;
      this.ProjectName = projectName;
      this.getPettyCashByProjectId(projectId);
    },

    async getPettyCashByProjectId(projectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/pettyCash/byProjectId/${projectId}`
        );
        this.getPettyCashByProject = response.data;
        console.log(this.getPettyCashByProject);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async featchgetAllProjectsPettyCash() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/pettyCash/getAllProjects`);
        this.getAllProjects = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    goToProjectExpenseSheet() {
      this.pettyCash = !this.pettyCash;
      this.PettyCashByAllProjects = true;
      this.featchgetAllProjectsPettyCash();
    },

    AddonSitePettyCash(PettyCashIds) {
      this.PettyCashId = PettyCashIds;
      console.log(this.PettyCashId);
    },
    async featchPettyCash() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/pettyCash/`);
        this.PettyCash = response.data;
        this.getIncomeAndExpense = response.data.getIncomeAndExpense;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.purchase-request {
  padding: 10px 10px;
  height: 100%;
  width: 100%;
}

.purchase-request .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.files-table-container {
  max-height: 60vh;
  /* Adjust based on your requirements */
  overflow-y: auto;
  position: relative;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table thead {
  position: sticky;
  top: 0;
  background-color: #bcbfc6;
  z-index: 1;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(1),
.files-table td:nth-child(1) {
  text-align: left;
}

.files-table th:nth-child(5),
.files-table td:nth-child(5),
.files-table th:nth-child(6),
.files-table td:nth-child(6),
.files-table th:nth-child(7),
.files-table td:nth-child(7) {
  text-align: right;
}

.table-resonsive {
  white-space: nowrap;
  width: 100%;
  overflow-x: scroll;
}

.text-right {
  text-align: right !important;
}

@media (max-width: 768px) {
  .table-resonsive {
    overflow-x: scroll;
  }

  .purchase-request {
    padding: 5px 10px;
    height: 50%;
    width: 100%;
  }

  .d-flex.align-items-center {
    display: block !important;
  }

  .d-flex.align-items-center label,
  .d-flex.align-items-center .form-control {
    width: 100% !important;
  }
}

.DesignProjectTimeLine th,
.DesignProjectEstimate td {
  padding: 4px 8px;
  text-align: left;
  font-size: 2;
  white-space: nowrap;
}

.DesignProjectTimeLine td {
  padding: 4px 8px;
  text-align: left;
  font-size: 14px;
}

.DesignProjectTimeLine th:first-child,
.DesignProjectEstimate th:first-child {
  border-radius: 5px 0 0 5px;
}

.DesignProjectTimeLine th:last-child,
.DesignProjectEstimate th:last-child {
  border-radius: 0px 5px 5px 0px;
}

.DesignProjectTimeLine thead,
.DesignProjectEstimate thead {
  background-color: #bcbfc6;
  border-radius: 10px;
  top: 0;
  position: sticky;
  color: #333;
  font-size: 14px;
}

.DesignProjectTimeLine tbody {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.DesignProjectTimeLine tbody td input {
  padding: 1% 1%;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
}

.active-view {
  background-color: #007bff;
  color: white;
}

.DesignProjectTimeLineWrapper .d-flex button {
  margin-right: 10px;
  font-size: 12px;
}

.DesignProjectTimeLineWrapper .d-flex button.active-view {
  background-color: purple;
  color: white;
}

.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  white-space: nowrap;
}

.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .purchase-request {
    padding: 5px 10px;
    height: 50%;
    width: 100%;
  }

  .selectMonthAndYear select {
    margin-top: 10px !important;
    margin-left: 6px;
    padding: 0;
    font-size: 13px;
  }
}
</style>
